import React, { useContext, useEffect, useRef, useState } from "react"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import Loading from "../../../../Common/Components/Loader"
import { ResOrdineEasy } from "../../../../Models/Res/ResOrdineEasy"
import CustomTable from "../../../../Common/Structure/Tables/CustomTable"
import BtnCheck from "../../../../Common/Structure/Buttons/BtnCheck"
import {
  getButtonStatusColor,
  getButtonStatusText,
} from "../../../../Common/Functions/Util/getStatusText"
import { useNavigate } from "react-router-dom"
import TitlePage from "../../../../Common/Structure/Title/TitlePage"
import ErrorComponent from "../../../../Common/Components/ErrorComponent"
import CardContainer from "../../../../Common/Components/CardContainer"
import Pencil from "../../../../Common/Icons/Pencil"
import { LabelLan } from "../../../../Models/LabelLan"
import { GeneralContext } from "../../MasterPage/MasterPage"

type Props = {
  active?: boolean
}

const ListaOrdiniHW = ({ active = true }: Props) => {
  const [listaOrdini, setListaOrdini] = useState<ResOrdineEasy[] | undefined>(undefined)

  const ordersActiveClient = useGetHttp("order/get-active")
  const ordersCloseClient = useGetHttp("order/get-closed")

  const navigation = useNavigate()

  const gContex = useContext(GeneralContext)
  const {
    label: {
      HWOrder: { listOrder },
    },
  } = gContex.labels as LabelLan

  useEffect(() => {
    if (ordersActiveClient.dataF) {
      const newOrderList: ResOrdineEasy[] = ordersActiveClient.dataF.sort(
        (x: ResOrdineEasy, y: ResOrdineEasy) => {
          return y.statusId - x.statusId
        }
      )
      setListaOrdini(newOrderList)
    }
    if (ordersCloseClient.dataF) {
      const newOrderList: ResOrdineEasy[] = ordersCloseClient.dataF.sort(
        (x: ResOrdineEasy, y: ResOrdineEasy) => {
          return y.statusId - x.statusId
        }
      )
      setListaOrdini(newOrderList)
    }
  }, [ordersActiveClient.dataF, ordersCloseClient.dataF])

  useEffect(() => {
    if (active) {
      if (!ordersActiveClient.isTouched) {
        ordersActiveClient.fetch()
      }
    } else {
      if (!ordersCloseClient.isTouched) {
        ordersCloseClient.fetch()
      }
    }
  }, [active])

  if (ordersActiveClient.isLoading || ordersCloseClient.isLoading) return <Loading />

  if (!ordersActiveClient.isLoading && ordersActiveClient.isError)
    return <ErrorComponent errorMessage={listOrder.error} />

  if (!gContex.utente?.permissions?.includes("menu:list-hw-order")) {
    return <ErrorComponent auth />
  }

  return (
    <div className="col-12 col-md-10">
      <CardContainer>
        <TitlePage Title={listOrder.title} />
        <CustomTable
          headers={[listOrder.list.orderCode, listOrder.list.creationData, listOrder.list.status]}
          rows={
            listaOrdini
              ? listaOrdini.map((x) => [
                  x.dealerOrderCode,
                  new Date(x.dateCreation.split("T")[0]).toLocaleDateString("it"),
                  <BtnCheck
                    Title={getButtonStatusText(x.statusId, gContex.labels?.languageCode)}
                    bgColor={getButtonStatusColor(x.statusId)}
                    onClick={() => {
                      if (active) {
                        navigation(`/ordini-hw-e-accessori/nuovo-ordine/${x.id}`)
                      } else {
                        //to-do cambiare componente in ordine chiuso
                        navigation(`/ordini-hw-e-accessori/nuovo-ordine/${x.id}`)
                      }
                    }}
                  />,
                ])
              : [[listOrder.list.noOrder]]
          }
        />
      </CardContainer>
    </div>
  )
}

export default ListaOrdiniHW
