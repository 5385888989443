import { useContext, useEffect, useState } from "react"
import Sidebar from "../../../../Common/Structure/Sidebar/Sidebar"
import BoxArrowInRight from "../../../../Common/Icons/BoxArrowInRight"
import Container from "../PageContainer/Container"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import useWindowWidth from "../../../../Common/Components/hooks/useScreenWidth"
import keycloak from "../../../../keycloack"
import { Notifica } from "../../../../Models/Notifica"
import Bell from "../../../../Common/Icons/Bell"
import { GeneralContext } from "../MasterPage"
import NotificationList from "../../../ListaModali/NotificationList"
import MenuHamburger from "../../../../Common/Icons/MenuHamburger"
import MenuHome from "../MenuList/MenuHome"
import { DealerImpersonated } from "../../../../Models/DealerImpersonated"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import ErrorComponent from "../../../../Common/Components/ErrorComponent"
import { ResDealerList } from "../../../../Models/Res/ResDealerList"
import Loading from "../../../../Common/Components/Loader"
import { UserInfo } from "../../../../Models/userInfo"
import ComboBoxImp from "../Combo"
import { motion } from "framer-motion"

type Props = {
  //notifiche: Notifica[]
  username?: string
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | undefined>>
}

const UserBar = ({ username, setUserInfo }: Props) => {
  const [showSidebar, setShowSidebar] = useState(false)
  const [dealerList, setDealerList] = useState<ResDealerList[]>()
  const [isLoading, setIsLoading] = useState(true)
  const [dealerIdImpersonated, setDealerIdImpersonated] = useState<ResDealerList | null>(null)
  const screenWidth = useWindowWidth()
  const loc = useLocation()
  const gContext = useContext(GeneralContext)
  const setModal = gContext.features?.setModal
  const k = keycloak

  const nav = useNavigate()

  const getDealerAssociated = useGetHttp("organization/dealer/getall")

  useEffect(() => {
    const init = async () => {
      if (gContext.utente?.idUser) {
        await getDealerAssociated.fetch({ agentId: gContext.utente?.idUser })
      }
    }
    if (gContext.utente?.role === "A") {
      init()
    } else {
      localStorage.removeItem("IMP_USER")
    }
    setIsLoading(false)
  }, [gContext.utente?.idUser])

  useEffect(() => {
    if (dealerIdImpersonated && isLoading) setIsLoading(false)
  }, [dealerIdImpersonated])

  useEffect(() => {
    if (getDealerAssociated.dataF) {
      setDealerList(getDealerAssociated.dataF)
      if (localStorage.getItem("IMP_USER")) {
        let data2: ResDealerList | undefined = undefined
        const localeData = JSON.parse(localStorage.getItem("IMP_USER") ?? "")
        data2 = getDealerAssociated.dataF.find((x: ResDealerList) => x.dealerId === localeData.id)
        if (data2) {
          setDealerIdImpersonated(data2)
          setUserInfo((prev) => {
            if (prev) return { id: prev.id, idSalDivOrgSls: data2?.salOrDisChDivId ?? "" }
          })
        }

        data2 = JSON.parse(localStorage.getItem("IMP_USER") ?? "")
      }
    }
  }, [getDealerAssociated.dataF])

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar)
  }
  return (
    <>
      <div className="d-flex">
        {screenWidth > 1345 ? (
          <div className="bg-light fixed-sidebar" style={{ borderRight: "1px solid lightgrey" }}>
            <Sidebar />
          </div>
        ) : showSidebar ? (
          <div
            className="bg-light absolute-sidebar"
            style={{ borderRight: "1px solid lightgrey", zIndex: "999" }}
          >
            <Sidebar closeSidebar={toggleSidebar} />
          </div>
        ) : (
          <MenuHamburger
            onClick={toggleSidebar}
            className="position-absolute top-0 start-0"
            style={{ zIndex: "999", margin: screenWidth > 767 ? 15 : 10, color: "white" }}
          />
        )}
        <div className={`w-100`}>
          <nav className="navbar navbar-light" style={{ backgroundColor: "#009946" }}>
            <div
              className={`d-flex ${
                gContext.utente?.role === "A" ? "justify-content-between" : "justify-content-end"
              } align-items-center w-100 mx-5`}
            >
              {gContext.utente?.permissions?.includes("feature:impersonate") && (
                <div className="d-flex justify-content-between gap-3 align-items-center w-100 mx-2">
                  <ComboBoxImp
                    dealerImpersonated={dealerIdImpersonated}
                    dealerList={dealerList}
                    setDealerIdImpersonated={setDealerIdImpersonated}
                    setIsLoading={setIsLoading}
                    setUserInfo={setUserInfo}
                  />
                </div>
              )}
              <div className="d-flex justify-content-end align-items-center ">
                <div
                  className="navbar-brand mb-0 h1 text-white d-none d-md-flex"
                  onClick={toggleSidebar}
                >
                  {gContext.labels?.label.toolBar.welcome}
                  {username && ", " + username}
                </div>
                <div className="d-flex justify-content-end align-items-center gap-4">
                  {/* <Bell
                    onClick={() =>
                      setModal &&
                      notifiche.length > 0 &&
                      setModal({
                        isError: false,
                        isLoading: false,
                        isVisible: true,
                        reload: false,
                        title: gContext.labels?.label.notification.modal.title ?? "",
                        children: <NotificationList />,
                      })
                    }
                    divStyle="position-relative text-white"
                  >
                    {notifiche && notifiche.length > 0 && (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {notifiche.length}
                      </span>
                    )}
                  </Bell> */}
                  <BoxArrowInRight
                    className="text-white"
                    onClick={() => {
                      localStorage.removeItem("IMP_USER")
                      localStorage.removeItem("ATK")
                      localStorage.removeItem("CAT")
                      setIsLoading(true)
                      k.logout()
                    }}
                  />
                </div>
              </div>
            </div>
          </nav>
          <Container>
            {!isLoading ? (
              loc.pathname.length == 1 ? (
                <MenuHome />
              ) : gContext.utente?.role === "A" ? (
                dealerIdImpersonated ? (
                  <div className="w-100 d-flex justify-content-center">
                    <Outlet />
                  </div>
                ) : loc.pathname.includes("administrator") ? (
                  <Outlet />
                ) : (
                  <ErrorComponent impersinificaton logo />
                )
              ) : (
                <div className="w-100 d-flex justify-content-center">
                  <Outlet />
                </div>
              )
            ) : (
              <Loading />
            )}
          </Container>
        </div>
      </div>
    </>
  )
}

export default UserBar
