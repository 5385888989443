import { useState } from 'react';
import { NotificationProps } from '../Notification';

export const useNotification = () => {
  const [notification, setNotification] = useState<NotificationProps | null>(null);

  const showNotification = (message: string, type: "success" | "error") => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  return { notification, showNotification };
};
