import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { ReqOrderHeaderDto } from "../../../../../../../Models/Req/ReqOrderHeader"
import usePostHttp from "../../../../../../../Common/Functions/HTTPRequest/Post"
import CustomTable from "../../../../../../../Common/Structure/Tables/CustomTable"
import { ReqProdotto } from "../../../../../../../Models/Product"
import BtnClose from "../../../../../../../Common/Icons/BtnClose"
import BtnCheck from "../../../../../../../Common/Structure/Buttons/BtnCheck"
import { useNavigate } from "react-router-dom"
import useGetHttp from "../../../../../../../Common/Functions/HTTPRequest/Get"
import { FetchDataState } from "../../../../../../../Models/Generic"
import useDeleteHttp from "../../../../../../../Common/Functions/HTTPRequest/Delete"
import Loading from "../../../../../../../Common/Components/Loader"
import Input from "../../../../../../../Common/Structure/Input/Input"
import { GeneralContext } from "../../../../../MasterPage/MasterPage"
import { ResDettaglio } from "../../../../../../../Models/Res/ResDettaglio"
import BtnVisual from "../../../../../../../Common/Structure/Buttons/BtnVisual"
import Trolley from "../../../../../../ListaModali/Trolley"
import { reloadTimer } from "../../../../../../../Common/Functions/Util/reloadTime"
import Message from "../../../../../../../Common/Icons/Message"
import NoteProdotto from "../../../../../../ListaModali/NoteProdotto"
import { ErrorNotification } from "../../../../../../../Models/ErrorNotification"
import { errorNotDefault } from "../../../../../../../Common/defaultValue/ErrorNot"
import { LabelLan } from "../../../../../../../Models/LabelLan"

type Props = {
  ordine: ReqOrderHeaderDto
  onDeleteProduct: (id: string | number, qty: number, campaignId?: string | number) => void
  addProducts: (Products: ReqProdotto[], reload: boolean) => void
  orderSaveClient: FetchDataState
  showNotification: (message: string, type: "success" | "error") => void
  setShowList: Dispatch<SetStateAction<boolean>>
}

const ListaProdottiOrdine = ({
  onDeleteProduct,
  ordine,
  addProducts,
  orderSaveClient,
  showNotification,
  setShowList,
}: Props) => {
  const [productList, setProductList] = useState<any[][]>([])
  const [newOrderDetails, setNewOrderDetails] = useState<ResDettaglio[]>([])
  const [ErrorNotRender, setErrorNotRender] = useState<ErrorNotification>(errorNotDefault)

  const loadDettagliOrdine = useGetHttp("order/detail/getall")
  const svuotaCarrello = useDeleteHttp("order/detail/clear")
  const orderValidationClient = usePostHttp("order/validate-sap")
  const orderConfirmClient = usePostHttp("order/confirm")

  const { labels, features, utente } = useContext(GeneralContext)
  const {
    label: {
      HWOrder: {
        newOrder: { body },
      },
      notification: { message },
    },
  } = labels as LabelLan

  useEffect(() => {
    const caricaDettagli = async () => {
      const res = await loadDettagliOrdine.fetch({ orderId: ordine.orderId })
      if (!res) {
        showNotification(message.loadedOrderDetailsError, "error")
      }
    }
    caricaDettagli()
  }, [ordine.orderDetails])

  useEffect(() => {
    if (ErrorNotRender.type !== "")
      switch (ErrorNotRender.type) {
        case "C":
          return showNotification(orderConfirmClient.errorMessage, "error")
        case "V":
          return showNotification(orderValidationClient.errorMessage, "error")
        case "S":
          return showNotification(orderSaveClient.errorMessage, "error")
        case "T":
          return showNotification(svuotaCarrello.errorMessage, "error")
        default:
          return undefined
      }
    setErrorNotRender(errorNotDefault)
  }, [ErrorNotRender])

  useEffect(() => {
    if (loadDettagliOrdine.dataF) {
      setProductList(
        loadDettagliOrdine.dataF
          ? loadDettagliOrdine.dataF.map((x: any) => {
              return [
                x.productCode,
                x.codiceMnemonico,
                x.descrizione,
                <div className="d-flex align-items-start">
                  <Input
                    type="number"
                    inputDivStyle="w-75 m-0 p-0 my-0"
                    justify="justify-content-end"
                    spacing={false}
                    step={1}
                    min={x.minimoOrdine ?? 1}
                    className="m-0 my-0 p-0 w-100 text-end"
                    disabled={ordine.statusId >= 2}
                    defaultValue={x.productQty}
                    onChange={(e) =>
                      setNewOrderDetails((prev) => {
                        if (prev.length != 0) {
                          return prev.map((det: any) => {
                            if (det.id == x.id) {
                              return {
                                ...det,
                                productQty: +e.target.value,
                              }
                            } else return det
                          })
                        } else {
                          return loadDettagliOrdine.dataF.map((det: any) => {
                            if (det.id == x.id) {
                              return {
                                ...det,
                                productQty: +e.target.value,
                              }
                            } else return det
                          })
                        }
                      })
                    }
                  />
                  {x.unit ?? "pz"}
                </div>,
                `${
                  x.minimoOrdine
                    ? x.minimoOrdine + " " + (x.unit ?? "pz")
                    : 1 + " " + (x.unit ?? "pz")
                }`,
                utente?.permissions?.includes("feature:edit-price") && ordine.statusId == 1 ? (
                  <Input
                    type="text"
                    label={undefined}
                    inputDivStyle="w-75 m-0 p-0 my-0"
                    spacing={false}
                    justify="justify-content-center"
                    className="m-0 p-0 w-50 my-0"
                    defaultValue={`${x.productPrice} ${x.priceListUnitCurrency ?? "€"}`}
                    onChange={(e) =>
                      setNewOrderDetails((prev) => {
                        if (prev.length != 0) {
                          return prev.map((det: any) => {
                            if (det.id == x.id) {
                              return {
                                ...det,
                                modifiedPrice: +e.target.value.replace("€", " ").trim(),
                              }
                            } else return det
                          })
                        } else {
                          return loadDettagliOrdine.dataF.map((det: any) => {
                            if (det.id == x.id) {
                              return {
                                ...det,
                                modifiedPrice: +e.target.value.replace("€", " ").trim(),
                              }
                            } else return det
                          })
                        }
                      })
                    }
                  />
                ) : (
                  `${x.productPrice} ${x.priceListUnitCurrency ?? "€"}`
                ),
                x.sapPrice ? `${x.sapPrice} ${x.sapPriceCurrency ?? "€"}` : "",
                <Input
                  type="checkbox"
                  defaultChecked={x.flagDelivery}
                  className="m-0 my-0 p-0"
                  spacing={false}
                  inputDivStyle="m-0 p-0 my-0"
                  disabled={!ordine.flagDelivery}
                  onChange={({ currentTarget: { checked } }) =>
                    setNewOrderDetails((prev) => {
                      if (prev.length != 0) {
                        return prev.map((det: any) => {
                          if (det.id == x.id) {
                            return {
                              ...det,
                              flagDelivery: checked,
                            }
                          } else return det
                        })
                      } else {
                        return loadDettagliOrdine.dataF.map((det: any) => {
                          if (det.id == x.id) {
                            return {
                              ...det,
                              flagDelivery: checked,
                            }
                          } else return det
                        })
                      }
                    })
                  }
                />,
                <div className="d-flex gap-2">
                  <BtnClose
                    onClick={() =>
                      onDeleteProduct(
                        x.productId,
                        x.productQty,
                        x.campaignId ? x.campaignId : undefined
                      )
                    }
                  />
                  <Message
                    width="1.5em"
                    height="1.5em"
                    onClick={() =>
                      features?.setModal({
                        isError: false,
                        isLoading: false,
                        isVisible: true,
                        reload: false,
                        title: body.addNote,
                        children: (
                          <NoteProdotto
                            setNewOrderDetails={setNewOrderDetails}
                            data={newOrderDetails.find((y) => y.id === x.id) ?? x}
                            loadDettagliOrdine={loadDettagliOrdine.dataF}
                            onClose={() =>
                              features.setModal({
                                isError: false,
                                isLoading: false,
                                isVisible: false,
                                reload: false,
                                title: "",
                              })
                            }
                          />
                        ),
                      })
                    }
                  />
                </div>,
              ]
            })
          : [[body.list.noProducts]]
      )
    }
  }, [loadDettagliOrdine.dataF, ordine, newOrderDetails])
  const nav = useNavigate()

  return (
    <div className="w-100 my-2">
      <div className="d-flex mb-2 w-100 justify-content-between">
        <div className="d-flex gap-sm-3 gap-1 flex-column flex-sm-row">
          <div className="d-flex justify-content-between align-content-center ">
            <BtnCheck
              Title={ordine.statusId <= 1 ? body.save : body.edit}
              style={{ width: 150 }}
              onClick={async () => {
                let result
                if (ordine.orderType === "PAL") {
                  if (
                    ordine.guidOrder != null &&
                    ordine.guidOrder != "00000000-0000-0000-0000-000000000000"
                  ) {
                    result = await orderSaveClient.fetch(
                      newOrderDetails.length > 0
                        ? { ...ordine, OrderDetails: newOrderDetails }
                        : { ...ordine, OrderDetails: loadDettagliOrdine.dataF }
                    )
                  } else {
                    showNotification(message.typeOrderPalError, "error")
                    return
                  }
                } else {
                  result = await orderSaveClient.fetch(
                    newOrderDetails.length > 0
                      ? { ...ordine, OrderDetails: newOrderDetails }
                      : { ...ordine, OrderDetails: loadDettagliOrdine.dataF }
                  )
                }

                if (result) {
                  showNotification(message.orderSaveSucess, "success")
                  reloadTimer(2)
                } else {
                  setErrorNotRender({ type: "S", count: ErrorNotRender.count + 1 })
                }
              }}
            />
          </div>
          <div className="d-flex justify-content-between align-content-center">
            <BtnCheck
              Title={body.valid}
              style={{ width: 150 }}
              disabled={ordine.statusId === 2 || productList.length === 0}
              bgColor="btn-warning"
              onClick={async () => {
                let result
                if (ordine.orderType === "PAL") {
                  if (
                    ordine.guidOrder != null &&
                    ordine.guidOrder != "00000000-0000-0000-0000-000000000000"
                  ) {
                    result = await orderValidationClient.fetch(
                      newOrderDetails.length > 0
                        ? { ...ordine, OrderDetails: newOrderDetails }
                        : { ...ordine, OrderDetails: loadDettagliOrdine.dataF }
                    )
                  } else {
                    showNotification(message.typeOrderPalError, "error")
                    return
                  }
                } else {
                  result = await orderValidationClient.fetch(
                    newOrderDetails.length > 0
                      ? { ...ordine, OrderDetails: newOrderDetails }
                      : { ...ordine, OrderDetails: loadDettagliOrdine.dataF }
                  )
                }

                if (result) {
                  showNotification(message.orderValidationSucess, "success")
                  reloadTimer(2)
                } else {
                  setErrorNotRender({ type: "V", count: ErrorNotRender.count + 1 })
                }
              }}
            />
          </div>

          <div className="d-flex justify-content-between align-content-center">
            <BtnCheck
              Title={body.send}
              style={{ width: 150 }}
              bgColor="btn-primary"
              disabled={ordine.statusId < 2 || productList.length === 0}
              onClick={async () => {
                const result = await orderConfirmClient.fetch({
                  ...ordine,
                  OrderDetails: loadDettagliOrdine.dataF,
                })
                if (result) {
                  showNotification(message.orderSentSucess, "success")
                  setTimeout(() => {
                    nav("/ricambi/lista-ordini")
                  }, 2000)
                } else {
                  setErrorNotRender({ type: "C", count: ErrorNotRender.count + 1 })
                }
              }}
            />
          </div>
        </div>
      </div>
      <hr className="mt-4"></hr>

      <div className="d-flex flex-column flex-md-row gap-2 w-auto my-4">
        <BtnVisual
          Title={body.trolley}
          onClick={() =>
            features?.setModal(() => {
              return {
                isVisible: true,
                isError: false,
                isLoading: false,
                title: "Carrello",
                children: <Trolley onAddProdotto={addProducts} type={"R"} />,
                reload: true,
              }
            })
          }
          disabled={features?.trolley?.nProdotti === 0 || (ordine && ordine.statusId >= 2)}
          color={
            features?.trolley?.nProdotti === 0 || (ordine && ordine.statusId >= 2)
              ? "gray"
              : "#E88C0E"
          }
        />
        <BtnVisual
          Title={body.addProducts}
          onClick={() => setShowList(true)}
          disabled={ordine && ordine.statusId >= 2}
          color={ordine && ordine.statusId >= 2 ? "gray" : "green"}
        />
        <BtnVisual
          Title={body.toEmpty}
          btnStyle="BtnVisualOrderType justify-content-center"
          color={productList.length === 0 ? "gray" : "red"}
          disabled={productList.length === 0 || (ordine && ordine.statusId >= 2)}
          onClick={async () => {
            const result = await svuotaCarrello.fetch({ orderId: ordine.orderId })
            if (result) {
              showNotification(message.toEmpyDetails, "success")
              reloadTimer(2)
            } else {
              setErrorNotRender({ type: "T", count: ErrorNotRender.count + 1 })
            }
          }}
        />
      </div>
      {loadDettagliOrdine.isLoading && <Loading easyIcon />}
      {!loadDettagliOrdine.isLoading && productList.length > 0 && (
        <CustomTable
          headers={[
            body.list.productCode,
            body.list.mnemoCode,
            body.list.description,
            body.list.quantity,
            body.list.minQty,
            body.list.priceList,
            body.list.sapPrice,
            body.list.flagDelivery,
            body.list.actions,
          ]}
          rows={productList}
        />
      )}
    </div>
  )
}

export default ListaProdottiOrdine
