import React, { useContext, useEffect, useRef, useState } from "react"
import Loading from "../../../../../../../../Common/Components/Loader"
import useGetHttp from "../../../../../../../../Common/Functions/HTTPRequest/Get"
import Plus from "../../../../../../../../Common/Icons/Plus"
import BtnCheck from "../../../../../../../../Common/Structure/Buttons/BtnCheck"
import SimpleSelect from "../../../../../../../../Common/Structure/Select/SimpleSelect"
import CustomTable from "../../../../../../../../Common/Structure/Tables/CustomTable"
import TitlePage from "../../../../../../../../Common/Structure/Title/TitlePage"
import { ReqProdotto } from "../../../../../../../../Models/Product"
import { ResCompagnia } from "../../../../../../../../Models/Res/ResCompagnia"
import { ResFamigliaCommerciale } from "../../../../../../../../Models/Res/ResFamigliaCommerciale"
import { ResProduct } from "../../../../../../../../Models/Res/ResProduct"
import { defaultTableHeadersCatalogoDis } from "../../../../DefaultValue"
import Input from "../../../../../../../../Common/Structure/Input/Input"
import SearchIcon from "../../../../../../../../Common/Icons/SearchIcon"
import { LabelLan } from "../../../../../../../../Models/LabelLan"
import { GeneralContext } from "../../../../../../MasterPage/MasterPage"
import ArrowDown from "../../../../../../../../Common/Icons/ArrowDown"
import BtnVisual from "../../../../../../../../Common/Structure/Buttons/BtnVisual"

type Props = {
  onAddProdotti: (a: ReqProdotto[], reload: boolean) => void
}

const CatalogoProdotto = ({ onAddProdotti }: Props) => {
  const [famigliaCommerciale, setFamigliaCommerciale] = useState<string | undefined>("98")
  const [compagnia, setCompagnia] = useState<string | undefined>()
  const [searchProduct, setSearchProduct] = useState<string>("")
  const [searchCampain, setSearchCampain] = useState<string>("")
  const [famigliaSort, setFamigliaSort] = useState<ResFamigliaCommerciale[] | undefined>(undefined)
  const [compagniaSort, setCompagniaSort] = useState<ResCompagnia[] | undefined>(undefined)
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [productsSorted, setProductsSorted] = useState<
    (string | Function | JSX.Element)[][] | undefined
  >(undefined)
  const [sezione, setSezione] = useState<string | undefined>()
  const [descriptionSearch, setDescriptionSearch] = useState<string>("")
  const [searchDisabled, setSearchDisabled] = useState<boolean>(true)
  const [campaigns, setcampaigns] = useState<any[] | undefined>(undefined)
  const [sezioneSort, setSezioneSort] = useState<any[] | undefined>(undefined)
  const refSezione = useRef<string | undefined>()

  const level1Client = useGetHttp("product/hierarchy/family")
  const level2Client = useGetHttp("product/hierarchy/section")
  const level3Client = useGetHttp("product/hierarchy/lastlevel")
  const campaignClient = useGetHttp("product/get-campaings")

  const productsClient = useGetHttp("product/getall")
  const prodottoDisClient = useGetHttp("product/available")
  const refFamiglia = useRef<string | undefined>()
  const refCompagnia = useRef<string | undefined>()

  const gContex = useContext(GeneralContext)
  const {
    label: {
      HWOrder: { catalogue },
      common,
    },
  } = gContex.labels as LabelLan

  const defaultTableHeadersCatalogo = [
    catalogue.list.product,
    catalogue.list.MnemoCode,
    catalogue.list.description,
    catalogue.list.minOrder,
    catalogue.list.minDelivery,
    catalogue.list.priceList,
    catalogue.list.assignmentPrice,
    catalogue.list.inTrolley,
    "",
  ]

  const validaProdotto = (id: string | number) => {
    if (id) {
      prodottoDisClient.fetch({ idProduct: id })
    }
  }

  useEffect(() => {
    level1Client.fetch({ type: "R" })
    campaignClient.fetch()
  }, [])

  useEffect(() => {
    if (level1Client.dataF) {
      setFamigliaSort(
        level1Client.dataF.sort((a: ResFamigliaCommerciale, b: ResFamigliaCommerciale) => {
          return a.hierarchyCode > b.hierarchyCode
        })
      )
    }
  }, [level1Client.dataF])

  useEffect(() => {
    if (famigliaCommerciale) {
      level2Client.fetch({ familyCode: famigliaCommerciale, type: "R" })
    }
  }, [famigliaCommerciale])

  useEffect(() => {
    if (level2Client.dataF) {
      setCompagnia(undefined)
      setCompagniaSort(
        level2Client.dataF.sort((a: ResCompagnia, b: ResCompagnia) => {
          return a.id > b.id
        })
      )
    }
  }, [level2Client.dataF])

  useEffect(() => {
    if (compagnia) {
      level3Client.fetch({
        familyCode: famigliaCommerciale?.toString(),
        fasciaCode: compagnia.toString(),
        type: "R",
      })
    }
  }, [compagnia])

  useEffect(() => {
    if (level3Client.dataF) {
      setSezione(undefined)
      setSezioneSort(level3Client.dataF)
    } else {
      setSezioneSort(undefined)
    }
  }, [level3Client.dataF])

  const searchProducts = () => {
    let page = selectedPage
    if (
      famigliaCommerciale !== refFamiglia.current ||
      compagnia !== refCompagnia.current ||
      sezione !== refSezione.current
    ) {
      setSelectedPage(1)
      setTotalPage(1)
      page = 1
    }
    productsClient.fetch({
      hierarchyCode: `${famigliaCommerciale ? famigliaCommerciale : ""}${
        compagnia ? compagnia : ""
      }${sezione ? sezione : ""}`,
      productCode: searchProduct?.trim(),
      campaignId: searchCampain,
      page: page,
      description: descriptionSearch,
      type: "R",
    })
  }

  useEffect(() => {
    if ((famigliaCommerciale && compagnia) || searchProduct) {
      searchProducts()
      window.scroll({ top: 0 })
    }
  }, [selectedPage])

  useEffect(() => {
    if (famigliaCommerciale || searchCampain || searchProduct || descriptionSearch) {
      setSearchDisabled(false)
    } else {
      setSearchDisabled(true)
    }
  }, [famigliaCommerciale, searchCampain, searchProduct, descriptionSearch])

  useEffect(() => {
    if (productsClient.dataF) {
      refFamiglia.current = famigliaCommerciale
      refCompagnia.current = compagnia
      refSezione.current = sezione
      const { products, totalPages } = productsClient.dataF
      const productsSortedObj = products
      setTotalPage(totalPages)
      setProductsSorted(
        productsSortedObj.map((x: ResProduct) => [
          x.productCode,
          x.codiceMnemonico,
          x.description,
          <BtnCheck
            onClick={() => validaProdotto(x.productId)}
            btnStyle="w-100"
            Title={
              prodottoDisClient.dataF
                ? prodottoDisClient.dataF.idProduct == x.productId
                  ? prodottoDisClient.dataF.available
                    ? "Disponibile : " + prodottoDisClient.dataF.qty
                    : "Non Disponibile"
                  : "Verifica"
                : "Verifica"
            }
            bgColor={
              prodottoDisClient.dataF && prodottoDisClient.dataF.idProduct == x.productId
                ? prodottoDisClient.dataF.available
                  ? "btn-success"
                  : "btn-danger"
                : "btn-warning"
            }
          />,
          x.minimoOrdine,
          x.minimoConsegna,
          `${x.prezzoListino ? x.prezzoListino + "€" : "N.a."}`,
          <Plus
            onClick={() => onAddProdotti([{ productId: x.productId, productQty: 1 }], false)}
          />,
        ])
      )
    } else {
      setProductsSorted([])
      setTotalPage(0)
      setSelectedPage(1)
    }
  }, [productsClient.dataF, prodottoDisClient.dataF])

  useEffect(() => {
    if (level1Client.isError || level2Client.isError) {
      //to do aggiungere funzione per mostare pop e ricarica componente
    }
  }, [level1Client.isError, level1Client.isError, productsClient.isError])

  return (
    <div className="col-12 mt-5">
      <div className="d-lg-flex mb-2">
        <div className="d-flex gap-5 justify-content-center m-0 mb-5 flex-column flex-xxl-row w-100">
          <div className="col-12 col-xxl-5">
            <SimpleSelect
              label={catalogue.fields.familyCode}
              name="famigliaCommerciale"
              disabled
              spacing={false}
              isLoading={level1Client.isLoading}
              onChange={(e) => setFamigliaCommerciale(e.currentTarget.value)}
              value={famigliaCommerciale}
              selectDivStyle="align-items-center my-2"
            >
              <option value="">{common.select}...</option>
              {famigliaSort &&
                famigliaSort.map((option: ResFamigliaCommerciale, index: number) => (
                  <option key={index} value={option.hierarchyCode}>
                    {option.hierarchyCode} - {option.description}
                  </option>
                ))}
            </SimpleSelect>
            <SimpleSelect
              label={catalogue.fields.section}
              isLoading={level2Client.isLoading}
              spacing={false}
              onChange={(e) => setCompagnia(e.currentTarget.value)}
              value={compagnia ?? ""}
              selectDivStyle="align-items-center my-2"
            >
              <option value="">{common.select}...</option>
              {compagniaSort &&
                compagniaSort.map((option: ResCompagnia, index: number) => (
                  <option key={index} value={option.hierarchyCode}>
                    {option.hierarchyCode} - {option.description}
                  </option>
                ))}
            </SimpleSelect>
            <SimpleSelect
              label={catalogue.fields.group}
              isLoading={level3Client.isLoading}
              onChange={(e) => setSezione(e.currentTarget.value)}
              value={sezione ?? ""}
              selectDivStyle="align-items-center my-2"
            >
              <option value="">{common.select + "..."}</option>
              {sezioneSort &&
                sezioneSort.map((option: any, index: number) => (
                  <option key={index} value={option.hierarchyCode}>
                    {option.hierarchyCode} - {option.description}
                  </option>
                ))}
            </SimpleSelect>
          </div>
          <div className="col-12 col-xxl-5">
            <Input
              label={catalogue.fields.productCode}
              type="text"
              placeholder="123456"
              onChange={(e) => setSearchProduct(e.currentTarget.value)}
              inputDivStyle="align-items-center"
            />
            <Input
              label={catalogue.fields.description}
              type="text"
              placeholder={catalogue.fields.description + "..."}
              onChange={(e) => setDescriptionSearch(e.currentTarget.value)}
              inputDivStyle="align-items-center py-1"
              spacing={true}
            />
            {gContex.utente?.permissions?.includes("feature:view-campaign") && (
              <SimpleSelect
                label={catalogue.fields.campaignCode}
                name="campaigns"
                disabled={!campaigns}
                isLoading={campaignClient.isLoading}
                onChange={(e) => setSearchCampain(e.currentTarget.value)}
                value={searchCampain}
                selectDivStyle="align-items-center my-0"
              >
                <option value="">{common.select + "..."}</option>
                {campaigns &&
                  campaigns.map((option: ResFamigliaCommerciale, index: number) => (
                    <option key={index} value={option.hierarchyCode}>
                      {option.hierarchyCode} - {option.description}
                    </option>
                  ))}
              </SimpleSelect>
            )}
          </div>
          <div className="col-12 col-xxl-1 align-self-end">
            <BtnCheck
              onClick={searchProducts}
              Title={catalogue.fields.search}
              disabled={searchDisabled}
              icon={<SearchIcon width="1.2em" height="1.2em" />}
            />
          </div>
        </div>
      </div>
      {!productsClient.isLoading && productsSorted && (
        <CustomTable
          headers={defaultTableHeadersCatalogo}
          rows={productsSorted.length > 0 ? productsSorted : [[catalogue.list.notFound]]}
        >
          <div className="w-100 my-2 d-flex justify-content-center align-items-center gap-3">
            {totalPage > 1 && selectedPage !== 1 && (
              <ArrowDown
                style={{ transform: "rotate(90deg)" }}
                width={"1.5em"}
                height={"1.5em"}
                color="#0033A1"
                onClick={selectedPage > 1 ? () => setSelectedPage(selectedPage - 1) : undefined}
              />
            )}
            {selectedPage > 1 && (
              <BtnVisual Title={"1"} color="#004ff9" onClick={() => setSelectedPage(1)} />
            )}
            {totalPage > 1 && <BtnVisual Title={selectedPage.toString()} color="#0033A1" />}
            {totalPage > 1 && selectedPage < totalPage && selectedPage + 1 != totalPage && (
              <BtnVisual
                Title={(selectedPage + 1).toString()}
                onClick={() => setSelectedPage(selectedPage + 1)}
              />
            )}
            {totalPage > 1 && selectedPage != totalPage && (
              <BtnVisual
                Title={totalPage.toString()}
                color="#004ff9"
                onClick={() => setSelectedPage(totalPage)}
              />
            )}
            {totalPage > 1 && totalPage !== selectedPage && (
              <ArrowDown
                style={{ transform: "rotate(270deg)" }}
                width={"1.5em"}
                height={"1.5em"}
                color="#0033A1"
                onClick={
                  selectedPage < totalPage ? () => setSelectedPage(selectedPage + 1) : undefined
                }
              />
            )}
          </div>
        </CustomTable>
      )}
      {productsClient.isTouched && !productsClient.isLoading && !productsSorted && (
        <div className="d-flex justify-content-center">{catalogue.list.notFound}</div>
      )}
      {productsClient.isLoading && (
        <div className="d-flex justify-content-center">
          <Loading easyIcon={true} />
        </div>
      )}
    </div>
  )
}

export default CatalogoProdotto
