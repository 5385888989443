export const getLabelRuoliOG = (n: number) => {
  switch (n) {
    case 1:
      return "Responsabile Tecnico"
    case 2:
      return "Responsabile Commerciale"
    case 3:
      return "Responsabile Amministrativo"
  }
}
