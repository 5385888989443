import { useContext, useEffect, useState } from "react"
import TitlePage from "../../../../Common/Structure/Title/TitlePage"
import Input from "../../../../Common/Structure/Input/Input"
import CustomTable from "../../../../Common/Structure/Tables/CustomTable"
import CardContainer from "../../../../Common/Components/CardContainer"
import { GeneralContext } from "../../MasterPage/MasterPage"
import BtnVisual from "../../../../Common/Structure/Buttons/BtnVisual"
import SpuntaIcon from "../../../../Common/Icons/Spunta"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import { ConsegnaIndirizzo } from "../../../../Models/ConsegnaIndirizzo"
import { useNotification } from "../../../../Common/Components/hooks/useNotification"
import Loading from "../../../../Common/Components/Loader"
import ErrorComponent from "../../../../Common/Components/ErrorComponent"
import EditIcon from "../../../../Common/Icons/Edit"
import Cestino from "../../../../Common/Icons/Cestino"
import usePostHttp from "../../../../Common/Functions/HTTPRequest/Post"
import useDeleteHttp from "../../../../Common/Functions/HTTPRequest/Delete"
import { reloadTimer } from "../../../../Common/Functions/Util/reloadTime"
import { Notification } from "../../../../Common/Components/Notification"
import { LabelLan } from "../../../../Models/LabelLan"

const PuntoConsegna = () => {
  const [destinations, setDestinations] = useState<ConsegnaIndirizzo[]>([])
  const [destination, setDestination] = useState<ConsegnaIndirizzo>()
  const [ragSoc, setRagSoc] = useState("")
  const [cap, setCap] = useState("")
  const [indirizzo, setIndirizzo] = useState("")
  const [locality, setLocality] = useState("")
  const { utente, labels } = useContext(GeneralContext)
  const {
    label: {
      agencyProfile: { deliveryPoint },
      notification: { message },
      common,
    },
  } = labels as LabelLan
  const { notification, showNotification } = useNotification()

  const headers2 = [
    deliveryPoint.fields.corporateName ?? "",
    deliveryPoint.fields.adress ?? "",
    deliveryPoint.fields.location ?? "",
    deliveryPoint.fields.cap ?? "",
    labels?.label.agencyProfile.organizationChart.list.tableFields.actions ?? "",
  ]

  const getDestinationClient = useGetHttp("order/destination/getall")
  const saveDestination = usePostHttp("dealer/add-or-update-destination")
  const delDestination = useDeleteHttp("dealer/delete-destination")

  useEffect(() => {
    const init = async () => {
      await getDestinationClient.fetch()
    }
    init()
  }, [])

  useEffect(() => {
    if (getDestinationClient.dataF) {
      setDestinations(getDestinationClient.dataF)
    }
  }, [getDestinationClient.dataF])

  if (getDestinationClient.isLoading) return <Loading />
  if (getDestinationClient.isError)
    return <ErrorComponent errorMessage="Errore durante il caricamento dei dati" />

  if (destinations && !getDestinationClient.isLoading) {
    return (
      <div className="col-12 col-md-10 col-lg-10 col-xl-10">
        <CardContainer>
          <TitlePage Title={deliveryPoint.title} />
          <div className="d-flex flex-column flex-md-row flex-xl-row justify-content-around center-on-mobile">
            <div className="d-flex flex-column justify-content-between w-100">
              <div className="my-5 my-md-0">
                {destinations.length > 0 ? (
                  <CustomTable
                    headers={headers2}
                    rows={destinations
                      .sort((x, y) => {
                        if (x.isEditable && !y.isEditable) {
                          return 1
                        } else if (!x.isEditable && y.isEditable) {
                          return -1
                        } else {
                          return 0
                        }
                      })
                      .map((x) => [
                        x.corporateName,
                        x.address,
                        x.locality,
                        x.postalCode,
                        x.isEditable && utente?.role === "D" && (
                          <div className="d-flex gap-2">
                            <EditIcon
                              color="blue"
                              width={"1.5em"}
                              height={"1.5em"}
                              onClick={() => setDestination(x)}
                            />
                            <Cestino
                              color="red"
                              onClick={async () => {
                                const res = await delDestination.fetch({ destinationId: x.id })
                                if (res) {
                                  showNotification(
                                    message.deleteUserOrgChartSuccess ?? "",
                                    "success"
                                  )
                                  reloadTimer(2)
                                } else {
                                  showNotification(delDestination.errorMessage, "error")
                                }
                              }}
                            />
                          </div>
                        ),
                      ])}
                  />
                ) : (
                  <h4>{common.noAdress}</h4>
                )}
                {utente?.permissions?.includes("feature:custom-destination") && (
                  <div className="d-flex justify-content-end mt-5">
                    <div className="w-100">
                      <p style={{ fontWeight: "bold" }}>{deliveryPoint.addAdress}</p>
                      <Input
                        name="ragioneSociale"
                        label={deliveryPoint.fields.corporateName}
                        onChange={({ currentTarget: { value } }) => setRagSoc(value)}
                        value={ragSoc !== "" ? ragSoc : destination?.corporateName ?? ""}
                      />
                      <Input
                        name="indirizzo"
                        label={deliveryPoint.fields.adress}
                        onChange={({ currentTarget: { value } }) => setIndirizzo(value)}
                        value={indirizzo !== "" ? indirizzo : destination?.address ?? ""}
                      />
                      <Input
                        name="CAP"
                        label={deliveryPoint.fields.cap}
                        onChange={({ currentTarget: { value } }) => setCap(value)}
                        value={cap !== "" ? cap : destination?.postalCode ?? ""}
                      />
                      <Input
                        name="localita"
                        label={deliveryPoint.fields.location}
                        onChange={({ currentTarget: { value } }) => setLocality(value)}
                        value={locality !== "" ? locality : destination?.locality ?? ""}
                      />
                      <div className="my-5 text-center d-flex gap-3">
                        <BtnVisual
                          onClick={async () => {
                            const result = await saveDestination.fetch({
                              id: destination?.id ?? 0,
                              dealerId: utente.idUser,
                              corporateName: ragSoc != "" ? ragSoc : destination?.corporateName,
                              address: indirizzo != "" ? indirizzo : destination?.address,
                              postalCode: cap != "" ? cap : destination?.postalCode,
                              locality: locality != "" ? locality : destination?.locality,
                            })
                            if (result) {
                              showNotification(
                                labels?.label.notification.message.saveGeneralSuccess ?? "",
                                "success"
                              )
                              reloadTimer(2)
                            } else {
                              showNotification(saveDestination.errorMessage, "error")
                            }
                          }}
                          Title={deliveryPoint.save}
                          color="#0033A1"
                          icon={<SpuntaIcon color="#0033A1" />}
                        />
                        <BtnVisual
                          onClick={() => {
                            setDestination(undefined)
                            setIndirizzo("")
                            setRagSoc("")
                            setLocality("")
                            setCap("")
                          }}
                          Title={deliveryPoint.delete}
                          color="#0033A1"
                          icon={<SpuntaIcon color="#0033A1" />}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {notification && <Notification message={notification.message} type={notification.type} />}
        </CardContainer>
      </div>
    )
  }
  return <></>
}

export default PuntoConsegna
