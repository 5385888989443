import { ModalProps } from "../../../../Models/Generic"
import { ReqOrderHeaderDto } from "../../../../Models/Req/ReqOrderHeader"

export const defaultForm = {
  dateCreation: "",
  dealerOrderCode: undefined,
  dealerOrderDate: "",
  destinationCode: undefined,
  flagDelivery: undefined,
  id: undefined,
  notes: undefined,
  statusId: 0,
  termPaymentCode: undefined,
  webCode: undefined,
}

export const defaulModalState: ModalProps = {
  isError: false,
  isLoading: false,
  title: "",
  isVisible: false,
  children: undefined,
  reload: false
}

export const defaultTableHeadersCatalogoDis = [
  "Prodotto",
  "Cod. mnemonico",
  "Descrizione",
  "Disponibilità",
  "Min. Ordine",
  "Min.Consegna",
  "Prezzo listino",
  "Prezzo cessione",
  "",
]

export const defaultTableHeadersCatalogo = [
  "Prodotto",
  "Cod. mnemonico",
  "Descrizione",
  "Min. Ordine",
  "Min.Consegna",
  "Prezzo listino",
  "Prezzo cessione",
  "In Carrello",
  "",
]
