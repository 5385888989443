import { InputHTMLAttributes, ReactNode, Ref, forwardRef } from "react"
import Loading from "../../Components/Loader"

type SimpleSelectProps = {
  children?: ReactNode
  label?: string
  selectDivStyle?: string
  selectStyle?: string
  isLoading?: boolean
  spacing?: boolean
}

const SimpleSelect = forwardRef(
  (
    props: SimpleSelectProps & InputHTMLAttributes<HTMLSelectElement>,
    ref: Ref<HTMLSelectElement>
  ) => {
    const { children, selectDivStyle, selectStyle, label, isLoading, spacing = true, ...rest } = props

    return (
      <div className={`${selectDivStyle} d-flex justify-content-between ${spacing && "my-2"}`}>
        {label}
        {isLoading && <Loading easyIcon={true} />}
        <select {...rest} className={`simple-select ${selectStyle}`} ref={ref}>
          {children}
        </select>
      </div>
    )
  }
)

export default SimpleSelect
