import { Dialog } from "@headlessui/react"
import { useRef } from "react"
import BtnClose from "../Icons/BtnClose"
import { ModalProps } from "../../Models/Generic"
import Loading from "./Loader"

export type ModalInsideProps = {
  onClose: () => void
  modalState: ModalProps
}

const Modal = ({ onClose, modalState }: ModalInsideProps) => {
  const containerHtmlRef = useRef<HTMLDivElement>(null)

  const { children, isError, isLoading, isVisible, title } = modalState

  return (
    <Dialog open={isVisible} onClose={onClose} initialFocus={containerHtmlRef}>
      {/* BackDrop */}
      <div
        className="position-fixed allPageInset bg-dark z-1 opacity-50"
        aria-hidden="true"
        onClick={onClose}
        ref={containerHtmlRef}
      ></div>
      <div className="position-fixed allPageInset z-1 d-flex align-items-center justify-content-center">
        <Dialog.Panel className={"bg-white position-relative rounded modalSize border-2 p-4"}>
          <Dialog.Title>
            <div className={`d-flex ${title ? "justify-content-between" : "justify-content-end"}`}>
              {title} <BtnClose onClick={onClose} width="1em" height="1em" color="red"/>
            </div>
          </Dialog.Title>

          {
            <div className="allPageInset d-flex align-items-center justify-content-center  z-3">
              {!isLoading ? (
                children
              ) : (
                <div className="py-5 my-5">
                  <Loading easyIcon={true} />
                </div>
              )}
            </div>
          }
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default Modal
