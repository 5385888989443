import { ChangeEvent, useContext, useEffect, useState } from "react"
import Input from "../../Structure/Input/Input"
import BtnCheck from "../../Structure/Buttons/BtnCheck"
import { xlsxReader } from "../../Functions/Util/excellReader"
import { fileToArrayBuffer } from "../../Functions/Util/fileToArrayBuffer"
import { GeneralContext } from "../../../Components/UI/MasterPage/MasterPage"
import { LabelLan } from "../../../Models/LabelLan"

export type fileData = {
  ProductCode: string
  Quantity: string
  Notes: string
}

type Props = {
  onFileUpload: (a: any[]) => void
  onFileRead: (a: fileData[]) => void
}

const ExcellReader = ({ onFileUpload, onFileRead }: Props) => {
  const [dataExcell, setDataExcell] = useState<any[]>([])
  const [typeError, setTypeError] = useState("")

  const { labels } = useContext(GeneralContext)
  const {
    label: {
      files: { xlsx },
    },
  } = labels as LabelLan

  const handleFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const bufferArray = await fileToArrayBuffer(e.target.files)

    if (bufferArray instanceof ArrayBuffer) {
      const file = xlsxReader(bufferArray) as fileData[]
      if (file && file?.length > 0) {
        setDataExcell(file)
        onFileRead(file)
        setTypeError("")
      }
    } else if (typeof bufferArray == "string") {
      setTypeError(bufferArray)
    } else {
      setTypeError(xlsx.error)
    }
  }

  return (
    <div className="d-flex justify-content-start gap-3 flex-column flex-md-row align-items-md-center align-items-start">
      <a
        href={require("./AddProductExcel.xlsx")}
        download="AddProducts.xlsx"
        target="_blank"
        rel="noopener noreferrer"
      >
        {xlsx.downloadXlsx}
      </a>
      <div className="d-flex flex-column w-75">
        <Input type="file" onChange={handleFile} className="w-100" />
        {typeError && <div className="text-danger">{typeError}</div>}
      </div>
      <BtnCheck
        Title={xlsx.upload}
        onClick={() => onFileUpload(dataExcell)}
        type="button"
        btnStyle="h-50"
      />
    </div>
  )
}

export default ExcellReader
