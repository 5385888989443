import React, { ReactNode, useContext } from "react"
import BtnVisual from "../Structure/Buttons/BtnVisual"
import BoxArrowInRight from "../Icons/BoxArrowInRight"
import { useNavigate } from "react-router-dom"
import { GeneralContext } from "../../Components/UI/MasterPage/MasterPage"
import { LabelLan } from "../../Models/LabelLan"

export type CardMenu = {
  title: string
  color: string
  url: string
  description?: string
  icon?: ReactNode
}

const CardMenu = ({ color, title, url, description, icon }: CardMenu) => {
  const nav = useNavigate()

  const { labels } = useContext(GeneralContext)
  const {
    label: { common },
  } = labels as LabelLan

  return (
    <div
      className="w-100 rounded-2 px-3 px-sm-5 py-3 bg-white"
      style={{
        border: "0px solid gray",
        borderTop: `5px solid ${color}`,
        height: 100,
        boxShadow: "0 2px 3px 1px #c0bebe",
      }}
    >
      <div className="d-flex flex-column gap-2 w-100">
        <div className="d-flex gap-4">
          <div style={{ color: color }}>{icon && icon}</div>
          <div style={{ color: color, fontSize: "1.2rem" }}>
            <b>{title}</b>
          </div>
        </div>
        <div className="d-flex justify-content-between mx-2 max-sm-5">
          <div>{description && description}</div>
          <div style={{ color: color, fontSize: "1.2rem" }}>
            <BtnVisual
              Title={common.visit}
              color={color}
              icon={<BoxArrowInRight style={{ color: color }} />}
              onClick={() => nav(url)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardMenu
