import React, { ButtonHTMLAttributes, ReactNode } from "react"
import Loading from "../../Components/Loader"

type BtnCheckProps = {
  Title: string
  btnStyle?: string
  disabled?: boolean
  icon?: ReactNode
  color?: string
  dire?: boolean
  isLoading?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

const BtnVisual = ({
  Title,
  btnStyle,
  disabled = false,
  icon,
  color,
  dire,
  isLoading,
  ...rest
}: BtnCheckProps) => {
  return (
    <button
      className={`btn ${
        !disabled
          ? `${!color ? "text-success border-success border" : ""}`
          : `${!color ? "text-secondary border-secondary border" : ""}`
      }  text-uppercase bg-transparent ${btnStyle} rounded-pill  `}
      disabled={disabled}
      type="button"
      style={{ border: color ? `1px solid ${color}` : "" }}
      onClick={rest.onClick}
      {...rest}
    >
      <div
        className={`d-flex ${
          icon ? "justify-content-around" : "justify-content-center"
        } align-items-center gap-3`}
      >
        {isLoading && <Loading easyIcon />}
        {!isLoading && dire ? (
          <>
            {icon && icon}
            <b style={{ color: color ? color : "" }}>{Title}</b>
          </>
        ) : (
          <>
            <b style={{ color: color ? color : "" }}>{Title}</b>
            {icon && icon}
          </>
        )}
      </div>
    </button>
  )
}

export default BtnVisual
