import React, { SVGProps, useState } from "react"

type PlusProps = {} & SVGProps<SVGSVGElement>

const Plus = ({ className, ...rest }: PlusProps) => {
  const [anima, setAnima] = useState(false)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="2em"
      width="2em"
      fill="currentColor"
      className={`bi bi-x-lg iconCustom ${className} ${anima && "FeedBackClick"}`}
      onMouseDown={() => {
        setAnima(true)
        setTimeout(() => {
          setAnima(false)
        }, 0.1 * 1000)
      }}
      viewBox="0 0 448 512"
      {...rest}
    >
      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
    </svg>
  )
}

export default Plus
