import { ModalProps } from "../../Models/Generic";

export const defaultModalProps: ModalProps = {
    isError: false,
    isLoading: false, 
    isVisible: false,
    title: "",
    children: undefined,
    isSuccess: undefined,
    reload: false
}
