import { useContext, useEffect, useState } from "react"
import CardContainer from "../../../../Common/Components/CardContainer"
import CustomTable from "../../../../Common/Structure/Tables/CustomTable"
import { GeneralContext } from "../../MasterPage/MasterPage"
import { LabelLan } from "../../../../Models/LabelLan"
import { LinkMan } from "../../../../Models/Links"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import Input from "../../../../Common/Structure/Input/Input"
import Pencil from "../../../../Common/Icons/Pencil"
import Cestino from "../../../../Common/Icons/Cestino"
import TitlePage from "../../../../Common/Structure/Title/TitlePage"
import BtnVisual from "../../../../Common/Structure/Buttons/BtnVisual"
import InsertNewLink from "./insert"
import { useNotification } from "../../../../Common/Components/hooks/useNotification"
import { Notification } from "../../../../Common/Components/Notification"
import useDeleteHttp from "../../../../Common/Functions/HTTPRequest/Delete"
import { reloadTimer } from "../../../../Common/Functions/Util/reloadTime"
import ErrorComponent from "../../../../Common/Components/ErrorComponent"
type Props = {}

const LinkManager = (props: Props) => {
  const [links, setLinks] = useState<(string | JSX.Element)[][]>([])
  const [linkSelected, setLinkSelected] = useState<LinkMan | undefined>(undefined)
  const [newLink, setNewLink] = useState(false)

  const closeNewLink = () => {
    setLinkSelected(undefined)
    setNewLink(false)
  }

  const { labels, utente } = useContext(GeneralContext)
  const {
    label: {
      linkPage: { list },
    },
  } = labels as LabelLan

  const headersVoice = [
    list.url,
    list.text,
    list.salOrg,
    list.dataStart,
    list.dataEnd,
    list.target,
    "",
  ]

  const getLinksClient = useGetHttp("homepagelink/get-homepagelink-by-session")
  const deleteLinkClient = useDeleteHttp("homepagelink/delete-homepagelink")

  const { notification, showNotification } = useNotification()
  useEffect(() => {
    const getLinks = async () => await getLinksClient.fetch()
    getLinks()
  }, [])

  useEffect(() => {
    if (getLinksClient.dataF) {
      try {
        setLinks(
          getLinksClient.dataF.map((x: LinkMan) => {
            return [
              x.link,
              x.message,
              x.affiliateId,
              x.dateStart && new Date(x.dateStart).toLocaleDateString(),
              x.dateEnd && new Date(x.dateEnd).toLocaleDateString(),
              <Input
                type="checkbox"
                spacing={false}
                disabled
                defaultChecked={x.target === "_blank" ? true : false}
              />,
              <div className="d-flex justify-content-center gap-2 align-items-center">
                <Pencil
                  color="#0033A1"
                  onClick={() => {
                    setLinkSelected(x)
                    setNewLink(true)
                  }}
                />
                <Cestino
                  color="#cd1231"
                  onClick={async () => {
                    const res = await deleteLinkClient.fetch(
                      { homePageLinkId: x.id },
                      undefined,
                      false
                    )
                    if (res) {
                      reloadTimer(0.2)
                    } else {
                      showNotification(
                        labels?.label.notification.message.saveGeneralError ?? "",
                        "error"
                      )
                    }
                  }}
                />
              </div>,
            ]
          })
        )
      } catch (error) {
        setLinks([])
      }
    } else {
      setLinks([])
    }
  }, [getLinksClient.dataF])

  if (!utente?.permissions?.includes("menu:admin-homepage-links")) {
    return <ErrorComponent auth />
  }

  return (
    <CardContainer customizeClassNames="col-11">
      <TitlePage Title={labels?.label.menu.administration.subMenu.link ?? ""} />
      {!newLink ? (
        <div className="col-12">
          <div className="mb-5 d-flex justify-content-end">
            <BtnVisual
              Title={labels?.label.HWOrder.listOrder.statusButton.new ?? "New"}
              onClick={() => setNewLink(true)}
            />
          </div>
          <CustomTable headers={headersVoice} rows={links} />
        </div>
      ) : (
        <InsertNewLink
          close={closeNewLink}
          link={linkSelected}
          showNotification={showNotification}
        />
      )}
      {notification && <Notification message={notification.message} type={notification.type} />}
    </CardContainer>
  )
}

export default LinkManager
