import { useContext, useState } from "react"
import { FetchDataState } from "../../../Models/Generic"
import { GeneralContext } from "../../../Components/UI/MasterPage/MasterPage"
import { LangErrors } from "../../../Models/LangError"

export type filesGuid = {
  files: File[]
  guid: string
  orderId: number
}

const useUploadHttp = (url: string) => {
  const [responseData, setResponseData] = useState<any>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isTouched, setIsTouched] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const { labelsErros } = useContext(GeneralContext)

  const fetch = async (data: filesGuid): Promise<boolean> => {
    return new Promise<boolean>(async (resolve) => {
      setIsLoading(true)
      setIsTouched(true)
      try {
        const response = await uploadHTTP(url, data)
        if (response) {
          if (response.success) {
            setResponseData(response.data)
            setIsSuccess(true)
            resolve(true)
          } else {
            setResponseData(undefined)
            setIsError(true)
            if (response.data) {
              const { controller } = labelsErros as LangErrors
              const [codeController, codeApi, codeError] = response.data.Code.split(".")
              const controllerSel = controller.find((x) => x.codice === codeController)
              const apiSel = controllerSel?.api.find((x) => x.codice === codeApi)
              let errorSel = apiSel?.errori[codeError]
              if (errorSel.includes("{0}")) {
                if (response.data.Message.includes(":")) {
                  errorSel = errorSel.replace("{0}", response.data.Message.split(":")[1])
                } else {
                  errorSel = errorSel.replace("{0}", "N")
                }
              }
              if (errorSel) {
                throw new Error(errorSel)
              } else {
                throw new Error(
                  controller
                    .find((x) => x.codice === "100")!
                    .api.find((x) => x.codice === "0")!.errori["1"]
                )
              }
            }
          }
        } else {
          setResponseData(undefined)
          setIsError(true)
          setErrorMessage("La richiesta è fallita pe rmotivi sconosciuti")
        }
        setIsLoading(false)
      } catch (e: any) {
        setResponseData(undefined)
        setIsError(true)
        setErrorMessage(e.message)
        setIsLoading(false)
        resolve(false)
      }
    })
  }

  const reset = () => {
    setIsTouched(false)
  }

  const state: FetchDataState = {
    dataF: responseData,
    isLoading,
    isError,
    errorMessage,
    fetch,
    isTouched,
    reset,
    isSuccess,
  }

  return state
}

export const uploadHTTP = async (url: string, files: filesGuid) => {
  const fullUrl = `${process.env.REACT_APP_BASE_API_URL}${url}`
  let response: Response = {} as Response
  const formData = new FormData()

  files.files.forEach((x) => {
    formData.append("files", x)
  })
  formData.append("guid", files.guid)
  formData.append("ordineId", `${files.orderId}`)
  const authToken = localStorage.getItem("ATK")

  try {
    response = await fetch(fullUrl, {
      method: "POST",
      headers: {
        Authorization: authToken ?? "",
      },
      body: formData,
    })

    const responseData = await response.json()

    const value = {
      data: responseData,
      statusCode: response.status,
      success: response.ok,
      error: false,
      errorMessage: "",
    }

    return value
  } catch (error) {
    const value = {
      data: undefined,
      statusCode: response.status,
      success: response.ok,
      error: true,
      errorMessage: response.statusText,
    }

    return value
  }
}

export default useUploadHttp
