import { useContext, useState } from "react"
import ExcellReader, { fileData } from "../../../../../../../../Common/Components/Files/ExcellReader"
import CustomTable from "../../../../../../../../Common/Structure/Tables/CustomTable"
import { ReqProdotto } from "../../../../../../../../Models/Product"
import { LabelLan } from "../../../../../../../../Models/LabelLan"
import { GeneralContext } from "../../../../../../MasterPage/MasterPage"

type Props = {
  onAddProdotti: (a: ReqProdotto[], reload: boolean) => void
}

const OrdineDaFile = ({ onAddProdotti }: Props) => {
  const [ProductList, setProductList] = useState<string[][]>([])

  const gContex = useContext(GeneralContext)
  const {
    label: {
      HWOrder: {
        newOrder: {
          body: { list },
        },
      },
    },
  } = gContex.labels as LabelLan

  const tableHeader: string[] = [
    list.productCode,
    list.quantity,
    gContex.labels?.label.HWOrder.newOrder.head.note ?? "",
  ]

  return (
    <div className="col-12 mt-5">
      <ExcellReader
        onFileRead={(a) => setProductList(a.map((x: fileData) => [x.ProductCode, x.Quantity, x.Notes]))}
        onFileUpload={(a) =>
          onAddProdotti(
            a.map((x) => {
              return { productId: x.ProductCode, productQty: x.Quantity }
            }),
            false
          )
        }
      />
      <div className="my-3">
        <CustomTable headers={tableHeader} rows={ProductList} />
      </div>
    </div>
  )
}

export default OrdineDaFile
