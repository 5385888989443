import React from "react";

type BtnSaveProps = {
  onClick?: () => void;
  Title: string;
  btnStyle?: string;
  disabled?: boolean
};

const BtnSave = ({ onClick, Title, btnStyle, disabled = false }: BtnSaveProps) => {
  return (
    <button
      className={` ${btnStyle} btn ${disabled ? "btn-secondary" : "btn-danger"}  text-white text-uppercase`}
      onClick={disabled ? undefined : onClick}
    >
      {Title}
    </button>
  );
};

export default BtnSave;
