import { createBrowserRouter, Navigate, RouterProvider, useNavigate } from "react-router-dom"
import MasterPage from "./Components/UI/MasterPage/MasterPage"
import Organigramma from "./Components/UI/ProfiloAzienda/Organigramma"
import PuntoConsegna from "./Components/UI/ProfiloAzienda/PuntiConsegna/PuntoConsegna"
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import ListaOrdiniHW from "./Components/UI/OrdineHW/ListaOrdini"
import CatalogoHW from "./Components/UI/OrdineHW/Catalogo/Catalogo"
import Ordine2 from "./Components/UI/OrdineHW/NuovoOrdineRef"
import RegistraGaranziaProdotto from "./Components/UI/Garanzie/Registra"
import ListaPartiGaranzia from "./Components/UI/Garanzie/Richiesta"
import CatalogoRicambi from "./Components/UI/OrdineRicambi/Catalogo/Catalogo"
import OrdineRicambi from "./Components/UI/OrdineRicambi/NuovoOrdineRef"
import ListaOrdiniRicambi from "./Components/UI/OrdineRicambi/ListaOrdini"
import ErrorComponent from "./Common/Components/ErrorComponent"
import Anagrafica from "./Components/UI/ProfiloAzienda/Anagrafica"
import keycloak from "./keycloack"
import LinkManager from "./Components/UI/Administrator/Link"
import { AnimatePresence } from "framer-motion"
import Overcharge from "./Components/UI/Administrator/Overcharge"
import SituationReport from "./Components/UI/WebReports/Situation"
import OpenItemsReport from "./Components/UI/WebReports/OpenItems"
import OrderListReport from "./Components/UI/WebReports/OrderList"
import OrderStatusReport from "./Components/UI/WebReports/OrdersStatus"
import DeliveryReport from "./Components/UI/WebReports/Delivery"
import BillsReport from "./Components/UI/WebReports/Bills"
import AccountBalanceReport from "./Components/UI/WebReports/AccountBalance"
import ShippedBilledSoldReport from "./Components/UI/WebReports/ShippedBilledSold"

const routes = createBrowserRouter([
  {
    path: "/",
    element: <MasterPage />,
    errorElement: (
      <ErrorComponent page logo lang={keycloak.tokenParsed?.language === "EN" ? "EN" : ""} />
    ),
    children: [
      {
        path: "/administrator",
        children: [
          { path: "link", element: <LinkManager /> },
          { path: "overcharge", element: <Overcharge /> },
        ],
      },
      {
        path: "/profilo-azienda",
        children: [
          { path: "anagrafica", element: <Anagrafica /> },
          { path: "organigramma", element: <Organigramma /> },
          { path: "punto-di-consegna", element: <PuntoConsegna /> },
        ],
      },
      {
        path: "/ordini-hw-e-accessori",
        children: [
          { path: "catalogohw", element: <CatalogoHW /> },
          { path: "nuovo-ordine", element: <Ordine2 /> },
          { path: "nuovo-ordine/:codice", element: <Ordine2 /> },
          { path: "lista-ordini", element: <ListaOrdiniHW /> },
        ],
      },
      {
        path: "/ricambi",
        children: [
          { path: "catalogo-ricambi", element: <CatalogoRicambi /> },
          { path: "nuovo-ordine", element: <OrdineRicambi /> },
          { path: "nuovo-ordine/:codice", element: <OrdineRicambi /> },
          { path: "lista-ordini", element: <ListaOrdiniRicambi /> },
        ],
      },
      {
        path: "/garanzia",
        children: [
          { path: "registrazione-prodotto-per-garanzia", element: <RegistraGaranziaProdotto /> },
          { path: "richiesta-parti-garanzia", element: <ListaPartiGaranzia /> },
        ],
      },
      {
        path: "/web-reporting",
        children: [
          { path: "situazione-contabile", element: <SituationReport /> },
          { path: "partite-aperte", element: <OpenItemsReport /> },
          { path: "lista-ordini", element: <OrderListReport/> },
          { path: "stato-ordini", element: <OrderStatusReport /> },
          { path: "consegne", element: <DeliveryReport /> },
          { path: "fatture", element: <BillsReport /> },
          { path: "estratti-conto", element: <AccountBalanceReport /> },
          { path: "venduto-fatturato-spedito", element: <ShippedBilledSoldReport /> },
        ],
      },
    ],
  },
])

function App() {
  return <RouterProvider router={routes} />
}

export default App
