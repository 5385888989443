import { useContext, useState } from "react"
import { FetchDataState } from "../../../Models/Generic"
import keycloak from "../../../keycloack"
import { GeneralContext } from "../../../Components/UI/MasterPage/MasterPage"
import { LangErrors } from "../../../Models/LangError"

const usePostHttp = (url: string) => {
  const [responseData, setResponseData] = useState<any>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isTouched, setIsTouched] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const { labelsErros } = useContext(GeneralContext)

  const fetch = async (data: any, p?: string, b?: boolean): Promise<boolean> => {
    return await new Promise(async (resolve) => {
      setIsLoading(true)
      setIsTouched(true)
      try {
        const response = await postHTTP(url, data, b)
        if (response) {
          if (response.success) {
            setResponseData(response.data)
            setIsSuccess(true)
            resolve(true)
          } else {
            if (response.data) {
              const { controller } = labelsErros as LangErrors
              const [codeController, codeApi, codeError] = response.data.Code.split(".")
              const controllerSel = controller.find((x) => x.codice === codeController)
              const apiSel = controllerSel?.api.find((x) => x.codice === codeApi)
              let errorSel = apiSel?.errori[codeError]
              if (errorSel.includes("{0}")) {
                if (response.data.Message.includes(":")) {
                  errorSel = errorSel.replace("{0}", response.data.Message.split(":")[1])
                } else {
                  errorSel = errorSel.replace("{0}", "N")
                }
              }
              if (errorSel) {
                throw new Error(errorSel)
              } else {
                throw new Error(
                  controller
                    .find((x) => x.codice === "100")!
                    .api.find((x) => x.codice === "0")!.errori["1"]
                )
              }
            }
          }
        } else {
          setIsError(true)
          setErrorMessage("La richiesta è fallita per motivi sconosciuti")
        }
        setIsLoading(false)
      } catch (e: any) {
        setResponseData(undefined)
        setIsError(true)
        setErrorMessage(e.message)
        setIsLoading(false)
        resolve(false)
      }
    })
  }

  const reset = () => {
    setIsTouched(false)
    setIsError(false)
    setResponseData(undefined)
    setErrorMessage("")
    setIsSuccess(false)
  }

  const state: FetchDataState = {
    dataF: responseData,
    isLoading,
    isError,
    errorMessage,
    fetch,
    isTouched,
    reset,
    isSuccess,
  }

  return state
}

export const postHTTP = async (url: string, data: Object | Array<Object>, imp = true) => {
  const fullUrl = `${process.env.REACT_APP_BASE_API_URL}${url}`
  let response: Response = {} as Response
  const authToken = localStorage.getItem("ATK")
  if (localStorage.getItem("IMP_USER") && imp) {
    const imp = JSON.parse(localStorage.getItem("IMP_USER") ?? "")
    data = { ...data, dealerId: imp.id }
  }

  try {
    response = await fetch(fullUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken ?? "",
      },
      body: JSON.stringify(data),
    })

    const responseData = await response.json()

    const value = {
      data: responseData,
      statusCode: response.status,
      success: response.ok,
      error: false,
      errorMessage: "",
    }

    return value
  } catch (error) {
    const value = {
      data: undefined,
      statusCode: response.status,
      success: response.ok,
      error: true,
      errorMessage: response.statusText,
    }

    return value
  }
}

export default usePostHttp
