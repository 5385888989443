import { useContext, useEffect, useState } from "react"
import { GeneralContext } from "../UI/MasterPage/MasterPage"
import BtnCheck from "../../Common/Structure/Buttons/BtnCheck"
import CustomTable from "../../Common/Structure/Tables/CustomTable"
import { ReqProdotto } from "../../Models/Product"
import Loading from "../../Common/Components/Loader"
import Plus from "../../Common/Icons/Plus"
import Cestino from "../../Common/Icons/Cestino"

type Props = {
  onAddProdotto?: (pro: ReqProdotto[], reload: boolean) => void
  type?: string | null
  idGuid?: string
}

const tableHeaders = ["Codice Prodotto", "Descrizione", ""]

const Trolley = ({ onAddProdotto, type = null }: Props) => {
  const [listTrolley, setListTrolley] = useState<any[][]>([])

  const gContext = useContext(GeneralContext)

  useEffect(() => {
    if (gContext.features?.trolley?.trolleyListRes) {
      setListTrolley(
        gContext.features.trolley.trolleyListRes
          .filter((x) => (type != null ? x.type == "R" : x.type != "R"))
          .map((x) => [
            x.productCode,
            x.description,
            <div className="d-flex gap-2 flex-row-reverse d-flex align-items-center">
              <Cestino
                onClick={() => gContext.features?.trolley?.deleteProduct(x.trolleyId)}
                color="red"
              />
              {onAddProdotto && (
                <Plus
                  onClick={() => onAddProdotto([{ productId: x.productId, productQty: 1 }], true)}
                  color="green"
                />
              )}
            </div>,
          ])
      )
    }
  }, [gContext.features?.trolley?.trolleyListRes])

  if (gContext.features?.trolley?.isLoading) {
    return (
      <div className="my-5 py-5">
        <Loading easyIcon={true} />
      </div>
    )
  }

  return (
    <div className="d-flex flex-column w-100 gap-1">
      <div className="d-flex flex-row w-100 gap-3">
        <div className="d-flex justify-content-between align-content-center">
          <BtnCheck
            Title="Svuota"
            icon={<Cestino color="white" />}
            btnStyle=""
            bgColor="btn-danger"
            onClick={() => {
              gContext.features?.trolley?.clearTrolley(type ?? null)
            }}
          />
        </div>

        {onAddProdotto && (
          <div className="d-flex justify-content-between align-items-center">
            <BtnCheck
              Title="Inserisci"
              bgColor="btn-primary"
              icon={<Plus width={24} height={24} color="#fff" />}
              onClick={() => {
                onAddProdotto(
                  gContext.features?.trolley?.trolleyListRes
                    .filter((x) => (type != null ? x.type == "R" : x.type != "R"))
                    .map((prod) => {
                      return { productId: prod.productId, productQty: 1 }
                    }) ?? [],
                  false
                )
              }}
            />
          </div>
        )}
      </div>
      <hr />
      <CustomTable
        headers={tableHeaders}
        rows={listTrolley.length > 0 ? listTrolley : [["Nessun prodotto selezionato"]]}
      />
    </div>
  )
}

export default Trolley
