import { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import Input from "../../Structure/Input/Input"
import BtnCheck from "../../Structure/Buttons/BtnCheck"
import CustomTable from "../../Structure/Tables/CustomTable"
import BtnClose from "../../Icons/BtnClose"
import { generateGuid } from "../../Functions/Util/genGuid"
import useUploadHttp, { filesGuid } from "../../Functions/HTTPRequest/Upload"
import { UseFormSetValue } from "react-hook-form"
import { ReqOrderHeaderDto } from "../../../Models/Req/ReqOrderHeader"
import Loading from "../Loader"
import useGetHttp from "../../Functions/HTTPRequest/Get"
import useDeleteHttp from "../../Functions/HTTPRequest/Delete"
import usePostHttp from "../../Functions/HTTPRequest/Post"
import DownloadLink from "react-download-link"
import { handleDownload } from "../../Functions/Util/linkDonwload"
import { GeneralContext } from "../../../Components/UI/MasterPage/MasterPage"
import { LabelLan } from "../../../Models/LabelLan"

type Props = {
  onFileUpload: (a: string) => void
  ordine: ReqOrderHeaderDto
}

const FileUploader = ({ onFileUpload, ordine }: Props) => {
  const [files, setFiles] = useState<File[]>([])
  const [fileDes, setFileDes] = useState<(string | JSX.Element)[][]>([])
  const [typeError, setTypeError] = useState("")
  const [errorLenght, setErrorLenght] = useState(false)

  const { guidOrder, orderId } = ordine
  const { labels } = useContext(GeneralContext)
  const {
    label: {
      files: { upload },
    },
  } = labels as LabelLan

  const uploadClient = useUploadHttp("storage/order/document-upload")
  const allDocument = useGetHttp("storage/order/document-list")
  const deleteDocument = usePostHttp("storage/order/document-delete")

  useEffect(() => {
    const a = async () => {
      if (guidOrder && guidOrder != "00000000-0000-0000-0000-000000000000" && guidOrder != "") {
        await allDocument.fetch({ guidId: guidOrder })
      }
    }
    a()
  }, [])

  useEffect(() => {
    if (allDocument.dataF && !allDocument.isError) {
      setFileDes(() => [
        ...allDocument.dataF.map((file: any) => [
          <p
            style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
            onClick={() => handleDownload(file.fileId, file.fileName)}
          >
            {file.fileName}
          </p>,
          file.size,
          <BtnClose
            name={file.fileName}
            onClick={async (name) => {
              const res = await deleteDocument.fetch(
                { guidOrder, fileName: file.fileName },
                undefined,
                false
              )
              if (res) {
                setFiles((prevFiles) => prevFiles.filter((f) => f.name !== name))
                setFileDes((prevFileDes) => prevFileDes.filter((desc) => desc[1] !== file.size))
              }
            }}
          />,
        ]),
      ])
    } else {
      console.log("Error documenti", allDocument.dataF, allDocument.errorMessage)
    }
  }, [allDocument.dataF, allDocument.isError, allDocument.errorMessage])

  const handleClickUpload = async () => {
    if (files.length > 0) {
      let guid: string
      if (!guidOrder || guidOrder == "" || guidOrder == "00000000-0000-0000-0000-000000000000") {
        guid = generateGuid()
      } else {
        guid = guidOrder!
      }

      const result = await uploadClient.fetch({ guid, files, orderId })
      if (result) {
        onFileUpload(guid)
      }
    }
  }

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    const extValid = ["pdf", "txt", "jpg", "jpeg", "png", "doc", "xls", "xlsx"]
    const newFiles = Array.from(e.target.files || [])
    if (newFiles.length <= 5) {
      const validFiles = newFiles.filter((file) =>
        extValid.includes(file.name.split(".").pop() || "")
      )
      const invalidFiles = newFiles.length - validFiles.length

      setFiles((prevFiles) => [...prevFiles, ...validFiles])
      setFileDes((prevFileDes) => [
        ...prevFileDes,
        ...validFiles.map((file) => [
          file.name,
          file.size.toString(),
          <BtnClose
            name={file.name}
            onClick={async (name) => {
              setFiles((prevFiles) => prevFiles.filter((f) => f.name !== name))
              setFileDes((prevFileDes) => prevFileDes.filter((desc) => desc[0] !== name))
            }}
          />,
        ]),
      ])

      if (invalidFiles > 0) {
        setTypeError(`Ignored ${invalidFiles} invalid file(s).`)
      } else {
        setTypeError(``)
      }
    } else {
      setErrorLenght(true)
    }
  }

  if (uploadClient.isError) {
    return (
      <div className="d-flex justify-content-center align-content-center text-danger my-5 py-5">
        {upload.fail}
      </div>
    )
  }

  if (uploadClient.isSuccess) {
    return (
      <div className="d-flex justify-content-center align-content-center text-success my-5 py-5">
        {upload.success}
      </div>
    )
  }

  if (uploadClient.isLoading || allDocument.isLoading) {
    return (
      <div className="d-flex justify-content-center align-content-center text-success my-5 py-5">
        <Loading easyIcon={true} />
      </div>
    )
  }

  if (errorLenght) {
    return (
      <div className="d-flex justify-content-center align-content-center text-danger my-5 py-5 flex-column gap-3">
        {upload.limit}
        <BtnCheck
          Title={upload.close}
          onClick={() => setErrorLenght(false)}
          bgColor="btn-danger"
          btnStyle="BtnVisualOrderType "
        />
      </div>
    )
  }

  return (
    <div className="d-flex flex-column gap-3 my-2 w-100">
      <div className="d-flex flex-column gap-3">
        <Input
          type="file"
          onChange={(e) => handleFile(e)}
          label={upload.uploadDocument}
          multiple
          placeholder={upload.noFileSelected}
          inputDivStyle="align-items-center flex-md-row flex-column"
        />
        {typeError && <div className="text-danger">{typeError}</div>}
      </div>
      <div className="col-2 d-flex justify-content-center w-100">
        <BtnCheck
          Title={upload.upload}
          onClick={handleClickUpload}
          type="button"
          bgColor={files ? "btn-success" : "btn-secondary"}
        />
      </div>

      <CustomTable
        headers={[upload.list.name, upload.list.size, upload.list.delete]}
        rows={fileDes}
      />
    </div>
  )
}

export default FileUploader
