import React, { SVGProps } from "react"

type LogoSvgProps = SVGProps<SVGSVGElement>

const LogoSvg = (props: LogoSvgProps) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 642 315"
      enableBackground="new 0 0 642 315"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M468.000000,316.000000 
   C312.000000,316.000000 156.500000,316.000000 1.000000,316.000000 
   C1.000000,211.000000 1.000000,106.000000 1.000000,1.000000 
   C215.000000,1.000000 429.000000,1.000000 643.000000,1.000000 
   C643.000000,106.000000 643.000000,211.000000 643.000000,316.000000 
   C584.833313,316.000000 526.666687,316.000000 468.000000,316.000000 
M261.647217,118.996788 
   C261.651520,98.008278 261.698547,77.019661 261.649384,56.031277 
   C261.587341,29.573774 240.798340,7.796959 214.384293,6.954517 
   C199.740646,6.487477 185.072647,6.738863 170.415649,6.744279 
   C130.438980,6.759050 90.456398,6.412352 50.487717,6.997811 
   C24.224861,7.382509 3.226149,29.012012 3.023431,55.322147 
   C2.696170,97.796127 2.689585,140.276382 3.022536,182.750275 
   C3.235636,209.934921 25.300898,231.239014 52.403305,231.255020 
   C105.707741,231.286499 159.012222,231.285889 212.316666,231.255341 
   C239.706863,231.239655 261.240143,209.785431 262.613403,182.031937 
   C267.609894,181.970322 272.630981,182.200714 277.597351,181.781876 
   C286.035370,181.070251 290.900787,175.705872 290.977783,167.307404 
   C291.075531,156.643356 291.062134,145.977280 290.983246,135.312912 
   C290.913727,125.915688 285.481384,120.530228 276.050201,120.293907 
   C271.233887,120.173225 266.417694,120.046127 261.647217,118.996788 
M489.486816,182.001862 
   C492.152832,182.001770 494.819305,182.032822 497.484833,181.996201 
   C509.161346,181.835770 515.051697,175.492874 513.842468,163.915604 
   C513.694214,162.495636 511.455322,160.248123 510.090820,160.176208 
   C502.945679,159.799606 495.766235,159.883881 488.609131,160.122040 
   C487.482422,160.159531 485.741486,161.615585 485.426849,162.736404 
   C484.069122,167.572708 481.010773,169.494598 475.987396,168.519089 
   C471.090515,167.568146 470.849579,164.203629 471.076416,160.255981 
   C471.306763,156.247040 473.885315,155.965485 476.979828,155.981415 
   C487.477112,156.035492 497.977539,155.865173 508.471039,156.071396 
   C512.482666,156.150238 514.317871,155.031570 514.068726,150.723236 
   C513.780640,145.741058 514.043823,140.729004 513.992737,135.730682 
   C513.891113,125.791519 508.454681,120.356285 498.442719,120.309509 
   C485.112762,120.247238 471.782043,120.240524 458.452179,120.311806 
   C448.486908,120.365089 443.085205,125.821472 443.013580,135.854095 
   C442.943390,145.684647 442.981812,155.516113 443.002502,165.347122 
   C443.026031,176.530792 448.293091,181.889206 459.498383,181.984543 
   C469.162109,182.066772 478.827057,182.001770 489.486816,182.001862 
M397.887939,137.666382 
   C397.019531,133.773285 395.854065,129.916748 395.369232,125.976463 
   C394.917236,122.303314 393.001465,121.240341 389.699066,121.274734 
   C383.205872,121.342346 376.711395,121.272453 370.217621,121.298676 
   C364.622009,121.321274 364.016815,122.088821 365.266998,127.368408 
   C369.169922,143.850784 373.157623,160.313919 376.909851,176.830536 
   C377.756653,180.557861 379.403900,182.120972 383.328522,182.068344 
   C396.313995,181.894165 409.305145,181.879105 422.289948,182.077454 
   C426.223785,182.137543 427.849670,180.630051 428.692902,176.902649 
   C432.503082,160.060532 436.467438,143.251892 440.565704,126.477486 
   C441.495636,122.671288 440.542023,121.190048 436.613800,121.259560 
   C429.789001,121.380333 422.954895,121.458275 416.135803,121.227264 
   C412.373474,121.099800 410.702606,122.698868 410.073303,126.206795 
   C409.135223,131.436020 408.018402,136.634476 406.908600,141.831284 
   C405.707855,147.453934 404.427795,153.059647 403.182312,158.672745 
   C402.865906,158.609467 402.549500,158.546188 402.233093,158.482895 
   C400.835480,151.822220 399.437836,145.161560 397.887939,137.666382 
M521.289795,122.500031 
   C521.289978,137.164886 521.229980,151.830063 521.312134,166.494461 
   C521.363953,175.743790 526.072327,181.266785 534.870544,181.829697 
   C541.841736,182.275711 548.861023,181.995407 555.858887,181.985168 
   C558.684998,181.981033 560.119690,180.608505 560.029602,177.636963 
   C559.923645,174.140640 559.789001,170.621628 560.061584,167.143402 
   C560.390259,162.949890 559.129883,160.764236 554.541870,161.251572 
   C551.254700,161.600723 549.573853,160.064682 549.689087,156.591675 
   C549.810547,152.929474 549.462036,149.232346 549.856140,145.608932 
   C549.996155,144.321930 551.567139,142.353836 552.736084,142.152084 
   C559.982178,140.901505 560.005920,141.039062 560.005981,133.555954 
   C560.005981,131.556198 559.776428,129.524292 560.051270,127.563042 
   C560.737549,122.665009 558.674011,120.680389 553.894653,121.292702 
   C550.533447,121.723335 549.534729,120.155975 549.662842,116.971405 
   C549.856934,112.146545 549.664917,107.307419 549.726501,102.475494 
   C549.766174,99.363586 548.462280,97.910225 545.235291,97.963585 
   C538.737854,98.070999 532.236267,98.070961 525.738831,97.963921 
   C522.491699,97.910416 521.246948,99.409554 521.275208,102.502670 
   C521.332947,108.834679 521.290894,115.167587 521.289795,122.500031 
M566.290588,163.369858 
   C566.291260,177.749405 570.594543,182.001480 585.150635,182.003723 
   C590.146667,182.004486 595.143188,181.963959 600.138672,182.016327 
   C603.392151,182.050446 605.127441,180.761078 605.028687,177.281693 
   C604.915283,173.288071 605.340271,169.244705 604.828003,165.314667 
   C604.636047,163.842026 602.615295,161.685349 601.172180,161.456604 
   C594.627563,160.419250 594.343445,160.367905 594.290588,153.869827 
   C594.267517,151.038971 593.961121,148.148636 594.447266,145.402832 
   C594.673096,144.127060 596.363647,142.325058 597.595520,142.141830 
   C604.983582,141.042770 605.003601,141.177307 605.003723,133.603012 
   C605.003723,131.604584 604.793518,129.579239 605.045349,127.613075 
   C605.661682,122.802208 603.734070,120.696632 598.893860,121.286522 
   C595.421631,121.709702 594.098389,120.145462 594.239380,116.683914 
   C594.429016,112.028648 594.114685,107.351395 594.343506,102.699654 
   C594.515198,99.210258 593.178406,97.877831 589.739685,97.959198 
   C583.914246,98.097069 578.070618,98.231369 572.258362,97.921341 
   C567.653931,97.675735 566.161316,99.359810 566.207458,103.950218 
   C566.403381,123.432861 566.290710,142.918610 566.290588,163.369858 
M324.285492,115.502487 
   C324.285339,111.341179 324.126465,107.171967 324.333344,103.020973 
   C324.511200,99.452583 323.353210,97.813301 319.537903,97.941429 
   C313.386108,98.148018 307.219238,98.113457 301.064758,97.951790 
   C297.549438,97.859451 296.285980,99.511070 296.287811,102.749405 
   C296.301819,127.550751 296.315308,152.352142 296.272247,177.153412 
   C296.265839,180.856323 298.083679,182.112198 301.561493,182.035461 
   C307.051758,181.914322 312.558960,181.776306 318.035767,182.073395 
   C322.681732,182.325424 324.465454,180.933868 324.396820,175.923645 
   C324.125580,156.119568 324.285583,136.309570 324.285492,115.502487 
M359.709167,175.175415 
   C359.709045,158.851257 359.760284,142.526657 359.622009,126.203674 
   C359.609467,124.724220 358.420868,121.997597 357.704895,121.972809 
   C349.136475,121.676109 340.554169,121.781181 331.717896,121.781181 
   C331.717896,141.017685 331.754456,159.492661 331.682587,177.967224 
   C331.670319,181.116241 333.260284,182.022446 336.012909,182.009827 
   C341.842834,181.983093 347.682892,181.794785 353.499847,182.071014 
   C357.986115,182.284042 360.156555,180.703659 359.709167,175.175415 
M637.026794,181.822174 
   C637.779724,180.016983 639.168457,178.218124 639.186523,176.405594 
   C639.353943,159.585403 639.292908,142.762833 639.269836,125.941010 
   C639.268127,124.666916 639.049805,123.393127 638.895630,121.724289 
   C630.487671,121.724289 622.354858,121.581268 614.240540,121.902473 
   C613.208252,121.943329 611.434021,124.401230 611.418579,125.755516 
   C611.224609,142.741730 611.348816,159.731247 611.247864,176.719070 
   C611.224854,180.591217 612.799072,182.126175 616.616211,182.041168 
   C623.108643,181.896545 629.606384,181.988510 637.026794,181.822174 
M135.562469,290.501434 
   C135.562469,284.753296 135.562469,279.005188 135.562469,273.539215 
   C131.412796,272.055634 129.744171,273.720245 128.362289,276.712830 
   C125.238129,283.478455 121.831932,290.113800 118.212418,297.468719 
   C114.573982,290.249054 111.008018,283.554932 107.821312,276.684845 
   C106.284996,273.372772 104.307846,272.108337 100.589798,273.463013 
   C100.589798,285.440735 100.589798,297.515686 100.589798,310.014435 
   C102.187531,309.885590 103.533501,309.777069 105.059875,309.653992 
   C105.059875,300.768738 105.059875,292.353546 105.059875,283.938354 
   C108.568893,288.831207 111.143250,294.115845 114.057709,299.205750 
   C114.995926,300.844330 116.830132,301.969818 118.254433,303.330048 
   C119.528160,301.984131 121.154816,300.830383 122.011192,299.257294 
   C124.881737,293.984314 127.477280,288.561646 130.178101,283.196320 
   C130.439682,283.312469 130.701248,283.428619 130.962814,283.544769 
   C130.962814,292.181549 130.962814,300.818329 130.962814,309.724487 
   C132.489197,309.724487 133.869614,309.724487 135.562195,309.724487 
   C135.562195,303.453674 135.562195,297.476929 135.562469,290.501434 
M568.303650,296.488129 
   C568.303894,299.817749 568.248352,303.148560 568.319153,306.476685 
   C568.400146,310.281158 568.448975,310.280121 573.074585,309.071899 
   C573.074585,300.175262 573.074585,291.146790 573.074585,281.005890 
   C574.490479,282.785645 575.214172,283.606567 575.840210,284.496277 
   C581.202026,292.115387 586.429993,299.833405 591.989136,307.305267 
   C593.166260,308.887451 595.533997,309.583832 598.018066,311.092041 
   C598.018066,297.402252 598.018066,285.333588 598.018066,273.272461 
   C593.930054,273.257172 593.862061,273.256836 593.844788,276.693512 
   C593.803101,284.985199 593.831238,293.277252 593.831238,302.890533 
   C587.029053,293.112152 581.174316,284.452606 575.001099,276.026367 
   C573.762024,274.335052 571.311646,273.531158 568.303833,271.602661 
   C568.303833,280.877289 568.303833,288.184998 568.303650,296.488129 
M187.303497,277.773560 
   C187.303497,288.400482 187.303497,299.027405 187.303497,309.485657 
   C190.880020,310.781189 191.982574,309.696594 191.868271,306.460541 
   C191.686142,301.304016 191.819992,296.136322 191.820145,290.973358 
   C191.820221,287.982025 191.820145,284.990692 191.820145,281.999390 
   C198.936569,290.338226 204.756439,298.848145 210.863754,307.146576 
   C212.058578,308.770081 214.367416,309.573730 217.098358,311.371704 
   C217.098358,297.514526 217.098358,285.439270 217.098358,273.254700 
   C215.597961,273.254700 214.343918,273.254700 212.830826,273.254700 
   C212.830826,278.191589 212.830811,282.847046 212.830811,287.502502 
   C212.830811,292.190460 212.830826,296.878387 212.830826,302.845612 
   C211.051514,300.317352 210.022079,298.864227 209.002502,297.404266 
   C203.762375,289.900665 198.620911,282.324341 193.186508,274.964264 
   C192.335449,273.811646 190.157150,273.639008 188.592575,273.013214 
   C188.163010,274.294098 187.733444,275.575012 187.303497,277.773560 
M639.284912,104.644333 
   C639.233398,98.008263 639.233398,98.004066 632.659607,97.997871 
   C626.995605,97.992523 621.330383,98.070946 615.668213,97.969208 
   C612.700562,97.915886 611.336487,99.285172 611.310364,102.124786 
   C611.273560,106.122543 611.136108,110.131737 611.402832,114.111832 
   C611.475891,115.202194 612.816101,117.092316 613.614136,117.113220 
   C622.031555,117.333679 630.456970,117.249664 639.285095,117.249664 
   C639.285095,112.876617 639.285095,109.240944 639.284912,104.644333 
M354.136108,97.997017 
   C348.144836,97.997414 342.147186,97.848122 336.166901,98.104614 
   C334.691772,98.167885 332.082458,99.469467 332.022522,100.341240 
   C331.641418,105.883583 331.828735,111.465019 331.828735,117.275917 
   C340.608948,117.275917 348.586823,117.306793 356.563721,117.232193 
   C357.442566,117.223969 359.078033,116.612007 359.078156,116.279579 
   C359.080292,110.561470 358.963593,104.837494 358.567505,99.136032 
   C358.535675,98.677498 356.276855,98.373665 354.136108,97.997017 
M474.528839,272.974152 
   C472.266235,272.974152 470.003632,272.974152 467.646545,272.974152 
   C467.646545,285.627777 467.646545,297.674042 467.646545,309.791992 
   C472.924133,309.791992 477.937866,310.234131 482.834778,309.667175 
   C487.696564,309.104309 492.566528,307.441345 493.150513,301.425537 
   C493.376038,299.102692 492.985626,296.316376 491.818939,294.377014 
   C490.680267,292.484161 488.193054,291.402557 486.060577,289.784790 
   C490.405731,287.792969 491.990295,284.314484 490.971832,279.745605 
   C489.963501,275.222198 486.304138,274.017181 482.393768,273.421906 
   C480.117310,273.075348 477.782745,273.110657 474.528839,272.974152 
M633.960205,303.368317 
   C638.599915,295.723999 639.203918,287.544464 634.286499,280.261993 
   C627.820984,270.686646 617.313782,273.320923 607.601074,272.993744 
   C607.601074,285.484863 607.601074,297.550812 607.601074,310.030945 
   C611.008240,310.030945 614.140869,310.091034 617.270325,310.018158 
   C623.374207,309.876007 629.225037,308.986603 633.960205,303.368317 
M303.596100,296.839844 
   C303.012573,296.059906 302.429016,295.279968 301.731232,294.347321 
   C302.765472,293.789215 303.638977,293.335449 304.495972,292.852448 
   C308.940948,290.347321 310.677795,286.484833 309.951263,281.532227 
   C309.241486,276.693787 305.959015,274.310638 301.449554,273.791809 
   C296.258545,273.194519 291.014160,273.060730 285.634094,272.715454 
   C285.634094,285.617615 285.634094,297.677338 285.634094,309.755859 
   C287.210632,309.755859 288.463867,309.755859 290.224365,309.755859 
   C290.224365,304.764709 290.224365,300.018555 290.224365,295.471344 
   C295.126495,294.094055 298.079468,295.800751 300.158936,299.704926 
   C301.484802,302.194275 303.112823,304.525177 304.645844,306.901031 
   C306.859802,310.332275 306.875458,310.322174 311.535126,309.254944 
   C308.988068,305.256439 306.498352,301.347961 303.596100,296.839844 
M348.423492,308.916901 
   C349.796387,309.338318 351.169281,309.759735 353.218933,310.388885 
   C353.218933,304.758484 353.218933,300.018158 353.218933,295.472351 
   C358.101074,294.097565 361.064026,295.779663 363.146515,299.679840 
   C364.473480,302.165009 366.311829,304.383850 367.568146,306.898346 
   C369.093628,309.951630 371.166473,310.770599 374.657379,309.400940 
   C371.335327,304.238464 368.149750,299.288116 364.958313,294.328644 
   C366.630280,293.257751 368.262543,292.406738 369.667389,291.273712 
   C373.035614,288.557129 374.102814,284.559723 372.656891,280.946808 
   C371.572845,278.238037 368.791992,275.002625 366.184021,274.357758 
   C360.568512,272.969238 354.577545,273.099182 348.335236,272.573578 
   C348.335236,285.117737 348.335236,296.565582 348.423492,308.916901 
M522.827393,276.805939 
   C516.195190,271.322845 508.376556,273.410004 500.605103,272.935333 
   C500.605103,285.431122 500.605103,297.495483 500.605103,309.962189 
   C502.174774,309.877106 503.533020,309.803467 505.220642,309.712006 
   C505.220642,304.682190 505.220642,300.066803 505.220642,295.523438 
   C509.883789,294.083313 512.893188,295.595245 515.032043,299.482452 
   C516.472473,302.100342 517.906799,304.813477 519.862549,307.025360 
   C521.302307,308.653656 523.581299,309.539856 525.486267,310.756714 
   C525.860657,310.285919 526.235046,309.815125 526.609375,309.344360 
   C523.398926,304.360565 520.188477,299.376801 516.977600,294.396240 
   C525.475098,289.087921 526.978027,285.062378 522.827393,276.805939 
M555.733215,303.760956 
   C557.159607,306.826843 557.123413,311.794647 563.111389,309.388275 
   C558.613770,298.065094 554.259277,286.922089 549.694519,275.865936 
   C549.191650,274.647827 547.525513,273.243835 546.323059,273.170624 
   C545.152649,273.099396 543.227234,274.357788 542.761780,275.494415 
   C538.161621,286.728729 533.796387,298.059204 529.342773,309.418213 
   C532.744873,310.865631 534.192627,309.733398 535.439148,306.549744 
   C536.377441,304.153290 535.668213,300.005280 540.188171,300.026367 
   C544.186279,300.045044 548.204102,299.814087 552.170593,300.169342 
   C553.339661,300.274078 554.363770,301.998322 555.733215,303.760956 
M12.131553,289.470520 
   C9.547697,296.112518 6.963841,302.754486 4.362339,309.441864 
   C7.890873,310.900146 9.716752,309.747742 10.317130,306.400024 
   C11.283364,301.012329 14.477676,298.978088 19.791672,300.006287 
   C20.594925,300.161743 21.480639,300.163239 22.283178,300.006042 
   C27.574598,298.969574 30.801027,300.856781 31.747618,306.351135 
   C32.376183,309.999573 34.607994,310.827362 38.127205,309.449127 
   C33.622368,298.097595 29.213816,286.795593 24.575068,275.588898 
   C24.094467,274.427826 22.228626,273.143158 21.035133,273.171326 
   C19.856153,273.199127 18.201124,274.583710 17.649549,275.784119 
   C15.705084,280.015900 14.144711,284.424225 12.131553,289.470520 
M268.465118,310.023376 
   C271.602844,310.023376 274.740601,310.023376 277.834839,310.023376 
   C278.277771,306.123657 278.278961,306.040802 275.185883,305.996216 
   C270.260345,305.925232 265.332977,305.977264 260.367615,305.977264 
   C260.367615,301.359497 260.367615,297.432617 260.367615,293.023224 
   C264.216095,293.023224 267.851776,293.203979 271.453949,292.935425 
   C272.921173,292.826019 274.315704,291.741333 275.743317,291.100372 
   C274.382629,290.392517 273.060425,289.181885 271.653778,289.074127 
   C267.915009,288.787659 264.139801,288.976837 260.376282,288.976837 
   C260.376282,284.674866 260.376282,281.080017 260.376282,276.983459 
   C266.363586,276.983459 272.112579,276.983459 277.861542,276.983459 
   C278.113373,276.587250 278.365204,276.191010 278.617004,275.794800 
   C277.763153,274.858978 276.935486,273.141388 276.051239,273.111755 
   C269.290619,272.885162 262.518982,272.987396 255.676071,272.987396 
   C255.676071,285.637390 255.676071,297.701843 255.676071,310.023376 
   C259.838013,310.023376 263.660278,310.023376 268.465118,310.023376 
M157.334564,282.555023 
   C157.334564,291.638489 157.334564,300.721954 157.334564,309.812134 
   C165.385330,309.812134 172.666412,309.812134 179.960083,309.812134 
   C181.321747,306.553009 179.861420,305.885406 177.083191,305.946594 
   C172.166199,306.054962 167.245087,305.976990 162.405640,305.976990 
   C162.405640,301.282623 162.405640,297.353668 162.405640,293.022095 
   C166.636963,293.022095 170.609695,293.165649 174.559677,292.929108 
   C175.654922,292.863556 176.682587,291.669556 177.741257,290.993286 
   C176.678101,290.321472 175.644394,289.132751 174.546524,289.070190 
   C170.478638,288.838348 166.389587,288.977875 162.417053,288.977875 
   C162.417053,284.592499 162.417053,280.995392 162.417053,276.912781 
   C168.449005,276.912781 174.203537,276.912781 179.958069,276.912781 
   C180.195374,276.503052 180.432663,276.093323 180.669968,275.683594 
   C179.761353,274.784424 178.877548,273.136993 177.940125,273.105927 
   C171.194595,272.882080 164.438202,272.986084 157.334763,272.986084 
   C157.334763,276.295227 157.334763,278.935150 157.334564,282.555023 
M433.652802,288.976501 
   C432.221710,288.877655 430.790619,288.778778 429.381104,288.681427 
   C429.381104,284.665558 429.381104,281.070282 429.381104,276.974548 
   C435.374542,276.974548 441.124756,276.974548 446.875000,276.974548 
   C447.124878,276.576202 447.374725,276.177887 447.624603,275.779541 
   C446.763000,274.848724 445.927399,273.140717 445.035583,273.110870 
   C438.277008,272.884583 431.507416,272.987122 424.690369,272.987122 
   C424.690369,285.650574 424.690369,297.712036 424.690369,309.832703 
   C432.332764,309.832703 439.620331,309.832703 446.888458,309.832703 
   C447.237488,306.117004 447.238586,306.037933 444.139282,305.995148 
   C439.217316,305.927246 434.293701,305.977051 429.385071,305.977051 
   C429.385071,301.323639 429.385071,297.395447 429.385071,293.013275 
   C433.925018,293.013275 438.222351,293.109222 442.507599,292.924683 
   C443.277740,292.891510 443.998108,291.701843 444.741455,291.046112 
   C444.028900,290.360229 443.365326,289.155396 442.594910,289.082611 
   C439.952240,288.833130 437.272095,288.980469 433.652802,288.976501 
M318.314514,291.500000 
   C318.314514,297.588593 318.314514,303.677185 318.314514,309.744843 
   C320.236664,309.744843 321.487610,309.744843 323.191040,309.744843 
   C323.191040,305.061829 323.191040,300.637817 323.191040,296.483551 
   C327.415771,295.979523 331.242493,295.887268 334.876556,295.003143 
   C340.184784,293.711700 343.111359,289.311371 342.950317,283.870880 
   C342.790588,278.473907 339.995605,274.869904 334.306915,273.955536 
   C329.184631,273.132202 323.938568,273.079071 318.314728,272.653534 
   C318.314728,279.251007 318.314728,284.876038 318.314514,291.500000 
M394.194275,281.678986 
   C393.961792,285.665070 396.024811,288.325165 399.302002,290.132599 
   C402.209869,291.736359 405.510193,292.769012 408.106628,294.749481 
   C409.956177,296.160248 412.326385,299.058807 411.937256,300.691010 
   C411.437958,302.785461 408.596954,305.386902 406.418060,305.763489 
   C403.232666,306.313934 399.747253,305.003479 396.372345,304.653595 
   C395.284393,304.540802 394.141693,304.955963 393.024048,305.129608 
   C393.633759,306.228699 394.118866,308.178131 394.873413,308.288635 
   C399.706909,308.996826 404.672028,310.010132 409.450623,309.521759 
   C413.523010,309.105560 416.501770,306.020294 417.082153,301.420532 
   C417.664642,296.803680 415.476196,293.771851 411.636383,291.693604 
   C408.425629,289.955750 404.942108,288.624115 402.005798,286.518097 
   C400.532776,285.461578 399.059753,282.922577 399.322021,281.342041 
   C399.589355,279.731354 401.824005,277.563538 403.487122,277.267426 
   C406.241943,276.776886 409.228058,277.762970 412.118774,277.779907 
   C413.475494,277.787903 414.836975,276.987793 417.285919,276.206055 
   C406.135620,270.029877 397.306946,272.329803 394.194275,281.678986 
M228.462067,277.001038 
   C230.066330,277.079224 231.670578,277.157440 233.816620,277.262054 
   C233.816620,288.315796 233.816620,299.037231 233.816620,309.571075 
   C237.415237,310.653290 238.624176,309.590179 238.473541,306.383331 
   C238.286240,302.395966 238.429474,298.393097 238.429611,294.396912 
   C238.429794,288.661804 238.429657,282.926697 238.429657,276.868591 
   C242.685364,276.868591 246.137161,276.868591 251.227402,276.868591 
   C249.596939,274.919617 248.819366,273.157166 247.996552,273.135803 
   C240.009674,272.928284 232.014648,272.972412 224.024048,273.089386 
   C223.382599,273.098755 222.137787,274.372131 222.247192,274.728882 
   C222.509903,275.585602 223.281754,276.604218 224.087906,276.896545 
   C225.119476,277.270752 226.386398,276.996063 228.462067,277.001038 
M69.429848,304.413269 
   C69.429848,295.345367 69.429848,286.277435 69.429848,276.887939 
   C73.645699,276.887939 77.097809,276.887939 80.549911,276.887939 
   C80.844521,276.467041 81.139137,276.046112 81.433746,275.625214 
   C80.472870,274.751709 79.532051,273.147675 78.547943,273.120697 
   C70.898773,272.910767 63.239822,272.937317 55.588402,273.101135 
   C54.751999,273.119080 53.944099,274.468231 53.122978,275.199951 
   C53.911320,275.797852 54.666634,276.851471 55.493965,276.911407 
   C58.411617,277.122894 61.353962,276.993683 64.821548,276.993683 
   C64.821548,288.325043 64.821548,299.041016 64.821548,309.560974 
   C69.699783,311.056366 69.565971,308.226349 69.429848,304.413269 
M380.599182,298.486420 
   C380.599182,302.246674 380.599182,306.006927 380.599182,309.810364 
   C382.548218,309.810364 383.811218,309.810364 385.339691,309.810364 
   C385.339691,297.523407 385.339691,285.463989 385.339691,273.021393 
   C383.671417,273.097748 382.293030,273.160858 380.599030,273.238403 
   C380.599030,281.581238 380.599030,289.536346 380.599182,298.486420 
M91.612045,296.487091 
   C91.612045,288.738068 91.612045,280.989014 91.612045,272.852325 
   C89.481995,273.058594 88.106201,273.191833 86.851990,273.313293 
   C86.851990,285.731873 86.851990,297.649506 86.851990,309.701965 
   C88.473129,309.701965 89.835274,309.701965 91.611778,309.701965 
   C91.611778,305.410187 91.611778,301.445435 91.612045,296.487091 
z"
      />
      <path
        fill="#00AA52"
        opacity="1.000000"
        stroke="none"
        d="
M261.639221,182.219131 
   C261.240143,209.785431 239.706863,231.239655 212.316666,231.255341 
   C159.012222,231.285889 105.707741,231.286499 52.403305,231.255020 
   C25.300898,231.239014 3.235636,209.934921 3.022536,182.750275 
   C2.689585,140.276382 2.696170,97.796127 3.023431,55.322147 
   C3.226149,29.012012 24.224861,7.382509 50.487717,6.997811 
   C90.456398,6.412352 130.438980,6.759050 170.415649,6.744279 
   C185.072647,6.738863 199.740646,6.487477 214.384293,6.954517 
   C240.798340,7.796959 261.587341,29.573774 261.649384,56.031277 
   C261.698547,77.019661 261.651520,98.008278 261.187988,119.527985 
   C252.236404,120.080101 243.743744,120.066925 235.251678,120.130936 
   C225.573288,120.203888 220.094925,125.620636 220.016251,135.319351 
   C219.931183,145.809937 219.939743,156.302124 220.012589,166.792877 
   C220.080292,176.542511 225.388550,181.842026 235.175659,181.988464 
   C243.996017,182.120453 252.817978,182.146408 261.639221,182.219131 
M118.787247,178.917679 
   C128.356308,182.965103 137.861969,182.979263 147.189041,178.242432 
   C152.324402,175.634415 155.023529,171.688675 154.987259,165.512939 
   C154.805420,134.554550 154.813202,103.594025 154.983353,72.635498 
   C155.015930,66.707924 152.565552,62.728298 147.604691,60.155994 
   C137.814560,55.079620 127.819740,54.944477 117.892807,59.724899 
   C112.537674,62.303730 109.830055,66.320045 109.883743,72.772423 
   C110.138557,103.396858 110.158447,134.026077 109.872528,164.649963 
   C109.809433,171.408737 112.269058,175.762161 118.787247,178.917679 
z"
      />
      <path
        fill="#D6223D"
        opacity="1.000000"
        stroke="none"
        d="
M262.126312,182.125534 
   C252.817978,182.146408 243.996017,182.120453 235.175659,181.988464 
   C225.388550,181.842026 220.080292,176.542511 220.012589,166.792877 
   C219.939743,156.302124 219.931183,145.809937 220.016251,135.319351 
   C220.094925,125.620636 225.573288,120.203888 235.251678,120.130936 
   C243.743744,120.066925 252.236404,120.080101 261.165131,119.990555 
   C266.417694,120.046127 271.233887,120.173225 276.050201,120.293907 
   C285.481384,120.530228 290.913727,125.915688 290.983246,135.312912 
   C291.062134,145.977280 291.075531,156.643356 290.977783,167.307404 
   C290.900787,175.705872 286.035370,181.070251 277.597351,181.781876 
   C272.630981,182.200714 267.609894,181.970322 262.126312,182.125534 
M249.703003,162.172134 
   C249.275497,166.765503 252.141937,167.999237 255.896454,167.942673 
   C259.692841,167.885483 261.474335,166.009216 261.329468,161.920670 
   C261.076172,154.770782 261.011414,147.593719 261.350006,140.450912 
   C261.559784,136.025681 259.456390,134.173309 255.627228,134.161224 
   C251.900299,134.149429 249.443420,135.738449 249.627960,140.266220 
   C249.912476,147.246994 249.702515,154.247925 249.703003,162.172134 
z"
      />
      <path
        fill="#D7213E"
        opacity="1.000000"
        stroke="none"
        d="
M488.989136,182.001862 
   C478.827057,182.001770 469.162109,182.066772 459.498383,181.984543 
   C448.293091,181.889206 443.026031,176.530792 443.002502,165.347122 
   C442.981812,155.516113 442.943390,145.684647 443.013580,135.854095 
   C443.085205,125.821472 448.486908,120.365089 458.452179,120.311806 
   C471.782043,120.240524 485.112762,120.247238 498.442719,120.309509 
   C508.454681,120.356285 513.891113,125.791519 513.992737,135.730682 
   C514.043823,140.729004 513.780640,145.741058 514.068726,150.723236 
   C514.317871,155.031570 512.482666,156.150238 508.471039,156.071396 
   C497.977539,155.865173 487.477112,156.035492 476.979828,155.981415 
   C473.885315,155.965485 471.306763,156.247040 471.076416,160.255981 
   C470.849579,164.203629 471.090515,167.568146 475.987396,168.519089 
   C481.010773,169.494598 484.069122,167.572708 485.426849,162.736404 
   C485.741486,161.615585 487.482422,160.159531 488.609131,160.122040 
   C495.766235,159.883881 502.945679,159.799606 510.090820,160.176208 
   C511.455322,160.248123 513.694214,162.495636 513.842468,163.915604 
   C515.051697,175.492874 509.161346,181.835770 497.484833,181.996201 
   C494.819305,182.032822 492.152832,182.001770 488.989136,182.001862 
M476.661316,143.011597 
   C478.478973,143.011353 480.328369,143.216583 482.103638,142.942139 
   C483.390198,142.743195 485.537170,142.028442 485.624054,141.333893 
   C485.850861,139.521698 485.592346,136.222351 484.752014,135.955200 
   C481.481476,134.915497 477.829346,134.223358 474.489227,134.670959 
   C473.134705,134.852463 471.563568,138.185455 471.246796,140.260818 
   C470.731384,143.637711 473.718597,142.884689 476.661316,143.011597 
z"
      />
      <path
        fill="#D7223E"
        opacity="1.000000"
        stroke="none"
        d="
M397.964050,138.083633 
   C399.437836,145.161560 400.835480,151.822220 402.233093,158.482895 
   C402.549500,158.546188 402.865906,158.609467 403.182312,158.672745 
   C404.427795,153.059647 405.707855,147.453934 406.908600,141.831284 
   C408.018402,136.634476 409.135223,131.436020 410.073303,126.206795 
   C410.702606,122.698868 412.373474,121.099800 416.135803,121.227264 
   C422.954895,121.458275 429.789001,121.380333 436.613800,121.259560 
   C440.542023,121.190048 441.495636,122.671288 440.565704,126.477486 
   C436.467438,143.251892 432.503082,160.060532 428.692902,176.902649 
   C427.849670,180.630051 426.223785,182.137543 422.289948,182.077454 
   C409.305145,181.879105 396.313995,181.894165 383.328522,182.068344 
   C379.403900,182.120972 377.756653,180.557861 376.909851,176.830536 
   C373.157623,160.313919 369.169922,143.850784 365.266998,127.368408 
   C364.016815,122.088821 364.622009,121.321274 370.217621,121.298676 
   C376.711395,121.272453 383.205872,121.342346 389.699066,121.274734 
   C393.001465,121.240341 394.917236,122.303314 395.369232,125.976463 
   C395.854065,129.916748 397.019531,133.773285 397.964050,138.083633 
z"
      />
      <path
        fill="#D7233F"
        opacity="1.000000"
        stroke="none"
        d="
M521.289856,122.000076 
   C521.290894,115.167587 521.332947,108.834679 521.275208,102.502670 
   C521.246948,99.409554 522.491699,97.910416 525.738831,97.963921 
   C532.236267,98.070961 538.737854,98.070999 545.235291,97.963585 
   C548.462280,97.910225 549.766174,99.363586 549.726501,102.475494 
   C549.664917,107.307419 549.856934,112.146545 549.662842,116.971405 
   C549.534729,120.155975 550.533447,121.723335 553.894653,121.292702 
   C558.674011,120.680389 560.737549,122.665009 560.051270,127.563042 
   C559.776428,129.524292 560.005981,131.556198 560.005981,133.555954 
   C560.005920,141.039062 559.982178,140.901505 552.736084,142.152084 
   C551.567139,142.353836 549.996155,144.321930 549.856140,145.608932 
   C549.462036,149.232346 549.810547,152.929474 549.689087,156.591675 
   C549.573853,160.064682 551.254700,161.600723 554.541870,161.251572 
   C559.129883,160.764236 560.390259,162.949890 560.061584,167.143402 
   C559.789001,170.621628 559.923645,174.140640 560.029602,177.636963 
   C560.119690,180.608505 558.684998,181.981033 555.858887,181.985168 
   C548.861023,181.995407 541.841736,182.275711 534.870544,181.829697 
   C526.072327,181.266785 521.363953,175.743790 521.312134,166.494461 
   C521.229980,151.830063 521.289978,137.164886 521.289856,122.000076 
z"
      />
      <path
        fill="#D7223E"
        opacity="1.000000"
        stroke="none"
        d="
M566.290649,162.886536 
   C566.290710,142.918610 566.403381,123.432861 566.207458,103.950218 
   C566.161316,99.359810 567.653931,97.675735 572.258362,97.921341 
   C578.070618,98.231369 583.914246,98.097069 589.739685,97.959198 
   C593.178406,97.877831 594.515198,99.210258 594.343506,102.699654 
   C594.114685,107.351395 594.429016,112.028648 594.239380,116.683914 
   C594.098389,120.145462 595.421631,121.709702 598.893860,121.286522 
   C603.734070,120.696632 605.661682,122.802208 605.045349,127.613075 
   C604.793518,129.579239 605.003723,131.604584 605.003723,133.603012 
   C605.003601,141.177307 604.983582,141.042770 597.595520,142.141830 
   C596.363647,142.325058 594.673096,144.127060 594.447266,145.402832 
   C593.961121,148.148636 594.267517,151.038971 594.290588,153.869827 
   C594.343445,160.367905 594.627563,160.419250 601.172180,161.456604 
   C602.615295,161.685349 604.636047,163.842026 604.828003,165.314667 
   C605.340271,169.244705 604.915283,173.288071 605.028687,177.281693 
   C605.127441,180.761078 603.392151,182.050446 600.138672,182.016327 
   C595.143188,181.963959 590.146667,182.004486 585.150635,182.003723 
   C570.594543,182.001480 566.291260,177.749405 566.290649,162.886536 
z"
      />
      <path
        fill="#D7223E"
        opacity="1.000000"
        stroke="none"
        d="
M324.285583,116.002129 
   C324.285583,136.309570 324.125580,156.119568 324.396820,175.923645 
   C324.465454,180.933868 322.681732,182.325424 318.035767,182.073395 
   C312.558960,181.776306 307.051758,181.914322 301.561493,182.035461 
   C298.083679,182.112198 296.265839,180.856323 296.272247,177.153412 
   C296.315308,152.352142 296.301819,127.550751 296.287811,102.749405 
   C296.285980,99.511070 297.549438,97.859451 301.064758,97.951790 
   C307.219238,98.113457 313.386108,98.148018 319.537903,97.941429 
   C323.353210,97.813301 324.511200,99.452583 324.333344,103.020973 
   C324.126465,107.171967 324.285339,111.341179 324.285583,116.002129 
z"
      />
      <path
        fill="#D7223E"
        opacity="1.000000"
        stroke="none"
        d="
M359.708984,175.633530 
   C360.156555,180.703659 357.986115,182.284042 353.499847,182.071014 
   C347.682892,181.794785 341.842834,181.983093 336.012909,182.009827 
   C333.260284,182.022446 331.670319,181.116241 331.682587,177.967224 
   C331.754456,159.492661 331.717896,141.017685 331.717896,121.781181 
   C340.554169,121.781181 349.136475,121.676109 357.704895,121.972809 
   C358.420868,121.997597 359.609467,124.724220 359.622009,126.203674 
   C359.760284,142.526657 359.709045,158.851257 359.708984,175.633530 
z"
      />
      <path
        fill="#D7223F"
        opacity="1.000000"
        stroke="none"
        d="
M636.564331,181.901611 
   C629.606384,181.988510 623.108643,181.896545 616.616211,182.041168 
   C612.799072,182.126175 611.224854,180.591217 611.247864,176.719070 
   C611.348816,159.731247 611.224609,142.741730 611.418579,125.755516 
   C611.434021,124.401230 613.208252,121.943329 614.240540,121.902473 
   C622.354858,121.581268 630.487671,121.724289 638.895630,121.724289 
   C639.049805,123.393127 639.268127,124.666916 639.269836,125.941010 
   C639.292908,142.762833 639.353943,159.585403 639.186523,176.405594 
   C639.168457,178.218124 637.779724,180.016983 636.564331,181.901611 
z"
      />
      <path
        fill="#14AC5D"
        opacity="1.000000"
        stroke="none"
        d="
M135.562332,291.000793 
   C135.562195,297.476929 135.562195,303.453674 135.562195,309.724487 
   C133.869614,309.724487 132.489197,309.724487 130.962814,309.724487 
   C130.962814,300.818329 130.962814,292.181549 130.962814,283.544769 
   C130.701248,283.428619 130.439682,283.312469 130.178101,283.196320 
   C127.477280,288.561646 124.881737,293.984314 122.011192,299.257294 
   C121.154816,300.830383 119.528160,301.984131 118.254440,303.330048 
   C116.830132,301.969818 114.995926,300.844330 114.057709,299.205750 
   C111.143250,294.115845 108.568893,288.831207 105.059875,283.938354 
   C105.059875,292.353546 105.059875,300.768738 105.059875,309.653992 
   C103.533501,309.777069 102.187531,309.885590 100.589798,310.014435 
   C100.589798,297.515686 100.589798,285.440735 100.589798,273.463013 
   C104.307846,272.108337 106.284996,273.372772 107.821312,276.684845 
   C111.008018,283.554932 114.573982,290.249054 118.212418,297.468719 
   C121.831932,290.113800 125.238129,283.478455 128.362289,276.712830 
   C129.744171,273.720245 131.412796,272.055634 135.562469,273.539215 
   C135.562469,279.005188 135.562469,284.753296 135.562332,291.000793 
z"
      />
      <path
        fill="#18AE61"
        opacity="1.000000"
        stroke="none"
        d="
M568.303711,295.990417 
   C568.303833,288.184998 568.303833,280.877289 568.303833,271.602661 
   C571.311646,273.531158 573.762024,274.335052 575.001099,276.026367 
   C581.174316,284.452606 587.029053,293.112152 593.831238,302.890533 
   C593.831238,293.277252 593.803101,284.985199 593.844788,276.693512 
   C593.862061,273.256836 593.930054,273.257172 598.018066,273.272461 
   C598.018066,285.333588 598.018066,297.402252 598.018066,311.092041 
   C595.533997,309.583832 593.166260,308.887451 591.989136,307.305267 
   C586.429993,299.833405 581.202026,292.115387 575.840210,284.496277 
   C575.214172,283.606567 574.490479,282.785645 573.074585,281.005890 
   C573.074585,291.146790 573.074585,300.175262 573.074585,309.071899 
   C568.448975,310.280121 568.400146,310.281158 568.319153,306.476685 
   C568.248352,303.148560 568.303894,299.817749 568.303711,295.990417 
z"
      />
      <path
        fill="#18AE61"
        opacity="1.000000"
        stroke="none"
        d="
M187.303696,277.314728 
   C187.733444,275.575012 188.163010,274.294098 188.592575,273.013214 
   C190.157150,273.639008 192.335449,273.811646 193.186508,274.964264 
   C198.620911,282.324341 203.762375,289.900665 209.002502,297.404266 
   C210.022079,298.864227 211.051514,300.317352 212.830826,302.845612 
   C212.830826,296.878387 212.830811,292.190460 212.830811,287.502502 
   C212.830811,282.847046 212.830826,278.191589 212.830826,273.254700 
   C214.343918,273.254700 215.597961,273.254700 217.098358,273.254700 
   C217.098358,285.439270 217.098358,297.514526 217.098358,311.371704 
   C214.367416,309.573730 212.058578,308.770081 210.863754,307.146576 
   C204.756439,298.848145 198.936569,290.338226 191.820145,281.999390 
   C191.820145,284.990692 191.820221,287.982025 191.820145,290.973358 
   C191.819992,296.136322 191.686142,301.304016 191.868271,306.460541 
   C191.982574,309.696594 190.880020,310.781189 187.303497,309.485657 
   C187.303497,299.027405 187.303497,288.400482 187.303696,277.314728 
z"
      />
      <path
        fill="#D7223E"
        opacity="1.000000"
        stroke="none"
        d="
M639.285034,105.124802 
   C639.285095,109.240944 639.285095,112.876617 639.285095,117.249664 
   C630.456970,117.249664 622.031555,117.333679 613.614136,117.113220 
   C612.816101,117.092316 611.475891,115.202194 611.402832,114.111832 
   C611.136108,110.131737 611.273560,106.122543 611.310364,102.124786 
   C611.336487,99.285172 612.700562,97.915886 615.668213,97.969208 
   C621.330383,98.070946 626.995605,97.992523 632.659607,97.997871 
   C639.233398,98.004066 639.233398,98.008263 639.285034,105.124802 
z"
      />
      <path
        fill="#D7223E"
        opacity="1.000000"
        stroke="none"
        d="
M354.594543,97.997482 
   C356.276855,98.373665 358.535675,98.677498 358.567505,99.136032 
   C358.963593,104.837494 359.080292,110.561470 359.078156,116.279579 
   C359.078033,116.612007 357.442566,117.223969 356.563721,117.232193 
   C348.586823,117.306793 340.608948,117.275917 331.828735,117.275917 
   C331.828735,111.465019 331.641418,105.883583 332.022522,100.341240 
   C332.082458,99.469467 334.691772,98.167885 336.166901,98.104614 
   C342.147186,97.848122 348.144836,97.997414 354.594543,97.997482 
z"
      />
      <path
        fill="#12AC59"
        opacity="1.000000"
        stroke="none"
        d="
M475.001587,272.974152 
   C477.782745,273.110657 480.117310,273.075348 482.393768,273.421906 
   C486.304138,274.017181 489.963501,275.222198 490.971832,279.745605 
   C491.990295,284.314484 490.405731,287.792969 486.060577,289.784790 
   C488.193054,291.402557 490.680267,292.484161 491.818939,294.377014 
   C492.985626,296.316376 493.376038,299.102692 493.150513,301.425537 
   C492.566528,307.441345 487.696564,309.104309 482.834778,309.667175 
   C477.937866,310.234131 472.924133,309.791992 467.646545,309.791992 
   C467.646545,297.674042 467.646545,285.627777 467.646545,272.974152 
   C470.003632,272.974152 472.266235,272.974152 475.001587,272.974152 
M472.110901,303.245728 
   C472.748169,304.154175 473.356934,305.816864 474.027588,305.842255 
   C477.147186,305.960175 480.330719,305.899750 483.401764,305.376831 
   C486.677399,304.819092 488.641205,302.713837 488.495239,299.112396 
   C488.347778,295.473145 486.081665,293.636292 482.844177,293.300751 
   C479.454407,292.949463 475.999969,293.222260 472.108734,293.222260 
   C472.108734,296.431122 472.108734,299.370270 472.110901,303.245728 
M475.546570,277.006897 
   C474.400024,277.902405 472.434052,278.666077 472.264587,279.718842 
   C471.804871,282.575317 472.106934,285.554352 472.106934,288.849609 
   C474.973236,288.849609 477.414978,289.040405 479.814819,288.804535 
   C483.344666,288.457642 486.380798,287.164032 486.453522,282.925781 
   C486.528076,278.582428 483.474731,277.471771 479.907684,277.114014 
   C478.762024,276.999146 477.600922,277.038025 475.546570,277.006897 
z"
      />
      <path
        fill="#14AC5B"
        opacity="1.000000"
        stroke="none"
        d="
M633.740845,303.665649 
   C629.225037,308.986603 623.374207,309.876007 617.270325,310.018158 
   C614.140869,310.091034 611.008240,310.030945 607.601074,310.030945 
   C607.601074,297.550812 607.601074,285.484863 607.601074,272.993744 
   C617.313782,273.320923 627.820984,270.686646 634.286499,280.261993 
   C639.203918,287.544464 638.599915,295.723999 633.740845,303.665649 
M631.832031,296.695587 
   C633.974426,281.652008 626.585571,274.428894 612.494995,277.701141 
   C612.494995,287.076904 612.494995,296.430969 612.494995,305.966248 
   C620.401978,305.925812 628.144592,306.552429 631.832031,296.695587 
z"
      />
      <path
        fill="#15AD5C"
        opacity="1.000000"
        stroke="none"
        d="
M303.802368,297.139648 
   C306.498352,301.347961 308.988068,305.256439 311.535126,309.254944 
   C306.875458,310.322174 306.859802,310.332275 304.645844,306.901031 
   C303.112823,304.525177 301.484802,302.194275 300.158936,299.704926 
   C298.079468,295.800751 295.126495,294.094055 290.224365,295.471344 
   C290.224365,300.018555 290.224365,304.764709 290.224365,309.755859 
   C288.463867,309.755859 287.210632,309.755859 285.634094,309.755859 
   C285.634094,297.677338 285.634094,285.617615 285.634094,272.715454 
   C291.014160,273.060730 296.258545,273.194519 301.449554,273.791809 
   C305.959015,274.310638 309.241486,276.693787 309.951263,281.532227 
   C310.677795,286.484833 308.940948,290.347321 304.495972,292.852448 
   C303.638977,293.335449 302.765472,293.789215 301.731232,294.347321 
   C302.429016,295.279968 303.012573,296.059906 303.802368,297.139648 
M292.638458,290.981842 
   C294.958496,290.828522 297.350006,290.978760 299.582306,290.452942 
   C303.217926,289.596558 305.237183,287.245667 305.070251,283.237244 
   C304.913483,279.473755 302.520935,277.912689 299.346771,277.434540 
   C296.459625,276.999725 293.491547,277.102356 290.115051,276.947723 
   C290.115051,279.997040 289.907776,282.483490 290.178894,284.916656 
   C290.406860,286.962555 291.191681,288.946442 292.638458,290.981842 
z"
      />
      <path
        fill="#15AD5C"
        opacity="1.000000"
        stroke="none"
        d="
M348.379364,308.465149 
   C348.335236,296.565582 348.335236,285.117737 348.335236,272.573578 
   C354.577545,273.099182 360.568512,272.969238 366.184021,274.357758 
   C368.791992,275.002625 371.572845,278.238037 372.656891,280.946808 
   C374.102814,284.559723 373.035614,288.557129 369.667389,291.273712 
   C368.262543,292.406738 366.630280,293.257751 364.958313,294.328644 
   C368.149750,299.288116 371.335327,304.238464 374.657379,309.400940 
   C371.166473,310.770599 369.093628,309.951630 367.568146,306.898346 
   C366.311829,304.383850 364.473480,302.165009 363.146515,299.679840 
   C361.064026,295.779663 358.101074,294.097565 353.218933,295.472351 
   C353.218933,300.018158 353.218933,304.758484 353.218933,310.388885 
   C351.169281,309.759735 349.796387,309.338318 348.379364,308.465149 
M367.213959,287.376251 
   C369.563538,278.831818 364.985870,275.553955 353.422607,277.596222 
   C353.422607,281.943634 353.422607,286.303650 353.422607,292.285706 
   C358.350494,290.710297 362.545502,289.369171 367.213959,287.376251 
z"
      />
      <path
        fill="#15AD5C"
        opacity="1.000000"
        stroke="none"
        d="
M523.072876,277.109314 
   C526.978027,285.062378 525.475098,289.087921 516.977783,294.394623 
   C520.188477,299.376801 523.398926,304.360565 526.609375,309.344360 
   C526.235046,309.815125 525.860657,310.285919 525.486267,310.756714 
   C523.581299,309.539856 521.302307,308.653656 519.862549,307.025360 
   C517.906799,304.813477 516.472473,302.100342 515.032043,299.482452 
   C512.893188,295.595245 509.883789,294.083313 505.220642,295.523438 
   C505.220642,300.066803 505.220642,304.682190 505.220642,309.712006 
   C503.533020,309.803467 502.174774,309.877106 500.605103,309.962189 
   C500.605103,297.495483 500.605103,285.431122 500.605103,272.935333 
   C508.376556,273.410004 516.195190,271.322845 523.072876,277.109314 
M520.029846,282.700104 
   C518.101196,277.486511 514.535400,276.193573 505.378815,277.508423 
   C505.378815,281.835510 505.378815,286.196198 505.378815,290.520905 
   C515.444580,291.870514 519.043518,290.109436 520.029846,282.700104 
z"
      />
      <path
        fill="#0EAD60"
        opacity="1.000000"
        stroke="none"
        d="
M555.593750,303.370667 
   C554.363770,301.998322 553.339661,300.274078 552.170593,300.169342 
   C548.204102,299.814087 544.186279,300.045044 540.188171,300.026367 
   C535.668213,300.005280 536.377441,304.153290 535.439148,306.549744 
   C534.192627,309.733398 532.744873,310.865631 529.342773,309.418213 
   C533.796387,298.059204 538.161621,286.728729 542.761780,275.494415 
   C543.227234,274.357788 545.152649,273.099396 546.323059,273.170624 
   C547.525513,273.243835 549.191650,274.647827 549.694519,275.865936 
   C554.259277,286.922089 558.613770,298.065094 563.111389,309.388275 
   C557.123413,311.794647 557.159607,306.826843 555.593750,303.370667 
M551.515991,291.937286 
   C549.859253,287.524963 548.202454,283.112610 546.100098,277.513672 
   C543.514954,284.338898 541.390442,289.947937 539.192627,295.750427 
   C543.999512,295.750427 548.241821,295.750427 552.828186,295.750427 
   C552.414612,294.514893 552.106262,293.593719 551.515991,291.937286 
z"
      />
      <path
        fill="#0EAD60"
        opacity="1.000000"
        stroke="none"
        d="
M12.285099,289.118042 
   C14.144711,284.424225 15.705084,280.015900 17.649549,275.784119 
   C18.201124,274.583710 19.856153,273.199127 21.035133,273.171326 
   C22.228626,273.143158 24.094467,274.427826 24.575068,275.588898 
   C29.213816,286.795593 33.622368,298.097595 38.127205,309.449127 
   C34.607994,310.827362 32.376183,309.999573 31.747618,306.351135 
   C30.801027,300.856781 27.574598,298.969574 22.283178,300.006042 
   C21.480639,300.163239 20.594925,300.161743 19.791672,300.006287 
   C14.477676,298.978088 11.283364,301.012329 10.317130,306.400024 
   C9.716752,309.747742 7.890873,310.900146 4.362339,309.441864 
   C6.963841,302.754486 9.547697,296.112518 12.285099,289.118042 
M22.624777,281.252686 
   C22.227486,280.414368 21.830196,279.576080 20.994976,277.813690 
   C18.530859,284.316040 16.400478,289.937683 14.192308,295.764618 
   C18.966415,295.764618 23.221897,295.764618 27.427876,295.764618 
   C27.506266,295.371277 27.609079,295.183044 27.561077,295.048706 
   C25.997650,290.674500 24.419718,286.305420 22.624777,281.252686 
z"
      />
      <path
        fill="#11AC58"
        opacity="1.000000"
        stroke="none"
        d="
M267.973816,310.023376 
   C263.660278,310.023376 259.838013,310.023376 255.676071,310.023376 
   C255.676071,297.701843 255.676071,285.637390 255.676071,272.987396 
   C262.518982,272.987396 269.290619,272.885162 276.051239,273.111755 
   C276.935486,273.141388 277.763153,274.858978 278.617004,275.794800 
   C278.365204,276.191010 278.113373,276.587250 277.861542,276.983459 
   C272.112579,276.983459 266.363586,276.983459 260.376282,276.983459 
   C260.376282,281.080017 260.376282,284.674866 260.376282,288.976837 
   C264.139801,288.976837 267.915009,288.787659 271.653778,289.074127 
   C273.060425,289.181885 274.382629,290.392517 275.743317,291.100372 
   C274.315704,291.741333 272.921173,292.826019 271.453949,292.935425 
   C267.851776,293.203979 264.216095,293.023224 260.367615,293.023224 
   C260.367615,297.432617 260.367615,301.359497 260.367615,305.977264 
   C265.332977,305.977264 270.260345,305.925232 275.185883,305.996216 
   C278.278961,306.040802 278.277771,306.123657 277.834839,310.023376 
   C274.740601,310.023376 271.602844,310.023376 267.973816,310.023376 
z"
      />
      <path
        fill="#11AC58"
        opacity="1.000000"
        stroke="none"
        d="
M157.334656,282.065063 
   C157.334763,278.935150 157.334763,276.295227 157.334763,272.986084 
   C164.438202,272.986084 171.194595,272.882080 177.940125,273.105927 
   C178.877548,273.136993 179.761353,274.784424 180.669968,275.683594 
   C180.432663,276.093323 180.195374,276.503052 179.958069,276.912781 
   C174.203537,276.912781 168.449005,276.912781 162.417053,276.912781 
   C162.417053,280.995392 162.417053,284.592499 162.417053,288.977875 
   C166.389587,288.977875 170.478638,288.838348 174.546524,289.070190 
   C175.644394,289.132751 176.678101,290.321472 177.741272,290.993286 
   C176.682587,291.669556 175.654922,292.863556 174.559677,292.929108 
   C170.609695,293.165649 166.636963,293.022095 162.405640,293.022095 
   C162.405640,297.353668 162.405640,301.282623 162.405640,305.976990 
   C167.245087,305.976990 172.166199,306.054962 177.083191,305.946594 
   C179.861420,305.885406 181.321747,306.553009 179.960083,309.812134 
   C172.666412,309.812134 165.385330,309.812134 157.334564,309.812134 
   C157.334564,300.721954 157.334564,291.638489 157.334656,282.065063 
z"
      />
      <path
        fill="#11AC58"
        opacity="1.000000"
        stroke="none"
        d="
M434.129517,288.976501 
   C437.272095,288.980469 439.952240,288.833130 442.594910,289.082611 
   C443.365326,289.155396 444.028900,290.360229 444.741455,291.046112 
   C443.998108,291.701843 443.277740,292.891510 442.507599,292.924683 
   C438.222351,293.109222 433.925018,293.013275 429.385071,293.013275 
   C429.385071,297.395447 429.385071,301.323639 429.385071,305.977051 
   C434.293701,305.977051 439.217316,305.927246 444.139282,305.995148 
   C447.238586,306.037933 447.237488,306.117004 446.888458,309.832703 
   C439.620331,309.832703 432.332764,309.832703 424.690369,309.832703 
   C424.690369,297.712036 424.690369,285.650574 424.690369,272.987122 
   C431.507416,272.987122 438.277008,272.884583 445.035583,273.110870 
   C445.927399,273.140717 446.763000,274.848724 447.624603,275.779541 
   C447.374725,276.177887 447.124878,276.576202 446.875000,276.974548 
   C441.124756,276.974548 435.374542,276.974548 429.381104,276.974548 
   C429.381104,281.070282 429.381104,284.665558 429.381104,288.681427 
   C430.790619,288.778778 432.221710,288.877655 434.129517,288.976501 
z"
      />
      <path
        fill="#16AD5C"
        opacity="1.000000"
        stroke="none"
        d="
M318.314636,291.000549 
   C318.314728,284.876038 318.314728,279.251007 318.314728,272.653534 
   C323.938568,273.079071 329.184631,273.132202 334.306915,273.955536 
   C339.995605,274.869904 342.790588,278.473907 342.950317,283.870880 
   C343.111359,289.311371 340.184784,293.711700 334.876556,295.003143 
   C331.242493,295.887268 327.415771,295.979523 323.191040,296.483551 
   C323.191040,300.637817 323.191040,305.061829 323.191040,309.744843 
   C321.487610,309.744843 320.236664,309.744843 318.314514,309.744843 
   C318.314514,303.677185 318.314514,297.588593 318.314636,291.000549 
M337.694672,282.135284 
   C334.947845,277.451752 331.052032,276.209442 323.114685,277.680298 
   C323.114685,279.905273 323.114655,282.206787 323.114655,284.508301 
   C323.114655,286.772980 323.114685,289.037659 323.114685,291.135376 
   C333.159851,293.349274 337.662689,290.660889 337.694672,282.135284 
z"
      />
      <path
        fill="#0FAC5C"
        opacity="1.000000"
        stroke="none"
        d="
M394.219818,281.289734 
   C397.306946,272.329803 406.135620,270.029877 417.285919,276.206055 
   C414.836975,276.987793 413.475494,277.787903 412.118774,277.779907 
   C409.228058,277.762970 406.241943,276.776886 403.487122,277.267426 
   C401.824005,277.563538 399.589355,279.731354 399.322021,281.342041 
   C399.059753,282.922577 400.532776,285.461578 402.005798,286.518097 
   C404.942108,288.624115 408.425629,289.955750 411.636383,291.693604 
   C415.476196,293.771851 417.664642,296.803680 417.082153,301.420532 
   C416.501770,306.020294 413.523010,309.105560 409.450623,309.521759 
   C404.672028,310.010132 399.706909,308.996826 394.873413,308.288635 
   C394.118866,308.178131 393.633759,306.228699 393.024048,305.129608 
   C394.141693,304.955963 395.284393,304.540802 396.372345,304.653595 
   C399.747253,305.003479 403.232666,306.313934 406.418060,305.763489 
   C408.596954,305.386902 411.437958,302.785461 411.937256,300.691010 
   C412.326385,299.058807 409.956177,296.160248 408.106628,294.749481 
   C405.510193,292.769012 402.209869,291.736359 399.302002,290.132599 
   C396.024811,288.325165 393.961792,285.665070 394.219818,281.289734 
z"
      />
      <path
        fill="#02AA54"
        opacity="1.000000"
        stroke="none"
        d="
M228.006989,277.001007 
   C226.386398,276.996063 225.119476,277.270752 224.087906,276.896545 
   C223.281754,276.604218 222.509903,275.585602 222.247192,274.728882 
   C222.137787,274.372131 223.382599,273.098755 224.024048,273.089386 
   C232.014648,272.972412 240.009674,272.928284 247.996552,273.135803 
   C248.819366,273.157166 249.596939,274.919617 251.227402,276.868591 
   C246.137161,276.868591 242.685364,276.868591 238.429657,276.868591 
   C238.429657,282.926697 238.429794,288.661804 238.429611,294.396912 
   C238.429474,298.393097 238.286240,302.395966 238.473541,306.383331 
   C238.624176,309.590179 237.415237,310.653290 233.816620,309.571075 
   C233.816620,299.037231 233.816620,288.315796 233.816620,277.262054 
   C231.670578,277.157440 230.066330,277.079224 228.006989,277.001007 
z"
      />
      <path
        fill="#02AA54"
        opacity="1.000000"
        stroke="none"
        d="
M69.429916,304.903809 
   C69.565971,308.226349 69.699783,311.056366 64.821548,309.560974 
   C64.821548,299.041016 64.821548,288.325043 64.821548,276.993683 
   C61.353962,276.993683 58.411617,277.122894 55.493965,276.911407 
   C54.666634,276.851471 53.911320,275.797852 53.122978,275.199951 
   C53.944099,274.468231 54.751999,273.119080 55.588402,273.101135 
   C63.239822,272.937317 70.898773,272.910767 78.547943,273.120697 
   C79.532051,273.147675 80.472870,274.751709 81.433746,275.625214 
   C81.139137,276.046112 80.844521,276.467041 80.549911,276.887939 
   C77.097809,276.887939 73.645699,276.887939 69.429848,276.887939 
   C69.429848,286.277435 69.429848,295.345367 69.429916,304.903809 
z"
      />
      <path
        fill="#12B370"
        opacity="1.000000"
        stroke="none"
        d="
M380.599121,297.988953 
   C380.599030,289.536346 380.599030,281.581238 380.599030,273.238403 
   C382.293030,273.160858 383.671417,273.097748 385.339691,273.021393 
   C385.339691,285.463989 385.339691,297.523407 385.339691,309.810364 
   C383.811218,309.810364 382.548218,309.810364 380.599182,309.810364 
   C380.599182,306.006927 380.599182,302.246674 380.599121,297.988953 
z"
      />
      <path
        fill="#12B370"
        opacity="1.000000"
        stroke="none"
        d="
M91.611908,296.983887 
   C91.611778,301.445435 91.611778,305.410187 91.611778,309.701965 
   C89.835274,309.701965 88.473129,309.701965 86.851990,309.701965 
   C86.851990,297.649506 86.851990,285.731873 86.851990,273.313293 
   C88.106201,273.191833 89.481995,273.058594 91.612045,272.852325 
   C91.612045,280.989014 91.612045,288.738068 91.611908,296.983887 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M118.447685,178.748215 
   C112.269058,175.762161 109.809433,171.408737 109.872528,164.649963 
   C110.158447,134.026077 110.138557,103.396858 109.883743,72.772423 
   C109.830055,66.320045 112.537674,62.303730 117.892807,59.724899 
   C127.819740,54.944477 137.814560,55.079620 147.604691,60.155994 
   C152.565552,62.728298 155.015930,66.707924 154.983353,72.635498 
   C154.813202,103.594025 154.805420,134.554550 154.987259,165.512939 
   C155.023529,171.688675 152.324402,175.634415 147.189041,178.242432 
   C137.861969,182.979263 128.356308,182.965103 118.447685,178.748215 
z"
      />
      <path
        fill="#02AA52"
        opacity="1.000000"
        stroke="none"
        d="
M249.702637,161.706207 
   C249.702515,154.247925 249.912476,147.246994 249.627960,140.266220 
   C249.443420,135.738449 251.900299,134.149429 255.627228,134.161224 
   C259.456390,134.173309 261.559784,136.025681 261.350006,140.450912 
   C261.011414,147.593719 261.076172,154.770782 261.329468,161.920670 
   C261.474335,166.009216 259.692841,167.885483 255.896454,167.942673 
   C252.141937,167.999237 249.275497,166.765503 249.702637,161.706207 
z"
      />
      <path
        fill="#FDF8F8"
        opacity="1.000000"
        stroke="none"
        d="
M476.202148,143.011566 
   C473.718597,142.884689 470.731384,143.637711 471.246796,140.260818 
   C471.563568,138.185455 473.134705,134.852463 474.489227,134.670959 
   C477.829346,134.223358 481.481476,134.915497 484.752014,135.955200 
   C485.592346,136.222351 485.850861,139.521698 485.624054,141.333893 
   C485.537170,142.028442 483.390198,142.743195 482.103638,142.942139 
   C480.328369,143.216583 478.478973,143.011353 476.202148,143.011566 
z"
      />
      <path
        fill="#F6FBF7"
        opacity="1.000000"
        stroke="none"
        d="
M472.109802,302.777557 
   C472.108734,299.370270 472.108734,296.431122 472.108734,293.222260 
   C475.999969,293.222260 479.454407,292.949463 482.844177,293.300751 
   C486.081665,293.636292 488.347778,295.473145 488.495239,299.112396 
   C488.641205,302.713837 486.677399,304.819092 483.401764,305.376831 
   C480.330719,305.899750 477.147186,305.960175 474.027588,305.842255 
   C473.356934,305.816864 472.748169,304.154175 472.109802,302.777557 
z"
      />
      <path
        fill="#F5FBF6"
        opacity="1.000000"
        stroke="none"
        d="
M475.996735,277.006714 
   C477.600922,277.038025 478.762024,276.999146 479.907684,277.114014 
   C483.474731,277.471771 486.528076,278.582428 486.453522,282.925781 
   C486.380798,287.164032 483.344666,288.457642 479.814819,288.804535 
   C477.414978,289.040405 474.973236,288.849609 472.106934,288.849609 
   C472.106934,285.554352 471.804871,282.575317 472.264587,279.718842 
   C472.434052,278.666077 474.400024,277.902405 475.996735,277.006714 
z"
      />
      <path
        fill="#F8FCF9"
        opacity="1.000000"
        stroke="none"
        d="
M631.727356,297.100647 
   C628.144592,306.552429 620.401978,305.925812 612.494995,305.966248 
   C612.494995,296.430969 612.494995,287.076904 612.494995,277.701141 
   C626.585571,274.428894 633.974426,281.652008 631.727356,297.100647 
z"
      />
      <path
        fill="#F6FBF6"
        opacity="1.000000"
        stroke="none"
        d="
M292.184235,290.969788 
   C291.191681,288.946442 290.406860,286.962555 290.178894,284.916656 
   C289.907776,282.483490 290.115051,279.997040 290.115051,276.947723 
   C293.491547,277.102356 296.459625,276.999725 299.346771,277.434540 
   C302.520935,277.912689 304.913483,279.473755 305.070251,283.237244 
   C305.237183,287.245667 303.217926,289.596558 299.582306,290.452942 
   C297.350006,290.978760 294.958496,290.828522 292.184235,290.969788 
z"
      />
      <path
        fill="#F6FBF6"
        opacity="1.000000"
        stroke="none"
        d="
M366.977234,287.702148 
   C362.545502,289.369171 358.350494,290.710297 353.422607,292.285706 
   C353.422607,286.303650 353.422607,281.943634 353.422607,277.596222 
   C364.985870,275.553955 369.563538,278.831818 366.977234,287.702148 
z"
      />
      <path
        fill="#F6FBF6"
        opacity="1.000000"
        stroke="none"
        d="
M520.040222,283.103027 
   C519.043518,290.109436 515.444580,291.870514 505.378815,290.520905 
   C505.378815,286.196198 505.378815,281.835510 505.378815,277.508423 
   C514.535400,276.193573 518.101196,277.486511 520.040222,283.103027 
z"
      />
      <path
        fill="#F5FAF1"
        opacity="1.000000"
        stroke="none"
        d="
M551.656982,292.304901 
   C552.106262,293.593719 552.414612,294.514893 552.828186,295.750427 
   C548.241821,295.750427 543.999512,295.750427 539.192627,295.750427 
   C541.390442,289.947937 543.514954,284.338898 546.100098,277.513672 
   C548.202454,283.112610 549.859253,287.524963 551.656982,292.304901 
z"
      />
      <path
        fill="#F5FAF1"
        opacity="1.000000"
        stroke="none"
        d="
M22.734360,281.594147 
   C24.419718,286.305420 25.997650,290.674500 27.561077,295.048706 
   C27.609079,295.183044 27.506266,295.371277 27.427876,295.764618 
   C23.221897,295.764618 18.966415,295.764618 14.192308,295.764618 
   C16.400478,289.937683 18.530859,284.316040 20.994976,277.813690 
   C21.830196,279.576080 22.227486,280.414368 22.734360,281.594147 
z"
      />
      <path
        fill="#F5FBF5"
        opacity="1.000000"
        stroke="none"
        d="
M337.769287,282.525757 
   C337.662689,290.660889 333.159851,293.349274 323.114685,291.135376 
   C323.114685,289.037659 323.114655,286.772980 323.114655,284.508301 
   C323.114655,282.206787 323.114685,279.905273 323.114685,277.680298 
   C331.052032,276.209442 334.947845,277.451752 337.769287,282.525757 
z"
      />
    </svg>
  )
}

export default LogoSvg
