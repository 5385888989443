import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { RegistraProdottoGaranzia } from "../../../../Models/RegistraProdottoGaranzia"
import Input from "../../../../Common/Structure/Input/Input"
import BtnCheck from "../../../../Common/Structure/Buttons/BtnCheck"
import usePostHttp from "../../../../Common/Functions/HTTPRequest/Post"
import { GeneralContext } from "../../MasterPage/MasterPage"
import { useNotification } from "../../../../Common/Components/hooks/useNotification"
import { Notification } from "../../../../Common/Components/Notification"
import CardContainer from "../../../../Common/Components/CardContainer"
import TitlePage from "../../../../Common/Structure/Title/TitlePage"
import { ErrorNotification } from "../../../../Models/ErrorNotification"
import { errorNotDefault } from "../../../../Common/defaultValue/ErrorNot"
import { LabelLan } from "../../../../Models/LabelLan"
import keycloak from "../../../../keycloack"
import ErrorComponent from "../../../../Common/Components/ErrorComponent"

type Props = {}

const RegistraGaranziaProdotto = (props: Props) => {
  const [ErrorNotRender, setErrorNotRender] = useState<ErrorNotification>(errorNotDefault)
  const { utente, labels } = useContext(GeneralContext)
  const {
    label: {
      warranty: { register: registerData },
    },
  } = labels as LabelLan

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<RegistraProdottoGaranzia>({
    mode: "onBlur",
    defaultValues: {
      serialNumber: "",
      productId: undefined,
      installationDate: undefined,
      dealerId: 0,
      familyCode: "",
      productCode: undefined,
      regInsDate: new Date(),
      warrantyId: 0,
    },
  })

  const { notification, showNotification } = useNotification()

  const saveGaranzia = usePostHttp("warranty/save")

  useEffect(() => {
    if (ErrorNotRender.type !== "") {
      showNotification(saveGaranzia.errorMessage, "error")
      setErrorNotRender(errorNotDefault)
    }
  }, [ErrorNotRender])

  if (!utente?.permissions?.includes("menu:warranties-management")) {
    return <ErrorComponent auth />
  }

  return (
    <div className="col-12 col-md-6 col-xl-5 my-4">
      <CardContainer>
        <TitlePage Title={registerData.title} />
        <form
          onSubmit={handleSubmit(async (e) => {
            const result = await saveGaranzia.fetch(e)
            if (result) {
              showNotification(
                labels?.label.notification.message.saveWarrantlySuccess ?? "",
                "success"
              )
            } else {
              setErrorNotRender({ type: "G", count: 1 })
            }
          })}
        >
          <Input
            label={registerData.productCode}
            type="text"
            placeholder="B00000"
            {...register("productCode", { required: true, minLength: 1 })}
            className={errors.productCode ? "invalid " : ""}
            spacing={true}
          />
          <Input
            label={registerData.serialNumber}
            type="text"
            placeholder="123456789"
            {...register("serialNumber", { required: true, minLength: 1 })}
            className={errors.serialNumber ? "invalid " : ""}
            spacing={true}
          />
          <Input
            label={registerData.installationDate}
            type="date"
            {...register("installationDate", { required: true })}
            className={errors.installationDate ? "invalid " : ""}
            spacing={true}
          />
          <BtnCheck
            Title={registerData.save}
            type="submit"
            disabled={!isValid}
            isLoading={saveGaranzia.isLoading}
            style={{
              minWidth: 100,
            }}
          />
        </form>
      </CardContainer>
      {notification && <Notification message={notification.message} type={notification.type} />}
    </div>
  )
}

export default RegistraGaranziaProdotto
