import { useEffect, useState, createContext, useRef } from "react"
import UserBar from "./UserBar/UserBar"
import { GeneralContextModel } from "../../../Models/Context/GeneralContextModel"
import * as labelJson from "../../../translate.json"
import labelErrorJson from "../../../translateError.json"
import { ModalProps } from "../../../Models/Generic"
import Modal from "../../../Common/Components/Modal"
import useTrolley from "../../../Common/Components/hooks/useTrolley"
import { defaulModalState } from "../OrdineHW/NuovoOrdineRef/DefaultValue"
import { Notifica } from "../../../Models/Notifica"
import useGetHttp from "../../../Common/Functions/HTTPRequest/Get"
import usePostHttp from "../../../Common/Functions/HTTPRequest/Post"
import keycloak from "../../../keycloack"
import Loading from "../../../Common/Components/Loader"
import ErrorComponent from "../../../Common/Components/ErrorComponent"
import { LangArray, LabelLan } from "../../../Models/LabelLan"
import jwtDecode from "jwt-decode"
import { UserInfo } from "../../../Models/userInfo"
import { redirect, useNavigate } from "react-router-dom"
import { LangErrors } from "../../../Models/LangError"

const defaultContext: GeneralContextModel = {
  labels: {} as LabelLan,
  utente: {
    idSaleDiv: 0,
    idUser: 0,
    role: "A",
  },
}

export const GeneralContext = createContext<GeneralContextModel>(defaultContext)

const MasterPage = () => {
  const [lingua, setLingua] = useState<string>("")
  const [labels, setLabels] = useState<LabelLan | undefined>(undefined)
  const [labelErrors, setLabelErrors] = useState<LangErrors | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState({ error: false, textError: "" })
  const [modalState, setModalState] = useState<ModalProps>(defaulModalState)
  //const [notifiche, setNotifiche] = useState<Notifica[]>([])
  const [userIDs, setUserIDs] = useState<UserInfo | undefined>(undefined)
  const [permissions, setPermissions] = useState<string[]>([])
  const userTokenInfo = keycloak.tokenParsed

  const userInfo: any = { ...userTokenInfo }

  const [userRole, setUserRole] = useState(
    userInfo.realm_access.roles.includes("agent") ? "A" : "D"
  )
  const nav = useNavigate()

  const trolley = useTrolley()
  const notificheClient = useGetHttp("notification/getall")
  const leggiNotificaClient = usePostHttp("notification/set-as-read")

  const dealerClient = useGetHttp("dealer/get-dealer-id")
  const agentClient = useGetHttp("dealer/get-agent-id")

  const leggiNotifica = async (id: string | number) => {
    const result = await leggiNotificaClient.fetch(id, undefined, false)
    return result
  }

  useEffect(() => {
    //lingua
    try {
      if (!localStorage.getItem("CAT")) {
        nav("/")
        localStorage.setItem("CAT", "1")
      }
      const lang = userInfo["language"]
        ? (userInfo["language"] as string).toLocaleUpperCase()
        : undefined
      if (!lang) throw new Error("langError")

      const labelFull: LangArray = labelJson
      let labelSelected = labelFull.lang.find((x) => x.languageCode === "EN")
      let labelErrorsSelected = labelErrorJson.find((x) => x.lang === "IT")

      if (labelSelected && labelErrorsSelected) {
        const labelErrors = labelErrorJson.find((x) => x.lang === lang)
        const labelUser = labelFull.lang.find((x) => x.languageCode === lang)

        if (labelUser) labelSelected = labelUser
        if (labelErrors) labelErrorsSelected = labelErrors

        setLabels(labelSelected)
        setLabelErrors(labelErrorsSelected)
        setLingua(labelSelected.languageCode)

        const takeUserInfo = async () => {
          let role = userInfo.realm_access.roles.includes("agent") ? true : false
          let result = false

          if (userInfo.sapCode) {
            if (role) {
              result = await agentClient.fetch({ agentCode: userInfo.sapCode })
            } else {
              result = await dealerClient.fetch({
                dealerCode: userInfo.sapCode,
                language: (userInfo.language as string).toUpperCase(),
                divisionId: userInfo.divisionId,
                distributionChannel: userInfo.distributionChannel,
                salesOrganization: userInfo.salesOrganization,
              })
            }
            if (!result) {
              setIsError({
                error: true,
                textError: labelSelected?.label.common.errorUser ?? "",
              })
            }
          } else {
            setUserRole("E")
          }

          setLoading(false)
        }
        takeUserInfo()

        //permessi
        const tokenAcessToParse = localStorage.getItem("ATK")
        if (tokenAcessToParse) {
          const data = jwtDecode(tokenAcessToParse)
          const permis = (data as any).authorization.permissions.map((x: any) => x.rsname)
          if (permis) {
            setPermissions(permis)
          } else {
            setIsError({
              error: true,
              textError: labelSelected.label.common.errorAuth,
            })
          }
        }
      } else {
        setLabels(undefined)
        setLabelErrors(undefined)
        setLoading(false)
      }
    } catch (e: any) {
      if (e.message === "langError") {
        setLabelErrors(undefined)
        setLabels(undefined)
        setLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    if (userInfo.realm_access.roles.includes("agent")) {
      if (agentClient.dataF) {
        setUserIDs({ id: agentClient.dataF, idSalDivOrgSls: "" })
        setLoading(false)
      }
    } else {
      if (dealerClient.dataF) {
        setUserIDs({
          id: dealerClient.dataF.dealerId,
          idSalDivOrgSls: dealerClient.dataF.salOrDisChDivId,
        })
        setLoading(false)
      }
    }
  }, [agentClient.dataF, dealerClient.dataF])

  // useEffect(() => {
  //   if (notificheClient.dataF) {
  //     let filteredNotification: Notifica[]
  //     if (userInfo.realm_access.roles.includes("agent")) {
  //       filteredNotification = notificheClient.dataF
  //     } else {
  //       filteredNotification = notificheClient.dataF.filter((n: Notifica) =>
  //         permissions.includes(n.role)
  //       )
  //     }
  //     setNotifiche(filteredNotification)
  //   } else {
  //     setNotifiche([])
  //   }
  // }, [notificheClient.dataF])

  // useEffect(() => {
  //   //Notifiche
  //   const fetchNotifications = async () => {
  //     await notificheClient.fetch()
  //   }

  //   const not = setInterval(async () => {
  //     if (userIDs) await fetchNotifications()
  //   }, 8 * 1000)

  //   return () => {
  //     clearInterval(not)
  //   }
  // }, [userIDs])

  const valueForGeneralContext: GeneralContextModel = {
    labels,
    labelsErros: labelErrors,
    utente: {
      idSaleDiv: userIDs?.idSalDivOrgSls ? +userIDs.idSalDivOrgSls : 0,
      idUser: userIDs?.id ? +userIDs.id : 0,
      role: userRole,
      permissions,
    },
    features: {
      setModal: setModalState,
      trolley: trolley,
      notifiche: {
        notifiche: [],
        leggiNotifica: leggiNotifica,
      },
    },
  }

  if (!localStorage.getItem("ATK")) {
    return (
      <ErrorComponent
        lang={lingua}
        action={false}
        logo
        logout
        errorMessage={labels?.label.common.errorAuth}
      />
    )
  }

  return (
    <GeneralContext.Provider value={valueForGeneralContext}>
      {loading ? (
        <Loading />
      ) : labels && labelErrors ? (
        !isError.error ? (
          <UserBar
            //notifiche={notifiche}
            username={userInfo!["name"]}
            setUserInfo={setUserIDs}
          />
        ) : (
          <ErrorComponent
            logo
            action={false}
            lang={labels.languageCode === "EN" ? "EN" : ""}
            errorMessage={isError.textError}
            logout
          />
        )
      ) : (
        <ErrorComponent
          lang="EN"
          action={false}
          logo
          logout
          errorMessage="There is a problem with the language's configuration, please contact the Administrator."
        />
      )}

      {modalState.isVisible && (
        <Modal
          modalState={modalState}
          onClose={() => {
            setModalState(defaulModalState)
            if (modalState.reload) {
              window.location.reload()
            }
          }}
        />
      )}
    </GeneralContext.Provider>
  )
}

export default MasterPage
