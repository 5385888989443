import React from "react";
type TitlePageProps = {
  Title: string;
  TitleStyle?: string;
  spacing?: boolean
};

const TitlePage = ({ Title, TitleStyle, spacing = true }: TitlePageProps) => {
  return (
    <div>
      <h3 className={`${TitleStyle} ${spacing && "mb-5"} text-center text-md-start`}>
        {Title}
      </h3>
    </div>
  );
};

export default TitlePage;
