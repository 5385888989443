import React, { ReactNode, useContext, useEffect, useState } from "react"
import BtnClose from "../../Icons/BtnClose"
import AmministrazioneIcon from "../../Icons/Sidebar/AmministrazioneIcon"
import RegProdGaranziaIcon from "../../Icons/Sidebar/RegProdGaranziaIcon"
import OrdiniHWIcon from "../../Icons/Sidebar/OrdiniHWIcon"
import ProfiloAziendaIcon from "../../Icons/Sidebar/ProfiloAziendaIcon"
import RicambiIcon from "../../Icons/Sidebar/RicambiIcon"
import WebReportingIcon from "../../Icons/Sidebar/WebReportingIcon"
import { Link, useNavigate } from "react-router-dom"
import { GeneralContext } from "../../../Components/UI/MasterPage/MasterPage"
import LogoSvg from "../../Icons/Logo"
import { LabelLan } from "../../../Models/LabelLan"

type SidebarProps = {
  closeSidebar?: () => void
}

export type MenuItem = {
  name: string
  route: string
  icon: ReactNode
  submenu: SubMenuItem[]
}

export type SubMenuItem = {
  name: string
  route: string
  role: string
}

const Sidebar = ({ closeSidebar }: SidebarProps) => {
  const [activeMenu, setActiveMenu] = useState<number | null>(null)
  const [activeVoice, setActiveVoice] = useState<number | null>(null)
  const [submenu, setSubmenu] = React.useState<{ name: string; route: string; role: string }[]>([])

  
  const { utente, labels } = useContext(GeneralContext)
  const {
    label: { menu },
  } = labels as LabelLan
  
  const menuVoice: MenuItem[] = [
    {
      name: menu.administration.value,
      route: "/administrator",
      icon: <AmministrazioneIcon />,
      submenu: [
        {
          name: menu.administration.subMenu.link,
          route: "/administrator/link",
          role: "menu:admin-homepage-links"
        },
        {
          name: menu.administration.subMenu.overcharge,
          route: "/administrator/overcharge",
          role: "menu:admin-overcharge",
        }
      ],
    },
    {
      name: menu.profileAgency.value,
      route: "/profilo-azienda",
      icon: <ProfiloAziendaIcon />,
      submenu: [
        {
          name: menu.profileAgency.subMenu.anagraph,
          route: "/profilo-azienda/anagrafica",
          role: "menu:agencyprofile",
        },
        {
          name: menu.profileAgency.subMenu.organizationChart,
          route: "/profilo-azienda/organigramma",
          role: "menu:destinations",
        },
        {
          name: menu.profileAgency.subMenu.deliveryPoint,
          route: "/profilo-azienda/punto-di-consegna",
          role: "menu:organigram",
        },
      ],
    },
    {
      name: menu.orderHW.value,
      route: "/ordini-hw-e-accessori",
      icon: <OrdiniHWIcon />,
      submenu: [
        {
          name: menu.orderHW.subMenu.catalogue,
          route: "/ordini-hw-e-accessori/catalogohw",
          role: "menu:catalogue",
        },
        {
          name: menu.orderHW.subMenu.listOrder,
          route: "/ordini-hw-e-accessori/lista-ordini",
          role: "menu:list-hw-order",
        },
        {
          name: menu.orderHW.subMenu.newOrder,
          route: "/ordini-hw-e-accessori/nuovo-ordine",
          role: "menu:create-hw-order",
        },
      ],
    },
    {
      name: menu.orderSpare.value,
      route: "/ricambi",
      icon: <RicambiIcon />,
      submenu: [
        {
          name: menu.orderSpare.subMenu.catalogue,
          route: "/ricambi/catalogo-ricambi",
          role: "menu:catalogue",
        },
        {
          name: menu.orderSpare.subMenu.listOrder,
          route: "/ricambi/lista-ordini",
          role: "menu:list-spare-order",
        },
        {
          name: menu.orderSpare.subMenu.newOrder,
          route: "/ricambi/nuovo-ordine",
          role: "menu:create-spare-order",
        },
      ],
    },
    {
      name: menu.listOrder.value,
      route: "/web-reporting",
      icon: <WebReportingIcon />,
      submenu: [
        {
          name: menu.listOrder.subMenu.financialStatement,
          route: "/web-reporting/situazione-contabile",
          role: "menu:reporting",
        },
        {
          name: menu.listOrder.subMenu.openItems,
          route: "/web-reporting/partite-aperte",
          role: "menu:reporting",
        },
        {
          name: menu.listOrder.subMenu.sentOrdersList,
          route: "/web-reporting/lista-ordini",
          role: "menu:reporting",
        },
        {
          name: menu.listOrder.subMenu.ordersStatus,
          route: "/web-reporting/stato-ordini",
          role: "menu:reporting",
        },
        {
          name: menu.listOrder.subMenu.deliveries,
          route: "/web-reporting/consegne",
          role: "menu:reporting",
        },
        {
          name: menu.listOrder.subMenu.invoices,
          route: "/web-reporting/fatture",
          role: "menu:reporting",
        },
        {
          name: menu.listOrder.subMenu.accountStatements,
          route: "/web-reporting/estratti-conto",
          role: "menu:reporting",
        },
        {
          name: menu.listOrder.subMenu.salesInvoicedShipped,
          route: "/web-reporting/venduto-fatturato-spedito",
          role: "menu:reporting",
        },
      ],
    },
    {
      name: menu.warranty.value,
      route: "/garanzia",
      icon: <RegProdGaranziaIcon />,
      submenu: [
        {
          name: menu.warranty.subMenu.registration,
          route: "/garanzia/registrazione-prodotto-per-garanzia",
          role: "menu:warranties-management",
        },
        {
          name: menu.warranty.subMenu.request,
          route: "/garanzia/richiesta-parti-garanzia",
          role: "menu:warranties-management",
        },
      ],
    },
  ]

  const nav = useNavigate()

  useEffect(() => {
    if (activeVoice) {
      showSubmenu(activeVoice)
    }
  }, [])

  const showSubmenu = (index: number) => {
    setActiveVoice(null)
    if (menuVoice[index].submenu.length > 0) {
      setActiveMenu(activeMenu === index ? null : index)
      setSubmenu(menuVoice[index].submenu)
    }
  }

  return (
    <div className="fixed-sidebar d-flex flex-column justify-content-start align-items-center bg-light custom-scrollbar">
      {closeSidebar && (
        <div className="container-fluid">
          <div className="row">
            <div className="col d-flex justify-content-end mt-4 mx-5">
              <BtnClose onClick={closeSidebar} />
            </div>
          </div>
        </div>
      )}
      <div className="my-1">
        <LogoSvg onClick={() => nav("/")} className="p-5" key={"logoSVG"} />
      </div>
      <div className="w-100 my-3 px-3">
        {menuVoice.map((voice, index) => {
          if (voice.submenu.some((y) => utente?.permissions?.includes(y.role ?? ""))) {
            return (
              <div key={index}>
                <div
                  className={`${activeMenu === index ? "selected-menu-item" : ""} menu-item  py-1`}
                  style={{
                    fontWeight: activeMenu === index ? "bold" : "normal",
                  }}
                  onClick={() => {
                    showSubmenu(index)
                  }}
                >
                  <div className="d-flex align-items-center text-decoration-none text-dark my-3">
                    <span className="mx-3">{voice.icon}</span>
                    {voice.name}
                  </div>
                </div>
                <div
                  className={`submenu-container ${
                    activeMenu === index ? "submenu-open" : "submenu-closed"
                  }`}
                >
                  {submenu.map((subVoice, subIndex) => {
                    if (utente?.permissions?.includes(subVoice.role)) {
                      return (
                        <div
                          key={subIndex.toString() + index.toString()}
                          className="submenu-item py-2"
                        >
                          <Link
                            to={subVoice.route}
                            className={`${
                              activeVoice == subIndex ? "selected-menu-item" : ""
                            } d-block pl-5 text-decoration-none text-dark`}
                            onClick={
                              closeSidebar
                                ? () => {
                                    closeSidebar()
                                    setActiveVoice(subIndex)
                                  }
                                : () => {
                                    setActiveVoice(subIndex)
                                  }
                            }
                          >
                            {subVoice.name}
                          </Link>
                        </div>
                      )
                    } else return null
                  })}
                </div>
              </div>
            )
          } else {
            return null
          }
        })}
      </div>
    </div>
  )
}

export default Sidebar
