export const fileToArrayBuffer = async (
  files: FileList | null,
  type?: string[]
): Promise<ArrayBuffer | string> => {
  try {
    if (files) {
      if (files[0] instanceof File) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsArrayBuffer(files[0])
          reader.onload = (e) => {
            if (e.target?.result instanceof ArrayBuffer) {
              resolve(e.target?.result)
            } else {
              reject(new Error("Il file non è stato letto correttamente."))
            }
          }
          reader.onerror = () => {
            reject(new Error("C'è stato un problema nel caricamento del file."))
          }
        })
      } else {
        throw new Error("Il file non corrisponde al formato richiesto.")
      }
    } else {
      throw new Error("Non ci sono file validi selezionati.")
    }
  } catch (e: any) {
    return `${e.message}`
  }
}
