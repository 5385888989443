import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: `${process.env.REACT_APP_BASE_KC_URL}`,
  realm: 'oliord',
  clientId: 'oliord-frontend'
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;

