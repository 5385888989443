import React, { ButtonHTMLAttributes, ReactNode } from "react"
import { Spinner } from "react-bootstrap"
import Loading from "../../Components/Loader"

type BtnCheckProps = {
  Title: string
  btnStyle?: string
  disabled?: boolean
  bgColor?: string
  icon?: ReactNode
  isLoading?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

const BtnCheck = ({
  Title,
  btnStyle,
  disabled = false,
  bgColor = "btn-success",
  icon,
  isLoading,
  ...rest
}: BtnCheckProps) => {
  return (
    <button
      className={`btn ${
        !disabled ? bgColor : "btn-secondary"
      } text-white text-uppercase ${btnStyle} rounded-pill`}
      disabled={disabled}
      style={rest.style}
      type="button"
      {...rest}
    >
      {!isLoading ? (
        <div
          className={`d-flex gap-3 align-items-center ${
            icon ? "justify-content-between" : "justify-content-center"
          }`}
        >
          {icon && icon}
          {Title}
        </div>
      ) : (
        <Loading easyIcon={true} />
      )}
    </button>
  )
}

export default BtnCheck
