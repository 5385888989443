import React, { InputHTMLAttributes, Ref, forwardRef } from "react"

type InputProps = {
  key?: any
  inputDivStyle?: string
  label?: string
  justify?: string
  spacing?: boolean
}

const Input = forwardRef(
  (props: InputProps & InputHTMLAttributes<HTMLInputElement>, ref: Ref<HTMLInputElement>) => {
    const {
      key,
      label,
      inputDivStyle,
      children,
      justify = "justify-content-between",
      spacing = true,
      ...rest
    } = props

    return (
      <div
        key={key}
        className={` d-flex ${spacing ? "my-2" : ""} ${inputDivStyle} ${justify}`}
        style={{ padding: spacing ? "10px 0" : "0" }}
      >
        {label && label}
        <input ref={ref} {...rest}></input>
        {children}
      </div>
    )
  }
)

export default Input
