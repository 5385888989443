import React, { useContext, useEffect, useState } from "react"
import { LinkMan } from "../../../../Models/Links"
import Input from "../../../../Common/Structure/Input/Input"
import { LabelLan } from "../../../../Models/LabelLan"
import { GeneralContext } from "../../MasterPage/MasterPage"
import BtnVisual from "../../../../Common/Structure/Buttons/BtnVisual"
import SpuntaIcon from "../../../../Common/Icons/Spunta"
import Cestino from "../../../../Common/Icons/Cestino"
import BtnClose from "../../../../Common/Icons/BtnClose"
import usePostHttp from "../../../../Common/Functions/HTTPRequest/Post"
import SimpleSelect from "../../../../Common/Structure/Select/SimpleSelect"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import { reloadTimer } from "../../../../Common/Functions/Util/reloadTime"
import { error } from "console"

type Props = {
  link?: LinkMan
  close: () => void
  showNotification: (message: string, type: "success" | "error") => void
}

const defaultLink: LinkMan = {
  dateEnd: undefined,
  dateStart: undefined,
  id: 0,
  link: undefined,
  message: undefined,
  target: "_self",
}

const InsertNewLink = ({ link, close, showNotification }: Props) => {
  const [newLink, setNewLink] = useState<LinkMan>(link ?? defaultLink)
  const [salOrgs, setSalOrgs] = useState<string[]>([])

  const { labels } = useContext(GeneralContext)
  const {
    label: {
      linkPage: { list },
    },
  } = labels as LabelLan

  const saveLink = usePostHttp("homepagelink/add-or-update-homepagelink")
  const salOrgsClient = useGetHttp("product/getall-salesorganizations")

  useEffect(() => {
    const getSalOrgs = async () => await salOrgsClient.fetch()
    getSalOrgs()
  }, [])

  useEffect(() => {
    if (salOrgsClient.dataF) {
      setSalOrgs(salOrgsClient.dataF)
      setNewLink((prev) => {
        return { ...prev, affiliateId: salOrgsClient.dataF[0] }
      })
    } else {
      setSalOrgs([])
    }
  }, [salOrgsClient.dataF])

  useEffect(() => {
    if (saveLink.isError && saveLink.errorMessage) {
      showNotification(saveLink.errorMessage, "error")
    }
  }, [saveLink.errorMessage])


  return (
    <>
      <div className="mb-3">
        <Input
          label={list.url}
          defaultValue={newLink?.link ?? "https://"}
          onChange={({ currentTarget: { value } }) =>
            setNewLink((prev) => {
              return { ...prev, link: value }
            })
          }
        />
        <Input
          label={list.text}
          defaultValue={newLink.message ?? ""}
          onChange={({ currentTarget: { value } }) =>
            setNewLink((prev) => {
              return { ...prev, message: value }
            })
          }
        />
        <SimpleSelect
          label={list.salOrg}
          children={
            salOrgs.length > 0 &&
            salOrgs.map((s, i) => (
              <option value={s} key={i}>
                {s}
              </option>
            ))
          }
          defaultValue={newLink.affiliateId ?? ""}
          onChange={({ currentTarget: { value } }) =>
            setNewLink((prev) => {
              return { ...prev, affiliateId: value }
            })
          }
        />
        <Input
          label={list.dataStart}
          type="date"
          value={newLink.dateStart?.split("T")[0] ?? ""}
          onChange={({ currentTarget: { value } }) =>
            setNewLink((prev) => {
              return { ...prev, dateStart: value }
            })
          }
        />
        <Input
          label={list.dataEnd}
          type="date"
          value={newLink.dateEnd?.split("T")[0] ?? ""}
          onChange={({ currentTarget: { value } }) => {
            setNewLink((prev) => {
              return { ...prev, dateEnd: value }
            })
          }}
        />
        <Input
          label={list.target}
          type="checkbox"
          defaultChecked={newLink.target === "_blank" ? true : false}
          onChange={({ currentTarget: { checked } }) =>
            setNewLink((prev) => {
              return { ...prev, target: checked ? "_blank" : "_self" }
            })
          }
        />
      </div>
      <div className="d-flex gap-3">
        <BtnVisual
          Title={labels?.label.HWOrder.newOrder.body.save ?? "Save"}
          color="#459317"
          onClick={async () => {
            if (newLink.dateStart && newLink.dateEnd && newLink.link && newLink.message) {
              const res = await saveLink.fetch(newLink, undefined, false)
              if (res) {
                showNotification(
                  labels?.label.notification.message.saveGeneralSuccess ?? "",
                  "success"
                )
                reloadTimer(1.5)
              }
            } else {
              showNotification(labels?.label.notification.message.saveGeneralError ?? "", "error")
            }
          }}
          icon={<SpuntaIcon width={"1.5em"} height={"1.5em"} color="#459317" />}
        />
        <BtnVisual
          Title={labels?.label.HWOrder.newOrder.body.close ?? "Close"}
          color="#cd1231"
          onClick={close}
          icon={<BtnClose color="#cd1231" width={"1.3em"} height={"1.3em"} />}
        />
      </div>
    </>
  )
}

export default InsertNewLink
