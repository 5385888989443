import { useContext, useEffect, useState } from "react"
import { ChiaveValore, FetchDataState } from "../../../Models/Generic"
import { GeneralContext } from "../../../Components/UI/MasterPage/MasterPage"
import { LangErrors } from "../../../Models/LangError"

const useDeleteHttp = (url: string) => {
  const [responseData, setResponseData] = useState<any>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isTouched, setIsTouched] = useState(false)

  const { labelsErros } = useContext(GeneralContext)

  const fetch = async (data: any, p?: string, b?: boolean): Promise<boolean> => {
    return new Promise(async (resolve) => {
      setIsLoading(true)
      const response = await deleteHTTP(url, data, b)

      try {
        if (response) {
          setIsTouched(true)
          if (response.success) {
            setResponseData(response.data)
            setIsLoading(false)
            resolve(true)
          } else {
            if (response.data) {
              const { controller } = labelsErros as LangErrors
              const [codeController, codeApi, codeError] = response.data.Code.split(".")
              const controllerSel = controller.find((x) => x.codice === codeController)
              const apiSel = controllerSel?.api.find((x) => x.codice === codeApi)
              let errorSel = apiSel?.errori[codeError]
              if (errorSel.includes("{0}")) {
                if (response.data.Message.includes(":")) {
                  errorSel = errorSel.replace("{0}", response.data.Message.split(":")[1])
                } else {
                  errorSel = errorSel.replace("{0}", "N")
                }
              }
              if (errorSel) {
                throw new Error(errorSel)
              } else {
                throw new Error(
                  controller
                    .find((x) => x.codice === "100")!
                    .api.find((x) => x.codice === "0")!.errori["1"]
                )
              }
            }
          }
        } else {
          throw new Error("La richiesta è fallita per motivi sconosciuti")
        }
      } catch (error: any) {
        setIsLoading(false)
        setIsError(true)
        setErrorMessage(error.message)
        resolve(false)
      }
    })
  }

  const reset = () => {
    setIsTouched(false)
    setIsError(false)
    setResponseData(undefined)
    setErrorMessage("")
  }

  const state: FetchDataState = {
    dataF: responseData,
    isLoading,
    isError,
    errorMessage,
    fetch,
    isTouched,
    reset,
  }

  return state
}

export const deleteHTTP = async (url: string, data: Object, b: boolean = true) => {
  let response: Response = {} as Response

  const parameters: ChiaveValore[] = Object.entries(data ?? {}).map((x) => ({
    chiave: x[0],
    valore: x[1],
  }))

  if (b) {
    if (localStorage.getItem("IMP_USER")) {
      const imp = JSON.parse(localStorage.getItem("IMP_USER") ?? "")
      parameters.push({ chiave: "dealerId", valore: imp.id })
    }
  }

  const baseUrl = process.env.REACT_APP_BASE_API_URL
  const queryString = parameters.length
    ? `?${parameters
        .map((p) => (p.valore !== undefined ? `${p.chiave}=${p.valore}` : null))
        .filter((query) => query !== null)
        .join("&")}`
    : ""
  const fullUrl = `${baseUrl}${url}${queryString}`

  const authToken = localStorage.getItem("ATK")

  try {
    response = await fetch(fullUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken ?? "",
      },
      body: JSON.stringify(data),
    })

    const responseData = await response.json()

    const value = {
      data: responseData,
      statusCode: response.status,
      success: response.ok,
      error: false,
      errorMessage: "",
    }

    return value
  } catch (error) {
    const value = {
      data: undefined,
      statusCode: response.status,
      success: response.ok,
      error: true,
      errorMessage: response.statusText,
    }

    return value
  }
}

export default useDeleteHttp
