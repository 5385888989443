import { useContext, useEffect, useRef, useState } from "react"
import { useFetcher, useNavigate, useParams } from "react-router-dom"
import { GeneralContext } from "../../MasterPage/MasterPage"
import { ReqOrderHeaderDto } from "../../../../Models/Req/ReqOrderHeader"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import { ReqProdotto } from "../../../../Models/Product"
import { getButtonStatusText } from "../../../../Common/Functions/Util/getStatusText"
import TitlePage from "../../../../Common/Structure/Title/TitlePage"
import SelezionaDettaglio from "../NuovoOrdineRef/Components/SelezionaDettaglio"
import Title from "./Components/Title"
import Loading from "../../../../Common/Components/Loader"
import TestataOrdineHW from "./Components/Testata"
import ErrorComponent from "../../../../Common/Components/ErrorComponent"
import usePostHttp from "../../../../Common/Functions/HTTPRequest/Post"
import BtnCheck from "../../../../Common/Structure/Buttons/BtnCheck"
import useDeleteHttp from "../../../../Common/Functions/HTTPRequest/Delete"
import { useNotification } from "../../../../Common/Components/hooks/useNotification"
import { Notification } from "../../../../Common/Components/Notification"
import CampagneList from "../../../ListaModali/CampagnaProducts"
import CardContainer from "../../../../Common/Components/CardContainer"
import { reloadTimer } from "../../../../Common/Functions/Util/reloadTime"
import ArrowDown from "../../../../Common/Icons/ArrowDown"
import { ErrorNotification } from "../../../../Models/ErrorNotification"
import { errorNotDefault } from "../../../../Common/defaultValue/ErrorNot"
import { LabelLan } from "../../../../Models/LabelLan"

const Ordine2 = () => {
  const { labels, features, utente } = useContext(GeneralContext)
  const {
    label: {
      HWOrder: { newOrder },
      common,
      notification: { message },
    },
  } = labels as LabelLan
  const [ordine, setOrdine] = useState<ReqOrderHeaderDto>({
    dateCreation: new Date().toISOString(),
    dealerOrderCode: "",
    dealerOrderDate: "",
    destinationId: "0",
    flagDelivery: true,
    blockBill: false,
    blockDelivery: false,
    notes: "",
    orderId: 0,
    statusId: 0,
    termPaymentId: "0",
    webCode: "",
    guidOrder: "",
    orderDetails: [],
    orderType: "WWW",
  })

  console.log(ordine)

  const [showTestata, setShowTestata] = useState(true)
  const [ErrorNotRender, setErrorNotRender] = useState<ErrorNotification>(errorNotDefault)
  const param = useParams()
  const navigate = useNavigate()
  const codice = param.codice

  const codiceRef = useRef<string>()
  const { notification, showNotification } = useNotification()

  const orderSaveClient = usePostHttp("order/save")
  const orderDelClient = useDeleteHttp("order/delete")

  const loadOrdine = useGetHttp("order/get")
  const addProduct = usePostHttp("order/detail/saveplus")
  const delProduct = useDeleteHttp("order/detail/delete")
  const indirizziClient = useGetHttp("order/destination/getall")
  const terminiPagClient = useGetHttp("order/term-payments/getall")

  useEffect(() => {
    indirizziClient.fetch()
    terminiPagClient.fetch()
  }, [])

  useEffect(() => {
    if (codice) {
      codiceRef.current = codice
      loadOrdine.fetch({ orderId: codice })
    } else {
      if (codiceRef.current) {
        reloadTimer(0)
        codiceRef.current = undefined
      }
    }
  }, [codice])

  useEffect(() => {
    if (loadOrdine.dataF) {
      setOrdine({ ...loadOrdine.dataF, orderId: codice ? +codice : 0 ?? 0 })
    }
  }, [loadOrdine.dataF])

  const addProducts = async (Products: ReqProdotto[], reload: boolean) => {
    if (Products.length > 0) {
      const result = await addProduct.fetch({
        productList: Products.map((x) => {
          return {
            orderId: +ordine.orderId,
            productId: x.productId ?? null,
            productCode: x.productCode ?? "",
            familyCode: "",
            quantita: x.productQty ?? 1,
            campaignId: x.campaignId,
          }
        }),
      })
      if (!result) {
        setErrorNotRender({ type: "A", count: ErrorNotRender.count + 1 })
      } else {
        showNotification(message.addProductSuccess, "success")
      }
    }
  }

  useEffect(() => {
    if (addProduct.dataF) {
      if (addProduct.dataF.length > 0) {
        showNotification(message.addProductErrorCompany, "error")
        features?.setModal({
          isError: false,
          isLoading: false,
          isVisible: true,
          reload: true,
          title: newOrder.modalCampaign.title,
          children: <CampagneList codiciCompagnia={addProduct.dataF} onAddProdotto={addProducts} />,
        })
      } else {
        showNotification(message.addProductSuccess, "success")
      }
    }
  }, [addProduct.dataF])

  const deleteProduct = async (id: string | number, qty: number, campaignId?: string | number) => {
    let result
    if (campaignId) {
      const chioceCamp = window.confirm(newOrder.deleteRequestCampaign)
      if (chioceCamp) {
        result = await delProduct.fetch({
          orderId: +ordine.orderId,
          productId: id,
          campaignId,
        })
        if (!result) {
          setErrorNotRender({ type: "D", count: ErrorNotRender.count + 1 })
        } else {
          reloadTimer(0.5)
        }
      }
    } else {
      const chioce = window.confirm(newOrder.deleteRequest)
      if (chioce) {
        result = await delProduct.fetch({
          orderId: +ordine.orderId,
          productId: id,
        })
        if (!result) {
          setErrorNotRender({ type: "D", count: ErrorNotRender.count + 1 })
        } else {
          reloadTimer(0.5)
        }
      }
    }
  }

  useEffect(() => {
    if (typeof orderSaveClient.dataF == "number") {
      setTimeout(() => {
        navigate("/ordini-hw-e-accessori/nuovo-ordine/" + orderSaveClient.dataF)
      }, 2100)
    }
  }, [orderSaveClient.dataF])

  useEffect(() => {
    if (ErrorNotRender.type !== "")
      switch (ErrorNotRender.type) {
        case "A":
          return showNotification(addProduct.errorMessage, "error")
        case "D":
          return showNotification(delProduct.errorMessage, "error")
        case "S":
          return showNotification(orderSaveClient.errorMessage, "error")
        default:
          return undefined
      }
    setErrorNotRender(errorNotDefault)
  }, [ErrorNotRender])

  if (loadOrdine.isLoading || indirizziClient.isLoading || terminiPagClient.isLoading)
    return <Loading />
  if (loadOrdine.isError || indirizziClient.isError || terminiPagClient.isError)
    return <ErrorComponent errorMessage={common.loadFail} />

  if (indirizziClient.dataF && indirizziClient.dataF.length == 0)
    return <ErrorComponent errorMessage={common.noAdress} />
  if (terminiPagClient.dataF && terminiPagClient.dataF.length == 0)
    return <ErrorComponent errorMessage={common.noTerm} />
  if (!utente?.permissions?.includes("menu:create-hw-order")) {
    return <ErrorComponent auth />
  }

  return (
    <div className="col-12 col-md-10 col-lg-10 col-xl-10">
      <CardContainer>
        {codice && (
          <ArrowDown
            onClick={() => setShowTestata(!showTestata)}
            style={{
              transition: "0.5s",
              transform: showTestata ? "rotate(180deg)" : "",
              marginBottom: 10,
            }}
          />
        )}
        <TitlePage Title={codice ? newOrder.title2 : newOrder.title} />
        <Title
          dataCreazione={ordine.dateCreation.split("T")[0]}
          descrizione={getButtonStatusText(ordine.statusId)}
          codice={
            ordine.orderId !== 0 ? ordine.orderId : labels?.label.HWOrder.listOrder.statusButton.new
          }
          orderDelClient={orderDelClient}
          ordine={ordine}
          showNotification={showNotification}
        />
        <hr></hr>

        <div>
          {showTestata && (
            <TestataOrdineHW
              setOrdine={setOrdine}
              ordine={ordine}
              indirizziClient={indirizziClient}
              terminiPagClient={terminiPagClient}
            />
          )}
          {ordine.statusId > 0 ? (
            <SelezionaDettaglio
              onAddProdotti={addProducts}
              onDeleteProduct={deleteProduct}
              ordine={ordine}
              orderSaveClient={orderSaveClient}
              showNotification={showNotification}
              setOrdine={setOrdine}
            />
          ) : (
            <BtnCheck
              Title={newOrder.head.save}
              style={{ width: 150 }}
              onClick={async () => {
                if (+ordine.destinationId > 0 && +ordine.termPaymentId > 0) {
                  let result = await orderSaveClient.fetch(ordine)

                  if (result) {
                    showNotification(message.orderSaveSucess, "success")
                  } else {
                    setErrorNotRender({ type: "S", count: ErrorNotRender.count + 1 })
                  }
                } else {
                  showNotification(message.adressTermNotInsertError, "error")
                }
              }}
            />
          )}
        </div>
      </CardContainer>
      {notification && <Notification message={notification.message} type={notification.type} />}
    </div>
  )
}

export default Ordine2
