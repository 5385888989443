import { forwardRef, Ref, TextareaHTMLAttributes } from "react";

type TextareaProps = {
  key?: any;
  inputDivStyle?: string;
  label?: string;
};

const Textarea = forwardRef(
  (
    props: TextareaProps & TextareaHTMLAttributes<HTMLTextAreaElement>,
    ref: Ref<HTMLTextAreaElement>
  ) => {
    const { key, label, inputDivStyle, children, ...rest } = props;

    return (
      <div
        key={key}
        className={`d-flex justify-content-between my-2 ${inputDivStyle}`}
      >
        {label && <p>{label}</p>}
        <textarea ref={ref} {...rest}></textarea>
        {children}
      </div>
    );
  }
);

export default Textarea;
