import React, { useContext, useEffect, useRef, useState } from "react"
import Loading from "../../../../Common/Components/Loader"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import Plus from "../../../../Common/Icons/Plus"
import BtnCheck from "../../../../Common/Structure/Buttons/BtnCheck"
import SimpleSelect from "../../../../Common/Structure/Select/SimpleSelect"
import CustomTable from "../../../../Common/Structure/Tables/CustomTable"
import TitlePage from "../../../../Common/Structure/Title/TitlePage"
import { ResCompagnia } from "../../../../Models/Res/ResCompagnia"
import { ResFamigliaCommerciale } from "../../../../Models/Res/ResFamigliaCommerciale"
import Input from "../../../../Common/Structure/Input/Input"
import ErrorComponent from "../../../../Common/Components/ErrorComponent"
import { ResProduct } from "../../../../Models/Res/ResProduct"
import { GeneralContext } from "../../MasterPage/MasterPage"
import { defaultModalProps } from "../../../../Common/defaultValue/modalProps"
import Trolley from "../../../ListaModali/Trolley"
import BtnVisual from "../../../../Common/Structure/Buttons/BtnVisual"
import Cart from "../../../../Common/Icons/Cart"
import SearchIcon from "../../../../Common/Icons/SearchIcon"
import CardContainer from "../../../../Common/Components/CardContainer"
import BtnClose from "../../../../Common/Icons/BtnClose"
import SpuntaIcon from "../../../../Common/Icons/Spunta"
import ArrowDown from "../../../../Common/Icons/ArrowDown"
import { LabelLan } from "../../../../Models/LabelLan"

type Props = {}

const CatalogoRicambi = (props: Props) => {
  const [famigliaCommerciale, setFamigliaCommerciale] = useState<string | undefined>("98")
  const [compagnia, setCompagnia] = useState<string | undefined>()
  const [sezione, setSezione] = useState<string | undefined>()
  const [searchProduct, setSearchProduct] = useState<string>("")
  const [searchCampain, setSearchCampain] = useState<string>("")
  const [descriptionSearch, setDescriptionSearch] = useState<string>("")
  const [searchDisabled, setSearchDisabled] = useState<boolean>(true)

  const [famigliaSort, setFamigliaSort] = useState<ResFamigliaCommerciale[] | undefined>(undefined)
  const [campaigns, setcampaigns] = useState<any[] | undefined>(undefined)
  const [compagniaSort, setCompagniaSort] = useState<ResCompagnia[] | undefined>(undefined)
  const [sezioneSort, setSezioneSort] = useState<any[] | undefined>(undefined)
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const refFamiglia = useRef<string | undefined>()
  const refCompagnia = useRef<string | undefined>()
  const refSezione = useRef<string | undefined>()
  const [productsSorted, setProductsSorted] = useState<
    (string | Function | JSX.Element)[][] | undefined
  >(undefined)

  const level1Client = useGetHttp("product/hierarchy/family")
  const level2Client = useGetHttp("product/hierarchy/section")
  const level3Client = useGetHttp("product/hierarchy/lastlevel")
  const campaignClient = useGetHttp("product/get-campaings")

  const productsClient = useGetHttp("product/getall")
  const prodottoDisClient = useGetHttp("product/available")
  const gContex = useContext(GeneralContext)
  const {
    label: {
      HWOrder: { catalogue },
      common,
      notification: { message },
    },
  } = gContex.labels as LabelLan

  const defaultTableHeadersCatalogo = [
    catalogue.list.product,
    catalogue.list.MnemoCode,
    catalogue.list.description,
    catalogue.list.minOrder,
    catalogue.list.minDelivery,
    catalogue.list.priceList,
    catalogue.list.assignmentPrice,
    catalogue.list.inTrolley,
    "",
  ]

  useEffect(() => {
    level1Client.fetch({ type: "R" })
    campaignClient.fetch()
  }, [])

  useEffect(() => {
    if (campaignClient.dataF) {
      setcampaigns(campaignClient.dataF)
    }
  }, [campaignClient.dataF])

  useEffect(() => {
    if (level1Client.dataF) {
      const data: ResFamigliaCommerciale[] = level1Client.dataF.sort(
        (a: ResFamigliaCommerciale, b: ResFamigliaCommerciale) => {
          return a.hierarchyCode > b.hierarchyCode
        }
      )
      setFamigliaSort(data)
    }
  }, [level1Client.dataF])

  useEffect(() => {
    if (famigliaCommerciale) {
      level2Client.fetch({ familyCode: famigliaCommerciale, type: "R" })
    }
  }, [famigliaCommerciale])

  useEffect(() => {
    if (level2Client.dataF) {
      setCompagnia(undefined)
      setCompagniaSort(
        level2Client.dataF.sort((a: ResCompagnia, b: ResCompagnia) => {
          return a.id > b.id
        })
      )
    }
  }, [level2Client.dataF])

  useEffect(() => {
    if (compagnia) {
      level3Client.fetch({
        familyCode: famigliaCommerciale,
        fasciaCode: compagnia.toString(),
        type: "R",
      })
    }
  }, [compagnia])

  useEffect(() => {
    if (level3Client.dataF) {
      setSezione(undefined)
      setSezioneSort(level3Client.dataF)
    } else {
      setSezioneSort(undefined)
    }
  }, [level2Client.dataF])

  const searchProducts = () => {
    let page = selectedPage
    if (
      famigliaCommerciale !== refFamiglia.current ||
      compagnia !== refCompagnia.current ||
      sezione !== refSezione.current
    ) {
      setSelectedPage(1)
      setTotalPage(1)
      page = 1
    }
    productsClient.fetch({
      hierarchyCode: `${famigliaCommerciale ? famigliaCommerciale : ""}${
        compagnia ? compagnia : ""
      }${sezione ? sezione : ""}`,
      productCode: searchProduct?.trim(),
      campaignId: searchCampain,
      description: descriptionSearch,
      page: page,
      type: "R",
    })
  }

  useEffect(() => {
    if (famigliaCommerciale || searchProduct || searchCampain || descriptionSearch) {
      searchProducts()
      window.scroll({ top: 100 })
    }
  }, [selectedPage])

  useEffect(() => {
    if (famigliaCommerciale || searchCampain || searchProduct || descriptionSearch) {
      setSearchDisabled(false)
    } else {
      setSearchDisabled(true)
    }
  }, [famigliaCommerciale, searchCampain, searchProduct, descriptionSearch])

  useEffect(() => {
    if (productsClient.dataF) {
      refFamiglia.current = famigliaCommerciale
      refCompagnia.current = compagnia
      const { products, totalPages } = productsClient.dataF
      const productsSortedObj = products
      setTotalPage(totalPages)
      setProductsSorted(
        productsSortedObj.map((x: ResProduct) => [
          x.productCode,
          x.codiceMnemonico,
          x.description,
          x.minimoOrdine,
          x.minimoConsegna,
          `${x.prezzoListino ? x.prezzoListino + "€" : "N.a."}`,
          x.prezzoCessione && x.prezzoCessione + "€",
          gContex.features?.trolley?.trolleyListRes.find((y) => {
            return y.productCode.toString() == x.productCode
          }) && <SpuntaIcon color="green" width="1.5rem" height="1.5rem" />,
          gContex.features?.trolley?.trolleyListRes.find((y) => {
            return y.productCode.toString() == x.productCode
          }) ? (
            <></>
          ) : (
            <Plus
              onClick={() => {
                gContex.features?.trolley?.addProduct(x)
              }}
            />
          ),
        ])
      )
    } else if (!productsClient.dataF) {
      setProductsSorted([])
      setTotalPage(0)
      setSelectedPage(1)
    }
  }, [productsClient.dataF, prodottoDisClient.dataF, gContex.features?.trolley?.trolleyListRes])

  if (level1Client.isError || level2Client.isError) {
    return <ErrorComponent errorMessage={common.loadFail} />
  }

  if (level1Client.isLoading) {
    return <Loading />
  }

  if (!gContex.utente?.permissions?.includes("menu:catalogue")) {
    return <ErrorComponent auth />
  }

  return (
    <div className="col-12 col-md-11 col-lg-11 col-xl-10">
      <CardContainer customizeClassNames="d-md-flex">
        <div className="d-flex justify-content-between flex-column flex-md-row align-content-center">
          <TitlePage Title={catalogue.titleSpare} />
          {gContex.utente?.permissions?.includes("feature:trolley") && (
            <BtnVisual
              btnStyle="h-25 my-0 mb-3 my-md-5 fixedButtonWidth align-self-end"
              dire={true}
              onClick={() => {
                gContex.features?.setModal({
                  ...defaultModalProps,
                  isVisible: true,
                  children: <Trolley type={"R"} />,
                  reload: false,
                })
              }}
              Title={catalogue.fields.trolley}
              icon={<Cart width={20} height={20} />}
            />
          )}
        </div>
        <div className="d-flex gap-5 justify-content-center m-0 mb-5 m-md-4 mb-md-5 flex-column flex-md-row ">
          <div className="col-12 col-md-5">
            <SimpleSelect
              label={catalogue.fields.familyCode}
              name="famigliaCommerciale"
              isLoading={level1Client.isLoading}
              disabled
              onChange={(e) => setFamigliaCommerciale(e.currentTarget.value)}
              value={famigliaCommerciale}
              selectDivStyle="align-items-center my-3"
            >
              <option value="">{common.select + "..."}</option>
              {famigliaSort &&
                famigliaSort.map((option: ResFamigliaCommerciale, index: number) => (
                  <option key={index} value={option.hierarchyCode}>
                    {option.hierarchyCode} - {option.description}
                  </option>
                ))}
            </SimpleSelect>
            <SimpleSelect
              label={catalogue.fields.section}
              isLoading={level2Client.isLoading}
              onChange={(e) => setCompagnia(e.currentTarget.value)}
              value={compagnia ?? ""}
              selectDivStyle="align-items-center my-3"
            >
              <option value="">{common.select + "..."}</option>
              {compagniaSort &&
                compagniaSort.map((option: ResCompagnia, index: number) => (
                  <option key={index} value={option.hierarchyCode}>
                    {option.hierarchyCode} - {option.description}
                  </option>
                ))}
            </SimpleSelect>
            <SimpleSelect
              label={catalogue.fields.group}
              isLoading={level3Client.isLoading}
              onChange={(e) => setSezione(e.currentTarget.value)}
              value={sezione ?? ""}
              selectDivStyle="align-items-center my-3"
            >
              <option value="">{common.select + "..."}</option>
              {sezioneSort &&
                sezioneSort.map((option: any, index: number) => (
                  <option key={index} value={option.hierarchyCode}>
                    {option.hierarchyCode} - {option.description}
                  </option>
                ))}
            </SimpleSelect>
          </div>
          <div className="col-12 col-md-5">
            <Input
              label={catalogue.fields.productCode}
              type="text"
              placeholder="123456"
              onChange={(e) => setSearchProduct(e.currentTarget.value)}
              inputDivStyle="align-items-center"
              spacing={true}
            />
            <Input
              label={catalogue.fields.description}
              type="text"
              placeholder={catalogue.fields.description + "..."}
              onChange={(e) => setDescriptionSearch(e.currentTarget.value)}
              inputDivStyle="align-items-center"
              spacing={true}
            />
            {gContex.utente?.permissions?.includes("feature:view-campaign") && (
              <SimpleSelect
                label={catalogue.fields.campaignCode}
                name="campaigns"
                disabled={!campaigns}
                isLoading={campaignClient.isLoading}
                onChange={(e) => setSearchCampain(e.currentTarget.value)}
                value={searchCampain}
                selectDivStyle="align-items-center"
              >
                <option value="">{common.select + "..."}</option>
                {campaigns &&
                  campaigns.map((option: ResFamigliaCommerciale, index: number) => (
                    <option key={index} value={option.hierarchyCode}>
                      {option.hierarchyCode} - {option.description}
                    </option>
                  ))}
              </SimpleSelect>
            )}
          </div>
        </div>
        <div className="col-12 w-100 d-flex justify-content-end">
          <BtnCheck
            onClick={searchProducts}
            Title={catalogue.fields.search}
            disabled={searchDisabled}
            icon={<SearchIcon width="1.2em" height="1.2em" />}
          />
        </div>

        {!productsClient.isLoading && productsClient.isTouched && productsSorted && (
          <CustomTable
            classNameBox="my-4"
            headers={defaultTableHeadersCatalogo}
            rows={productsSorted.length > 0 ? productsSorted : [[catalogue.list.notFound]]}
          >
            <div className="w-100 my-2 d-flex justify-content-center align-items-center gap-3">
              {totalPage > 1 && selectedPage !== 1 && (
                <ArrowDown
                  style={{ transform: "rotate(90deg)" }}
                  width={"1.5em"}
                  height={"1.5em"}
                  color="#0033A1"
                  onClick={selectedPage > 1 ? () => setSelectedPage(selectedPage - 1) : undefined}
                />
              )}
              {selectedPage > 1 && (
                <BtnVisual Title={"1"} color="#004ff9" onClick={() => setSelectedPage(1)} />
              )}
              {totalPage > 1 && <BtnVisual Title={selectedPage.toString()} color="#0033A1" />}
              {totalPage > 1 && selectedPage < totalPage && selectedPage + 1 != totalPage && (
                <BtnVisual
                  Title={(selectedPage + 1).toString()}
                  onClick={() => setSelectedPage(selectedPage + 1)}
                />
              )}
              {totalPage > 1 && selectedPage != totalPage && (
                <BtnVisual
                  Title={totalPage.toString()}
                  color="#004ff9"
                  onClick={() => setSelectedPage(totalPage)}
                />
              )}
              {totalPage > 1 && totalPage !== selectedPage && (
                <ArrowDown
                  style={{ transform: "rotate(270deg)" }}
                  width={"1.5em"}
                  height={"1.5em"}
                  color="#0033A1"
                  onClick={
                    selectedPage < totalPage ? () => setSelectedPage(selectedPage + 1) : undefined
                  }
                />
              )}
            </div>
          </CustomTable>
        )}
        {productsClient.isTouched && !productsClient.isLoading && !productsSorted && (
          <div className="d-flex justify-content-center">{catalogue.list.notFound}</div>
        )}
        {productsClient.isLoading && (
          <div className="d-flex justify-content-center">
            <Loading easyIcon={true} />
          </div>
        )}
      </CardContainer>
    </div>
  )
}

export default CatalogoRicambi
