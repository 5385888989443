import React, { useContext, useEffect, useState } from "react"
import CustomTable from "../../../../../Common/Structure/Tables/CustomTable"
import Input from "../../../../../Common/Structure/Input/Input"
import usePostHttp from "../../../../../Common/Functions/HTTPRequest/Post"
import { useNotification } from "../../../../../Common/Components/hooks/useNotification"
import { Notification } from "../../../../../Common/Components/Notification"
import BtnCheck from "../../../../../Common/Structure/Buttons/BtnCheck"
import { ResProdottoGaranzia } from "../../../../../Models/Res/RedProdottoGaranzia"
import { RichiestaPartiGaranzia } from "../../../../../Models/RichiestaPartiGaranzia"
import { reloadTimer } from "../../../../../Common/Functions/Util/reloadTime"
import { errorNotDefault } from "../../../../../Common/defaultValue/ErrorNot"
import { ErrorNotification } from "../../../../../Models/ErrorNotification"
import { LabelLan } from "../../../../../Models/LabelLan"
import { GeneralContext } from "../../../MasterPage/MasterPage"

type Props = {
  partiGaranzie: any
  richiesta: RichiestaPartiGaranzia
}

type ReqOrdineGaranzia = {
  warrantyId: number
  idIndirizzo: number
  notes: string
  reqDetails: ReqPartiGaranzia[]
}

type ReqPartiGaranzia = {
  productQty: number
  productCode: string
}

const ListaGaranzie = ({ partiGaranzie, richiesta }: Props) => {
  const [parti, setParti] = useState<ReqPartiGaranzia[]>([])
  const [ErrorNotRender, setErrorNotRender] = useState<ErrorNotification>(errorNotDefault)
  const saveWarrantlyRequest = usePostHttp("pdr/pdr/save")

  const { utente, labels } = useContext(GeneralContext)
  const {
    label: {
      warranty: { request },
    },
  } = labels as LabelLan

  const { showNotification, notification } = useNotification()

  const save = async () => {
    const request: ReqOrdineGaranzia = {
      idIndirizzo: richiesta.indirizziId,
      notes: richiesta.note ?? "",
      reqDetails: parti,
      warrantyId: partiGaranzie.warrantyId,
    }
    const result = await saveWarrantlyRequest.fetch(request)
    if (result) {
      showNotification(
        labels?.label.notification.message.saveWarrantlyRequestSuccess ?? "",
        "success"
      )
      reloadTimer(2)
    } else {
      setErrorNotRender({ type: "W", count: 1 })
    }
  }

  useEffect(() => {
    if (ErrorNotRender.type !== "") {
      showNotification(saveWarrantlyRequest.errorMessage, "error")
      setErrorNotRender(errorNotDefault)
    }
  }, [ErrorNotRender])

  const add = (partiGaranzie: ResProdottoGaranzia, qty: number) => {
    const newReqParti: ReqPartiGaranzia = {
      productCode: partiGaranzie.productCode,
      productQty: qty,
    }
    const arrayReqParti: ReqPartiGaranzia[] = parti.filter(
      (parte) => parte.productCode !== newReqParti.productCode
    )
    if (qty) arrayReqParti.push(newReqParti)

    setParti(arrayReqParti)
  }

  return (
    <div className="my-4">
      <CustomTable
        headers={[
          request.table.partCode,
          request.table.description,
          request.table.qtyAvaible,
          request.table.qtyWanted,
        ]}
        rows={partiGaranzie.products.map((x: ResProdottoGaranzia) => [
          x.productCode,
          x.description ?? labels?.label.common.missDescription,
          x.minimoOrdine === 0 ? "1" : x.minimoOrdine,
          <Input
            type="number"
            className="bg-white border w-100"
            step={1}
            max={x.minimoOrdine === 0 ? 1 : x.minimoOrdine}
            min={0}
            onChange={(e) => {
              if (+e.currentTarget.value <= (x.minimoOrdine === 0 ? 1 : x.minimoOrdine)) {
                add(x, +e.currentTarget.value)
              } else {
                add(x, 1)
                e.currentTarget.value = (x.minimoOrdine === 0 ? 1 : x.minimoOrdine).toString()
              }
            }}
            spacing={undefined}
            inputDivStyle="m-0 p-0 w-50"
          />,
        ])}
      />
      <BtnCheck
        Title={request.request}
        onClick={save}
        disabled={parti.length === 0}
        btnStyle="mt-3"
        style={{
          minWidth: 100,
        }}
        isLoading={saveWarrantlyRequest.isLoading}
      />
      {notification && <Notification message={notification.message} type={notification.type} />}
    </div>
  )
}

export default ListaGaranzie
