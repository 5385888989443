import { useState, useMemo, useContext } from "react"
import BtnClose from "../../../../../../../../Common/Icons/BtnClose"
import BtnCheck from "../../../../../../../../Common/Structure/Buttons/BtnCheck"
import CustomTable from "../../../../../../../../Common/Structure/Tables/CustomTable"
import TitlePage from "../../../../../../../../Common/Structure/Title/TitlePage"
import { ReqProdotto } from "../../../../../../../../Models/Product"
import Input from "../../../../../../../../Common/Structure/Input/Input"
import { LabelLan } from "../../../../../../../../Models/LabelLan"
import { GeneralContext } from "../../../../../../MasterPage/MasterPage"

type Props = {
  onAddProdotti: (a: ReqProdotto[], reload: boolean) => void
}

const OrdineVeloce = ({ onAddProdotti }: Props) => {
  const [codice, setCodice] = useState<number | string>(0)
  const [quantita, setQuantita] = useState<number>(0)
  const [errore, setErrore] = useState<string>("")
  const [prodotti, setProdotti] = useState<ReqProdotto[]>([])

  const gContex = useContext(GeneralContext)
  const {
    label: {
      HWOrder: { newOrder },
      notification: { message },
    },
  } = gContex.labels as LabelLan

  const prodottoAdd = () => {
    if (codice && quantita) {
      setErrore("")
      if (prodotti.filter((x) => x.productCode == codice).length == 0) {
        setProdotti((prev) => {
          return [...prev, { productCode: codice.toString(), productQty: quantita }]
        })
      } else {
        setProdotti((prev) => {
          return prev.map((x) => {
            if (x.productCode == codice) {
              return { productCode: x.productCode, productQty: x.productQty + quantita }
            } else {
              return x
            }
          })
        })
      }
    } else {
      setErrore(message.erroFormCompilation)
    }
  }

  const prodottoDel = (code: string | number) => {
    setProdotti((prev) => {
      return prev.filter((x) => x.productCode !== code)
    })
  }

  const listProdotti: (string | JSX.Element)[][] = useMemo(() => {
    return prodotti.map((x) => [
      x.productCode ?? "",
      x.productQty.toString(),
      <BtnClose onClick={() => prodottoDel(x.productCode ?? "")} />,
    ])
  }, [prodotti])

  return (
    <>
      <div className="d-flex gap-5 flex-column flex-md-row mt-5">
        <div className="col-12 col-md-5">
          <Input
            label={newOrder.body.list.productCode}
            type="text"
            placeholder="123"
            className={"w-75"}
            onChange={(e) => setCodice(e.currentTarget.value)}
          />
          <Input
            label={newOrder.body.list.quantity}
            type="number"
            placeholder="1"
            className={"w-75"}
            onChange={(e) => setQuantita(+e.currentTarget.value)}
          />
          {errore && <p className="text-danger">{errore}</p>}

          <BtnCheck
            onClick={prodottoAdd}
            Title={newOrder.body.details.addNewLine}
            btnStyle="my-2"
            bgColor="bg-primary"
          />
          <BtnCheck
            onClick={() => prodotti && onAddProdotti(prodotti, false)}
            Title={newOrder.body.addProducts}
            btnStyle="m-2"
            disabled={prodotti.length == 0 ? true : false}
          />
        </div>
        <div className="col-12 col-md-6 my-2">
          {prodotti.length > 0 && (
            <CustomTable
              rows={listProdotti}
              headers={[
                newOrder.body.details.code,
                newOrder.body.list.quantity,
                newOrder.body.details.delete,
              ]}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default OrdineVeloce
