import { Combobox } from "@headlessui/react"
import { DealerImpersonated } from "../../../../Models/DealerImpersonated"
import { SetStateAction, useState } from "react"
import { UserInfo } from "../../../../Models/userInfo"
import { ResDealerList } from "../../../../Models/Res/ResDealerList"
import ArrowDown from "../../../../Common/Icons/ArrowDown"
import { GeneralContext } from "../MasterPage"
import { useContext } from "react"

type Props = {
  dealerImpersonated: ResDealerList | null
  setDealerIdImpersonated: React.Dispatch<SetStateAction<ResDealerList | null>>
  setUserInfo: React.Dispatch<SetStateAction<UserInfo | undefined>>
  setIsLoading: React.Dispatch<SetStateAction<boolean>>
  dealerList: ResDealerList[] | undefined
}

const ComboBoxImp = ({
  dealerImpersonated,
  setDealerIdImpersonated,
  setIsLoading,
  setUserInfo,
  dealerList,
}: Props) => {
  const [query, setQuery] = useState("")
  const {features} = useContext(GeneralContext)

  let filteredDealer: ResDealerList[] = []

  if (dealerList) {
    filteredDealer =
      query === ""
        ? dealerList
        : dealerList.filter(
            (dealer) =>
              dealer.corporateName.toUpperCase().includes(query.toUpperCase()) ||
              dealer.dealerCode.toUpperCase().includes(query.toUpperCase())
          )
  }

  return (
    <Combobox
      value={dealerImpersonated}
      onChange={(value) => {
        if (value) {
          setDealerIdImpersonated(value)
          setUserInfo((prev) => {
            if (prev) return { id: prev.id, idSalDivOrgSls: value.corporateName }
          })
          localStorage.setItem(
            "IMP_USER",
            JSON.stringify({
              coorporateName: value.corporateName,
              id: value.dealerId,
              sal: value.salOrDisChDivId,
            })
          )
          features?.trolley?.loadTrolley()
          setIsLoading(true)
        }
      }}
    >
      {({ open }) => (
        <div
          style={{ position: "relative", backgroundColor: "white", maxWidth: 500 }}
          className="rounded w-100"
        >
          <Combobox.Input
            onChange={(event) => setQuery(event.target.value)}
            placeholder={"Seleziona un Dealer"}
            displayValue={(dealer: ResDealerList) => dealer?.corporateName}
            className={"form-control rounded"}
            style={{ width: "100%" }}
            autoComplete="off"
          />
          <Combobox.Button
            className="position-absolute d-flex align-items-center pe-2 border-0 bg-white"
            style={{ top: 10, right: "5%" }}
          >
            <ArrowDown
              style={{
                transition: "0.5s",
                transform: open ? "rotate(180deg)" : "",
              }}
            />
          </Combobox.Button>
          <Combobox.Options
            className="shadow-lg custom-scrollbar"
            style={{
              position: "absolute",
              top: 42,
              backgroundColor: "white",
              width: "100%",
              borderRadius: 5,
              padding: 0,
              borderColor: "#e2e1e1",
              opacity: 1,
              zIndex: 9999,
              maxHeight: 400,
              overflowY: "scroll",
            }}
          >
            {filteredDealer.map((dealer) => (
              <Combobox.Option
                key={dealer.dealerCode}
                value={dealer}
                style={{ listStyleType: "none", margin: 0 }}
              >
                {({ selected }) => (
                  <div
                    className={"elemToHover"}
                    style={{
                      backgroundColor: selected ? "#c3f3fc" : "",
                      padding: 10,
                      borderRadius: 5,
                    }}
                  >
                    <p className="m-0">
                      <b>{dealer.corporateName}</b>
                    </p>
                    <p className="m-0" style={{ fontSize: "0.8rem" }}>
                      {dealer.dealerCode}
                    </p>
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </div>
      )}
    </Combobox>
  )
}

export default ComboBoxImp
