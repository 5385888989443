import React, { useContext, useEffect, useState } from "react"
import { ReqOrderHeaderDto } from "../../../../../../../../Models/Req/ReqOrderHeader"
import { ConsegnaIndirizzo } from "../../../../../../../../Models/ConsegnaIndirizzo"
import { TermPaymentDto } from "../../../../../../../../Models/TerminiPagamento"
import Input from "../../../../../../../../Common/Structure/Input/Input"
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form"
import { ListStateTestataOrdine } from "../../../../../../../../Models/ListStateTestataOrdine"
import SimpleSelect from "../../../../../../../../Common/Structure/Select/SimpleSelect"
import { GeneralContext } from "../../../../../../MasterPage/MasterPage"
import { FetchDataState } from "../../../../../../../../Models/Generic"
import { LabelLan } from "../../../../../../../../Models/LabelLan"

type Props = {
  ordine: ReqOrderHeaderDto
  setOrdine: React.Dispatch<React.SetStateAction<ReqOrderHeaderDto>>
  register: UseFormRegister<ListStateTestataOrdine>
  getValues: UseFormGetValues<ListStateTestataOrdine>
  setValue: UseFormSetValue<ListStateTestataOrdine>
  errors: FieldErrors<ListStateTestataOrdine>
  indirizziClient: FetchDataState
  terminiPagClient: FetchDataState
}

const InputFieldsOrdine = ({
  ordine,
  setOrdine,
  getValues,
  register,
  setValue,
  errors,
  indirizziClient,
  terminiPagClient,
}: Props) => {
  const [sortedIndirizzi, setSortedIndirizzi] = useState<ConsegnaIndirizzo[]>([])
  const [sortedTermini, setSortedTermini] = useState<TermPaymentDto[]>([])

  const { labels } = useContext(GeneralContext)
  const {
    label: {
      HWOrder: {
        newOrder: { head },
      },
    },
  } = labels as LabelLan

  const salvaTestata = async () => {
    setOrdine((prev) => {
      return {
        ...prev,
        destinationId: getValues("idDestinazione").toString(),
        termPaymentId: getValues("idPagamento").toString(),
        dealerOrderCode: getValues("nConsessionario") ?? "",
        dealerOrderDate: getValues("dataConsessionario") ?? "",
        flagDelivery: getValues("flagDelivery"),
        notes: getValues("notes") ?? "",
        orderType: getValues("tipoOrdine"),
      }
    })
  }

  useEffect(() => {
    if (indirizziClient.dataF && terminiPagClient.dataF) {
      const sortedIndirizziF: ConsegnaIndirizzo[] = indirizziClient.dataF.sort(
        (a: ConsegnaIndirizzo, b: ConsegnaIndirizzo) => {
          return a.corporateName.localeCompare(b.corporateName)
        }
      )
      const sortedTerminiF: TermPaymentDto[] = terminiPagClient.dataF.sort(
        (a: TermPaymentDto, b: TermPaymentDto) => {
          return a.description.localeCompare(b.description)
        }
      )
      if (
        ordine.destinationId == "0" &&
        ordine.termPaymentId == "0" &&
        sortedIndirizziF[0] &&
        sortedTerminiF[0]
      ) {
        setValue("idDestinazione", sortedIndirizziF[0].id)
        setValue("idPagamento", sortedTerminiF[0].id)
        salvaTestata()
      }
      setSortedIndirizzi(sortedIndirizziF)
      setSortedTermini(sortedTerminiF)
    }
  }, [indirizziClient.dataF, terminiPagClient.dataF])

  let dateSet
  if (ordine.dealerOrderDate) {
    let dateSeparated = new Date(ordine?.dealerOrderDate).toLocaleDateString().split("/")
    dateSet = new Date(+dateSeparated[2], +dateSeparated[1] - 1, +dateSeparated[0] + 1)
      .toISOString()
      .split("T")[0]
  }

  return (
    <div className="w-100">
      <Input
        label={head.nOrderSessional}
        type="text"
        placeholder="PAL-123456"
        {...register("nConsessionario")}
        defaultValue={ordine?.dealerOrderCode}
        className={errors.nConsessionario ? "invalid min-width-50" : "min-width-50"}
        inputDivStyle="flex-column"
        style={{ width: "100%" }}
      />

      <Input
        label={head.dateOrder}
        type="date"
        value={dateSet ?? undefined}
        {...register("dataConsessionario", { required: true })}
        defaultValue={ordine?.dealerOrderDate.split("T")[0]}
        className={errors.dataConsessionario ? "invalid min-width-50" : "min-width-50"}
        inputDivStyle="flex-column"
        style={{ width: "100%" }}
      />

      <SimpleSelect
        label={head.shipAddress}
        onChange={(e) => setValue("idDestinazione", +e.currentTarget.value)}
        value={ordine?.destinationId ?? getValues("idDestinazione")}
        selectStyle={`w-100 ${errors.idDestinazione ? "invalid" : ""}`}
        isLoading={indirizziClient?.isLoading}
        selectDivStyle="flex-column"
      >
        {sortedIndirizzi &&
          sortedIndirizzi.map((x: ConsegnaIndirizzo, i: number) => {
            return (
              <option value={x.id} key={i + "-indirizzi"}>
                {`${x.corporateName} - ${x.address} - ${x.locality} - ${x.postalCode}`}
              </option>
            )
          })}
      </SimpleSelect>
      <SimpleSelect
        onChange={(e) => setValue("idPagamento", +e.currentTarget.value)}
        label={head.termPay}
        value={ordine?.termPaymentId ?? getValues("idPagamento")}
        selectStyle={`w-100 ${errors.idPagamento ? "invalid" : ""}`}
        isLoading={terminiPagClient?.isLoading}
        selectDivStyle="flex-column"
      >
        {sortedTermini &&
          sortedTermini.map((x: TermPaymentDto, i: number) => {
            return (
              <option value={x.id} key={i + "-terminiPagamento"}>
                {x.description}
              </option>
            )
          })}
      </SimpleSelect>
    </div>
  )
}

export default InputFieldsOrdine
