export const handleDownload = async (id: string, name: string) => {
  try {
   
    const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}storage/order/document-download?fileId=` + id, {
      method: "GET",
    })

    if (!response.ok) {
      throw new Error("Errore nella richiesta HTTP: " + response.status)
    }

    const reader = response.body?.getReader()
    if (!reader) {
      throw new Error("Impossibile ottenere il reader del corpo della risposta.")
    }

    const chunks: Uint8Array[] = []

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      if (value) {
        chunks.push(value)
      }
    }

    const blob = new Blob(chunks, { type: "application/octet-stream" })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", name)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error("Errore durante il download del file:", error)
  }
}
