import React, { useContext, useEffect, useState } from "react"
import SelectSizable from "../../../../Common/Structure/Select/SelectSizable"
import TitlePage from "../../../../Common/Structure/Title/TitlePage"
import CustomTable from "../../../../Common/Structure/Tables/CustomTable"
import BtnCheck from "../../../../Common/Structure/Buttons/BtnCheck"
import NewUtenteOrganigramma from "./components/NewUtente"
import { NewUserOG } from "../../../../Models/NuovoUtenteOG"
import { GeneralContext } from "../../MasterPage/MasterPage"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import usePostHttp from "../../../../Common/Functions/HTTPRequest/Post"
import Pencil from "../../../../Common/Icons/Pencil"
import useDeleteHttp from "../../../../Common/Functions/HTTPRequest/Delete"
import CardContainer from "../../../../Common/Components/CardContainer"
import Cestino from "../../../../Common/Icons/Cestino"
import { reloadTimer } from "../../../../Common/Functions/Util/reloadTime"
import { getLabelRuoliOG } from "../../../../Common/Functions/Util/getLabelRuoliOG"
import Loading from "../../../../Common/Components/Loader"
import { useNotification } from "../../../../Common/Components/hooks/useNotification"
import { Notification } from "../../../../Common/Components/Notification"
import ErrorComponent from "../../../../Common/Components/ErrorComponent"
import { LabelLan } from "../../../../Models/LabelLan"

const Organigramma = () => {
  const [showNewUser, setShowNewUser] = useState(false)
  const [agentiList, setAgentiList] = useState<(string | JSX.Element)[][]>([])
  const [idEdit, setIdEdit] = useState<NewUserOG | undefined>()

  const gContext = useContext(GeneralContext)
  const { utente, labels } = gContext
  const {
    label: {
      agencyProfile: { organizationChart },
      notification: { message },
      common,
    },
  } = labels as LabelLan

  const getOrgDealer = useGetHttp("organization/dealer/get-orgchart")
  const addUserClient = usePostHttp("organization/user/add-to-chart")
  const editUserClient = usePostHttp("organization/user-chart/update")
  const deleteUserClient = useDeleteHttp("organization/user-chart/delete")

  const { notification, showNotification } = useNotification()

  useEffect(() => {
    const fn = async () => {
      if (utente?.idUser && utente.role == "D") {
        await getOrgDealer.fetch({ dealerId: utente?.idUser })
      } else if (utente?.role == "A") {
        await getOrgDealer.fetch()
      }
    }
    fn()
  }, [])

  const headers1 = [
    organizationChart.list.tableFields.role,
    organizationChart.list.tableFields.names,
    organizationChart.list.tableFields.telephone,
    organizationChart.list.tableFields.email,
    organizationChart.list.tableFields.actions,
  ]

  useEffect(() => {
    if (getOrgDealer.dataF) {
      setAgentiList(
        getOrgDealer.dataF.map((x: any) => {
          return [
            getLabelRuoliOG(x.roleId),
            `${x.name} ${x.surname}`,
            `${x.mobileNumber ?? ""} - ${x.phoneNumber ?? ""}`,
            x.email,
            utente?.role === "D" && (
              <div className="d-flex gap-2">
                <Pencil
                  style={{ color: "blue" }}
                  onClick={() => {
                    setIdEdit({
                      id: x.id,
                      cognome: x.surname,
                      nome: x.name,
                      ruolo: x.roleId,
                      cellullare: x.mobileNumber,
                      telefono: x.phoneNumber,
                      email: x.email,
                      fax: x.fax,
                    })
                    setShowNewUser(true)
                  }}
                />
                <Cestino
                  style={{ color: "red" }}
                  height={"1.2em"}
                  width={"1.2em"}
                  onClick={async () => {
                    const res = await deleteUserClient.fetch({ orgId: x.id })
                    if (res) {
                      setAgentiList((prev) => {
                        return prev.filter((y) => !y.includes(x.email))
                      })
                      showNotification(message.deleteOrderSuccess, "success")
                    } else {
                      showNotification(deleteUserClient.errorMessage, "error")
                    }
                  }}
                />
              </div>
            ),
          ]
        })
      )
    }
  }, [getOrgDealer.dataF])

  if (!utente?.permissions?.includes("menu:organigram")) {
    return <ErrorComponent auth />
  }

  const addUser = async (form: NewUserOG, edit: boolean) => {
    if (form) {
      if (!edit) {
        await addUserClient.fetch({
          id: utente?.idUser,
          name: form.nome,
          surname: form.cognome,
          roleId: form.ruolo,
          phoneNumber: form.telefono,
          mobileNumber: form.cellullare,
          email: form.email,
        })
      } else {
        await editUserClient.fetch({
          id: idEdit?.id,
          name: form.nome,
          surname: form.cognome,
          roleId: form.ruolo,
          phoneNumber: form.telefono,
          mobileNumber: form.cellullare,
          email: form.email,
        })
      }
    }
    reloadTimer(0)
  }

  if (showNewUser)
    return <NewUtenteOrganigramma saveUser={addUser} setShowNewUser={setShowNewUser} id={idEdit} />

  if (getOrgDealer.isLoading) {
    return <Loading />
  }

  if (getOrgDealer.isError) {
    return (
      <ErrorComponent errorMessage={getOrgDealer.errorMessage || labels?.label.common.loadFail} />
    )
  }

  return (
    <div className="col-12 col-md-11 col-lg-11 col-xl-11">
      <CardContainer>
        <div className="text-center text-md-start">
          <TitlePage Title={organizationChart.title} />
          {utente?.role === "D" && (
            <BtnCheck
              onClick={() => {
                setIdEdit(undefined)
                setShowNewUser(true)
              }}
              Title={organizationChart.list.newButton}
              btnStyle="max-w-25"
            />
          )}
        </div>

        <div className="d-flex flex-column flex-md-row flex-xl-row justify-content-between center-on-mobile mt-5 w-100">
          <div className="d-flex flex-column justify-content-between col-12">
            <div className="my-5 my-md-0 ">
              {agentiList.length > 0 ? (
                <CustomTable
                  headers={headers1.map((x) => {
                    if (utente?.role === "A") {
                      if (x !== organizationChart.list.tableFields.actions) {
                        return x
                      } else {
                        return ""
                      }
                    } else {
                      return x
                    }
                  })}
                  rows={agentiList}
                />
              ) : (
                <h4>{common.noOrgChart}</h4>
              )}
            </div>
          </div>
        </div>
      </CardContainer>
      {notification && <Notification message={notification.message} type={notification.type} />}
    </div>
  )
}

export default Organigramma
