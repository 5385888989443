import React, { Dispatch, SetStateAction, useContext } from "react"
import { ResDettaglio } from "../../Models/Res/ResDettaglio"
import Textarea from "../../Common/Structure/Textarea/Textarea"
import { FetchDataState } from "../../Models/Generic"
import { LabelLan } from "../../Models/LabelLan"
import { GeneralContext } from "../UI/MasterPage/MasterPage"
import BtnSave from "../../Common/Structure/Buttons/BtnSave"
import BtnCheck from "../../Common/Structure/Buttons/BtnCheck"

type Props = {
  setNewOrderDetails: Dispatch<SetStateAction<ResDettaglio[]>>
  loadDettagliOrdine: any
  data: any
  onClose: () => void
}

const NoteProdotto = ({ setNewOrderDetails, data, loadDettagliOrdine, onClose }: Props) => {
  const { labels } = useContext(GeneralContext)
  const {
    label: {
      modals: { noteProduct },
      HWOrder: {
        newOrder: {
          head: { save },
        },
      },
    },
  } = labels as LabelLan

  return (
    <div className="w-100 d-flex flex-column gap-4">
      <h5 className="text-start">{noteProduct}</h5>
      <Textarea
        className="w-100"
        inputDivStyle="w-100"
        defaultValue={data.note}
        onBlur={(e) =>
          setNewOrderDetails((prev) => {
            if (prev.length != 0) {
              return prev.map((det: any) => {
                if (det.id == data.id) {
                  return {
                    ...det,
                    note: e.target.value,
                  }
                } else return det
              })
            } else {
              return loadDettagliOrdine.map((det: any) => {
                if (det.id == data.id) {
                  return {
                    ...det,
                    note: e.target.value,
                  }
                } else return det
              })
            }
          })
        }
      />
      <BtnCheck Title={save} onClick={onClose} style={{maxWidth: 100}} />
    </div>
  )
}

export default NoteProdotto
