import React, { SVGProps, useState } from "react"

type SpuntaIconProps = {} & SVGProps<SVGSVGElement>

const SpuntaIcon = ({ ...rest }: SpuntaIconProps) => {
  const [anima, setAnima] = useState(false)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
      fill="currentColor"
      viewBox="0 0 448 512"
      className={`iconCustom ${rest.className} ${anima && "FeedBackClick"}`}
      onClick={rest.onClick}
      onMouseDown={() => {
        setAnima(true)
        setTimeout(() => {
          setAnima(false)
        }, 0.1 * 1000)
      }}
      {...rest}
    >
      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
    </svg>
  )
}

export default SpuntaIcon
