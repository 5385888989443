import React, { ReactNode, useContext, useEffect, useState } from "react"
import RadioGroups, { RadioGroupItem } from "../../../../../../Common/Components/RadioGroups"
import BtnClose from "../../../../../../Common/Icons/BtnClose"
import Cart from "../../../../../../Common/Icons/Cart"
import Plus from "../../../../../../Common/Icons/Plus"
import { ReqProdotto } from "../../../../../../Models/Product"
import { ReqOrderHeaderDto } from "../../../../../../Models/Req/ReqOrderHeader"
import Trolley from "../../../../../ListaModali/Trolley"
import { GeneralContext } from "../../../../MasterPage/MasterPage"
import ListaProdottiOrdine from "./Lista"
import OrdineManuale from "./TipoDettaglio/Manuale/OrdineManuale"
import OrdineVeloce from "./TipoDettaglio/Veloce/OrdineVeloce"
import OrdineDaFile from "./TipoDettaglio/Excell/OrdineDaFile"
import { FetchDataState } from "../../../../../../Models/Generic"
import CatalogoProdotto from "./TipoDettaglio/Catalogo"
import SimpleSelect from "../../../../../../Common/Structure/Select/SimpleSelect"
import BtnVisual from "../../../../../../Common/Structure/Buttons/BtnVisual"
import { LabelLan } from "../../../../../../Models/LabelLan"

type Props = {
  onAddProdotti: (a: ReqProdotto[], reload: boolean) => void
  onDeleteProduct: (id: string | number, qty: number, campaignId?: string | number) => void
  ordine: ReqOrderHeaderDto
  orderSaveClient: FetchDataState
  showNotification: (message: string, type: "success" | "error") => void
  setOrdine: React.Dispatch<React.SetStateAction<ReqOrderHeaderDto>>
}



const SelezionaDettaglio = ({
  onAddProdotti,
  onDeleteProduct,
  ordine,
  orderSaveClient,
  showNotification
}: Props) => {
  const [selectProduct, setSelectProduct] = useState<number>(1)
  const [showList, setShowList] = useState(false)
  const [addTypeProduct, setAddTypeProduct] = useState<ReactNode>(<></>)

  const { labels } = useContext(GeneralContext)
  const {
    label: {
      HWOrder: {
        newOrder: { body },
      },
    },
  } = labels as LabelLan

  const radioGroup: RadioGroupItem[] = [
    { id: 1, label: body.types.knowCode, value: "code" },
    { id: 2, label: body.types.catalogue, value: "cat" },
    { id: 3, label: body.types.xlsx, value: "xls" },
    { id: 4, label: body.types.speed, value: "fast" },
  ]

  useEffect(() => {
    switch (selectProduct) {
      case 1:
        setAddTypeProduct(<OrdineManuale onAddProdotti={onAddProdotti} />)
        break
      case 2:
        setAddTypeProduct(<CatalogoProdotto onAddProdotti={onAddProdotti} />)
        break
      case 3:
        setAddTypeProduct(<OrdineDaFile onAddProdotti={onAddProdotti} />)
        break
      case 4:
        setAddTypeProduct(<OrdineVeloce onAddProdotti={onAddProdotti} />)
        break
    }
  }, [selectProduct])

  return (
    <div className="my-1">
      <>
        <div className="d-flex justify-content-end m-1"></div>
        {!showList && (
          <ListaProdottiOrdine
            onDeleteProduct={onDeleteProduct}
            ordine={ordine}
            addProducts={onAddProdotti}
            showNotification={showNotification}
            orderSaveClient={orderSaveClient}
            setShowList={setShowList}
          />
        )}
      </>
      <>
        {showList && (
          <div
            className="col-12 border d-flex flex-column w-100 rounded-5 my-5 p-md-5 p-3"
            style={{ backgroundColor: "#F2F2F2", boxShadow: "0 2px 5px gray" }}
          >
            <div className="d-flex flex-column flex-sm-row-reverse justify-content-between gap-5 w-100">
              <BtnVisual
                Title={body.close}
                color="gray"
                btnStyle="align-self-start "
                onClick={() => setShowList(false)}
                icon={<BtnClose color="gray" />}
              />
              <SimpleSelect
                selectDivStyle="flex-column flex-fill w-100 "
                selectStyle="selectSpecialSize"
                label={body.selectType}
                onChange={(e) => setSelectProduct(+e.currentTarget.value)}
                defaultValue={radioGroup[selectProduct - 1].id}
              >
                {radioGroup.map((gruppo, index) => (
                  <option value={gruppo.id} key={index + "gruppo"}>
                    {gruppo.label}
                  </option>
                ))}
              </SimpleSelect>
            </div>
            {addTypeProduct}
          </div>
        )}
      </>
    </div>
  )
}

export default SelezionaDettaglio
