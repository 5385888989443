import React from "react"

export type NotificationProps = {
  message: string
  type: "success" | "error"
}

export const Notification = ({ message, type }: NotificationProps) => {
  return (
    <>
      {type === "success" && (
        <div className="alert alert-success positionNotification" role="alert">
          {message}
        </div>
      )}
      {type === "error" && (
        <div className="alert alert-danger positionNotification" role="alert">
          {message}
        </div>
      )}
    </>
  )
}
