import React from "react"
import Input from "../../../../../Common/Structure/Input/Input"
import SimpleSelect from "../../../../../Common/Structure/Select/SimpleSelect"
import { InputOverchargeSearch } from "../../../../../Models/OverchargeInputSearch"

type Props = {
    userTokenInfo: any
    setInputSearch: React.Dispatch<React.SetStateAction<InputOverchargeSearch>>
}

const InputSearch = ({setInputSearch, userTokenInfo}: Props) => {
  return (
    <div className="flex-fill">
      <Input
        label="Sales Organization"
        inputDivStyle="align-items-center gap-2"
        disabled
        value={userTokenInfo.salesOrganization}
      />
      <SimpleSelect
        label="Tipo Ordine"
        selectDivStyle="align-items-center gap-2"
        onChange={({ currentTarget }) =>
          setInputSearch((prev) => {
            return { ...prev, ProductType: currentTarget.value === "R" ? "R" : "" }
          })
        }
      >
        <option value="">Ordini Accessori</option>
        <option value={"R"}>Ordini Ricambi</option>
      </SimpleSelect>
      <Input
        label="Canale di distribuzione"
        inputDivStyle="align-items-center gap-2"
        onChange={({ currentTarget }) =>
          setInputSearch((prev) => {
            return { ...prev, DistributionChannel: currentTarget.value }
          })
        }
      />
    </div>
  )
}

export default InputSearch
