import React, { useContext, useEffect, useRef, useState } from "react"
import CardContainer from "../../../../Common/Components/CardContainer"
import TitlePage from "../../../../Common/Structure/Title/TitlePage"
import Input from "../../../../Common/Structure/Input/Input"
import BtnVisual from "../../../../Common/Structure/Buttons/BtnVisual"
import Textarea from "../../../../Common/Structure/Textarea/Textarea"
import { useForm } from "react-hook-form"
import { ResDealer } from "../../../../Models/Res/ResDealer"
import { GeneralContext } from "../../MasterPage/MasterPage"
import { useNotification } from "../../../../Common/Components/hooks/useNotification"
import { Notification } from "../../../../Common/Components/Notification"
import usePostHttp from "../../../../Common/Functions/HTTPRequest/Post"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import ErrorComponent from "../../../../Common/Components/ErrorComponent"
import Loading from "../../../../Common/Components/Loader"
import { get } from "http"
import { LabelLan } from "../../../../Models/LabelLan"

type Props = {}

const Anagrafica = (props: Props) => {
  const [dealer, setDealer] = useState<ResDealer>()
  const { utente, labels } = useContext(GeneralContext)
  const {
    label: {
      agencyProfile: { details },
    },
  } = labels as LabelLan
  const { notification, showNotification } = useNotification()
  const {
    formState: { isLoading, isValid },
    register,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<ResDealer>({ mode: "all" })

  const submitFormElement = useRef<HTMLButtonElement>(null)

  const saveClient = usePostHttp("dealer/update-dealer-details")
  const getClient = useGetHttp("dealer/get-dealer-details")

  useEffect(() => {
    const initial = async () => {
      if (utente?.idUser && utente.role === "D") {
        const result = await getClient.fetch({ dealerId: utente?.idUser })
      } else if (utente?.idUser && utente.role === "A") {
        const result = await getClient.fetch()
      }
    }
    initial()
  }, [utente?.idUser])

  useEffect(() => {
    if (getClient.dataF) {
      setDealer(getClient.dataF)
      setValue("dealerId", getClient.dataF.dealerId)
      setValue("dealerCode", getClient.dataF.dealerCode)
      setValue("address", getClient.dataF.address)
      setValue("postalCode", getClient.dataF.postalCode)
      setValue("location", getClient.dataF.location)
      setValue("region", getClient.dataF.region)
      setValue("state", getClient.dataF.state)
      setValue("holding", getClient.dataF.holding)
      setValue("salesTax", getClient.dataF.salesTax)
      setValue("salesOrg", getClient.dataF.salesOrg)
      setValue("distributionCh", getClient.dataF.distributionCh)
      setValue("division", getClient.dataF.division)
      setValue("salesMan", getClient.dataF.salesMan)
      setValue("reference", getClient.dataF.reference)
      setValue("phoneNumber", getClient.dataF.phoneNumber)
      setValue("mobileNumber", getClient.dataF.mobileNumber)
      setValue("fax", getClient.dataF.fax)
      setValue("email", getClient.dataF.email)
      setValue("notes", getClient.dataF.notes)
    }
  }, [getClient.dataF])

  if (getClient.isError) return <ErrorComponent errorMessage={details.errors.loadFail} />

  if (!dealer && !getClient.isLoading)
    return <ErrorComponent errorMessage={details.errors.notFound} />

  if (getClient.isLoading) {
    return <Loading />
  }

  if (!utente?.permissions?.includes("menu:agencyprofile")) {
    return <ErrorComponent auth />
  }

  if (dealer) {
    return (
      <CardContainer customizeClassNames="col-11 col-sm-10 col-md-9 col-lg-8 col-xl-6">
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mb-5">
          <TitlePage Title={details.title} spacing={false} />
          <BtnVisual
            Title={details.save}
            btnStyle="fixedButtonWidth"
            onClick={async () => {
              if (submitFormElement.current) {
                submitFormElement.current?.click()
              }
            }}
            isLoading={isLoading}
            disabled={utente?.role === "D" ? false : true}
          />
        </div>
        <form
          onSubmit={handleSubmit(async (form) => {
            const result = await saveClient.fetch(form)
            if (result) {
              showNotification(
                labels?.label.notification.message.saveGeneralSuccess ?? "",
                "success"
              )
            } else {
              showNotification(saveClient.errorMessage, "error")
            }
          })}
        >
          <Input
            disabled={true}
            value={dealer?.dealerCode ?? ""}
            label={details.fields.dealerCode}
          />
          <Input
            value={dealer?.corporateName ?? ""}
            disabled={true}
            label={details.fields.corporateName}
          />
          <Input value={dealer?.address ?? ""} disabled={true} label={details.fields.adress} />
          <Input
            value={dealer?.postalCode ?? ""}
            disabled={true}
            label={details.fields.postalCode}
          />
          <Input value={dealer?.location ?? ""} disabled={true} label={details.fields.location} />
          <Input value={dealer?.region ?? ""} disabled={true} label={details.fields.region} />
          <Input value={dealer?.state ?? ""} disabled={true} label={details.fields.state} />
          <Input value={dealer?.holding ?? ""} disabled={true} label={details.fields.holding} />
          <Input value={dealer?.salesTax ?? ""} disabled={true} label={details.fields.saleTax} />
          <Input value={dealer?.salesOrg ?? ""} disabled={true} label={details.fields.saleOrg} />
          <Input
            value={dealer?.distributionCh ?? ""}
            disabled={true}
            label={details.fields.channel}
          />
          <Input value={dealer?.division ?? ""} disabled={true} label={details.fields.division} />
          <Input value={dealer?.salesMan ?? ""} disabled={true} label={details.fields.saleMan} />
          <Input value={dealer?.reference ?? ""} disabled={true} label={details.fields.reference} />
          <hr />
          <Input
            defaultValue={dealer?.mobileNumber ?? ""}
            disabled={utente?.role != "D" ? true : false}
            onChange={({ currentTarget: { value } }) => setValue("mobileNumber", value)}
            label={details.fields.telePhone}
          />
          <Input
            defaultValue={dealer?.phoneNumber ?? ""}
            disabled={utente?.role != "D" ? true : false}
            onChange={({ currentTarget: { value } }) => setValue("phoneNumber", value)}
            label={details.fields.mobilePhone}
          />
          <Input
            defaultValue={dealer?.fax ?? ""}
            disabled={utente?.role != "D" ? true : false}
            onChange={({ currentTarget: { value } }) => setValue("fax", value)}
            label={details.fields.fax}
          />
          <Input
            defaultValue={dealer?.email ?? ""}
            disabled={utente?.role != "D" ? true : false}
            onChange={({ currentTarget: { value } }) => setValue("email", value)}
            label={details.fields.email}
          />
          <Textarea
            label={details.fields.notes}
            disabled={utente?.role != "D" ? true : false}
            onChange={({ currentTarget: { value } }) => setValue("notes", value)}
            defaultValue={dealer?.notes ?? ""}
          />
          <button type="submit" className="d-none" ref={submitFormElement}></button>
        </form>
        {notification && <Notification message={notification.message} type={notification.type} />}
      </CardContainer>
    )
  }

  return <></>
}

export default Anagrafica
