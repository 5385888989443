import React, { useContext, useEffect, useState } from "react"
import CardContainer from "../../../../Common/Components/CardContainer"
import TitlePage from "../../../../Common/Structure/Title/TitlePage"
import BtnVisual from "../../../../Common/Structure/Buttons/BtnVisual"
import SearchIcon from "../../../../Common/Icons/SearchIcon"
import Plus from "../../../../Common/Icons/Plus"
import CustomTable from "../../../../Common/Structure/Tables/CustomTable"
import Cestino from "../../../../Common/Icons/Cestino"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import keycloak from "../../../../keycloack"
import { InputOverchargeNew, InputOverchargeSearch } from "../../../../Models/OverchargeInputSearch"
import Pencil from "../../../../Common/Icons/Pencil"
import { useNotification } from "../../../../Common/Components/hooks/useNotification"
import { Notification } from "../../../../Common/Components/Notification"
import usePostHttp from "../../../../Common/Functions/HTTPRequest/Post"
import useDeleteHttp from "../../../../Common/Functions/HTTPRequest/Delete"
import InputNew from "./components/inputNew"
import InputSearch from "./components/inputSearch"
import { GeneralContext } from "../../MasterPage/MasterPage"
import { LabelLan, LangArray } from "../../../../Models/LabelLan"

type Props = {}

export const defaultInputNew: InputOverchargeNew = {
  currency: "EUR",
  id: 0,
  overchargeValue: undefined,
  productCode: undefined,
  toPrice: undefined,
}

const Overcharge = (props: Props) => {
  const [isShow, setIsShow] = useState<boolean>(false)
  const [newLine, setNewLine] = useState<boolean>(false)
  const [overcharge, setOvercharge] = useState<(string | JSX.Element)[][]>([])

  const { notification, showNotification } = useNotification()

  const userTokenInfo = keycloak.tokenParsed as any

  const defaultSearch: InputOverchargeSearch = {
    SalesOrganization: userTokenInfo.salesOrganization ?? null,
    ProductType: "",
    DistributionChannel: null,
  }

  const [inputSearch, setInputSearch] = useState<InputOverchargeSearch>(defaultSearch)
  const [inputNew, setInputNew] = useState<InputOverchargeNew>(defaultInputNew)

  const { labels, utente } = useContext(GeneralContext)
  const {
    label: {
      Overcharge,
      notification: { message },
    },
  } = labels as LabelLan

  const getOvercharge = useGetHttp("order/get-overcharge")
  const saveOvercharge = usePostHttp("order/create-or-update-overcharge")
  const delOvercharge = useDeleteHttp("order/delete-overcharge")

  useEffect(() => {
    if (getOvercharge.dataF) {
      const lines = getOvercharge.dataF.map((x: any) => {
        return [
          x.productCode,
          "1 ST",
          x.fromPrice,
          x.toPrice,
          x.overchargeValue,
          x.currency,
          <div className="d-flex gap-2 align-items-center">
            <Pencil
              color="#459317"
              onClick={() => {
                setInputNew({
                  currency: x.currency,
                  id: x.id,
                  overchargeValue: x.overchargeValue,
                  productCode: x.productCode,
                  toPrice: x.toPrice,
                })
                setNewLine(true)
              }}
            />
            <Cestino
              color="#cd1231"
              onClick={async () => {
                await delOvercharge.fetch({ id: x.id })
              }}
            />
          </div>,
        ]
      })
      setOvercharge(lines)
      setIsShow(true)
    } else {
      setOvercharge([])
    }
  }, [getOvercharge.dataF])

  useEffect(() => {
    if (getOvercharge.isError) {
      showNotification(getOvercharge.errorMessage, "error")
      getOvercharge.reset()
      setNewLine(false)
      setIsShow(false)
    }
  }, [getOvercharge.isError])

  useEffect(() => {
    if (!saveOvercharge.isError) {
      if (saveOvercharge.dataF) {
        showNotification(message.saveGeneralSuccess, "success")
        getOvercharge.fetch(inputSearch, undefined, true)
      }
    } else {
      showNotification(saveOvercharge.errorMessage, "error")
    }
    saveOvercharge.reset()
  }, [saveOvercharge.dataF, saveOvercharge.isError, saveOvercharge.errorMessage])

  useEffect(() => {
    if (delOvercharge.dataF) {
      if (!delOvercharge.isError) {
        showNotification(message.saveGeneralSuccess, "success")
        getOvercharge.fetch(inputSearch, undefined, true)
      } else {
        showNotification(delOvercharge.errorMessage, "error")
      }
      delOvercharge.reset()
    }
  }, [delOvercharge.dataF])

  // if (!utente?.permissions?.includes("menu:admin-overcharge")) {
  //   return <ErrorComponent auth />
  // }

  return (
    <CardContainer customizeClassNames="col-11 col-md-10 col-xl-9">
      <TitlePage Title="Overcharge" />
      <div className="d-flex justify-content-between align-items-center w-100 flex-column flex-md-row gap-5">
        <InputSearch setInputSearch={setInputSearch} userTokenInfo={userTokenInfo} />
        <div className="d-flex flex-column gap-3">
          <BtnVisual
            Title="Ricerca"
            icon={<SearchIcon />}
            disabled={!inputSearch.DistributionChannel || !inputSearch.SalesOrganization}
            onClick={async () => {
              await getOvercharge.fetch(inputSearch, undefined, true)
            }}
          />
          {isShow && (
            <BtnVisual
              Title="Nuova Riga"
              color="#0033A1"
              onClick={() => setNewLine(!newLine)}
              icon={<Plus color="#0033A1" width={"1.5em"} height={"1.5em"} />}
            />
          )}
        </div>
      </div>
      {isShow && newLine && (
        <>
          <hr />
          <InputNew
            inputNew={inputNew}
            saveOvercharge={saveOvercharge}
            setInputNew={setInputNew}
            setNewLine={setNewLine}
            searchInput={inputSearch}
          />
        </>
      )}
      {isShow && (
        <>
          <hr />
          <div className="my-3">
            <CustomTable
              headers={[
                "Prodotto",
                "Quantità",
                "Dal Prezzo",
                "Al Prezzo",
                "Spese di trasporto",
                "Valuta",
                "",
              ]}
              rows={overcharge}
            />
          </div>
        </>
      )}
      {notification && <Notification message={notification.message} type={notification.type} />}
    </CardContainer>
  )
}

export default Overcharge
