import React, { useContext, useEffect, useState } from "react"
import CardMenu from "../../../../Common/Components/CardMenu"
import { MenuItem } from "../../../../Common/Structure/Sidebar/Sidebar"
import { GeneralContext } from "../MasterPage"
import AmministrazioneIcon from "../../../../Common/Icons/Sidebar/AmministrazioneIcon"
import OrdiniHWIcon from "../../../../Common/Icons/Sidebar/OrdiniHWIcon"
import ProfiloAziendaIcon from "../../../../Common/Icons/Sidebar/ProfiloAziendaIcon"
import RegProdGaranziaIcon from "../../../../Common/Icons/Sidebar/RegProdGaranziaIcon"
import RicambiIcon from "../../../../Common/Icons/Sidebar/RicambiIcon"
import WebReportingIcon from "../../../../Common/Icons/Sidebar/WebReportingIcon"
import { LabelLan } from "../../../../Models/LabelLan"
import keycloak from "../../../../keycloack"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import { LinkMan } from "../../../../Models/Links"

const arrayColor = ["#C7002B", "#E88C0E", "#459317", "#085BBC", "#858585", "#424242"]

const MenuHome = () => {
  const [links, setLinks] = useState<LinkMan[]>([])

  const { utente, labels } = useContext(GeneralContext)
  const {
    label: { menu },
  } = labels as LabelLan

  useEffect(() => {
    const getLinks = () => getLinksClient.fetch()
    getLinks()
  }, [])

  const getLinksClient = useGetHttp("homepagelink/get-homepagelink-by-session")

  useEffect(() => {
    if (getLinksClient.dataF) {
      setLinks(getLinksClient.dataF)
    } else {
      setLinks([])
    }
  }, [getLinksClient.dataF])

  const kcinfo = keycloak.tokenParsed as any


  const menuVoice: MenuItem[] = [
    {
      name: menu.administration.value,
      route: "/administrator",
      icon: <AmministrazioneIcon />,
      submenu: [
        {
          name: menu.administration.subMenu.link,
          route: "/administrator/link",
          role: "menu:admin-homepage-links",
        },
        {
          name: menu.administration.subMenu.link,
          route: "/administrator/overcharge",
          role: "menu:admin-overcharge",
        },
      ],
    },
    {
      name: menu.profileAgency.value,
      route: "/profilo-azienda",
      icon: <ProfiloAziendaIcon />,
      submenu: [
        {
          name: menu.profileAgency.subMenu.anagraph,
          route: "/profilo-azienda/anagrafica",
          role: "menu:agencyprofile",
        },
        {
          name: menu.profileAgency.subMenu.organizationChart,
          route: "/profilo-azienda/organigramma",
          role: "menu:destinations",
        },
        {
          name: menu.profileAgency.subMenu.deliveryPoint,
          route: "/profilo-azienda/punto-di-consegna",
          role: "menu:organigram",
        },
      ],
    },
    {
      name: menu.orderHW.value,
      route: "/ordini-hw-e-accessori",
      icon: <OrdiniHWIcon />,
      submenu: [
        {
          name: menu.orderHW.subMenu.catalogue,
          route: "/ordini-hw-e-accessori/catalogohw",
          role: "menu:catalogue",
        },
        {
          name: menu.orderHW.subMenu.listOrder,
          route: "/ordini-hw-e-accessori/lista-ordini",
          role: "menu:list-hw-order",
        },
        {
          name: menu.orderHW.subMenu.newOrder,
          route: "/ordini-hw-e-accessori/nuovo-ordine",
          role: "menu:create-hw-order",
        },
      ],
    },
    {
      name: menu.orderSpare.value,
      route: "/ricambi",
      icon: <RicambiIcon />,
      submenu: [
        {
          name: menu.orderSpare.subMenu.catalogue,
          route: "/ricambi/catalogo-ricambi",
          role: "menu:catalogue",
        },
        {
          name: menu.orderSpare.subMenu.listOrder,
          route: "/ricambi/lista-ordini",
          role: "menu:list-spare-order",
        },
        {
          name: menu.orderSpare.subMenu.newOrder,
          route: "/ricambi/nuovo-ordine",
          role: "menu:create-spare-order",
        },
      ],
    },
    {
      name: menu.listOrder.value,
      route: "/web-reporting",
      icon: <WebReportingIcon />,
      submenu: [
        {
          name: "Situazione contabile",
          route: "/web-reporting/situazione-contabile",
          role: "menu:reporting",
        },
        {
          name: "Partite aperte",
          route: "/web-reporting/partite-aperte",
          role: "menu:reporting",
        },
        {
          name: "Lista ordini inviati",
          route: "/web-reporting/lista-ordini",
          role: "menu:reporting",
        },
        {
          name: "Stato ordini",
          route: "/web-reporting/stato-ordini",
          role: "menu:reporting",
        },
        {
          name: "Consegne",
          route: "/web-reporting/consegne",
          role: "menu:reporting",
        },
        {
          name: "Fatture",
          route: "/web-reporting/fatture",
          role: "menu:reporting",
        },
        {
          name: "Estratti conto",
          route: "/web-reporting/estratti-conto",
          role: "menu:reporting",
        },
        {
          name: "Venduto - Fatturato - Spedito",
          route: "/web-reporting/venduto-fatturato-spedito",
          role: "menu:reporting",
        },
      ],
    },
    {
      name: menu.warranty.value,
      route: "/garanzia",
      icon: <RegProdGaranziaIcon />,
      submenu: [
        {
          name: menu.warranty.subMenu.registration,
          route: "/garanzia/registrazione-prodotto-per-garanzia",
          role: "menu:warranties-management",
        },
        {
          name: menu.warranty.subMenu.request,
          route: "/garanzia/richiesta-parti-garanzia",
          role: "menu:warranties-management",
        },
      ],
    },
  ]

  return (
    <div className=" w-100 my-5 mx-1 mx-md-5 d-flex justify-content-center">
      <div className="d-flex flex-column gap-5 menuHome">
        {(utente?.role === "D" ||
          utente?.permissions?.includes("menu:warranty-email")) && (
          <div
            className="w-100 rounded-2 px-3 px-sm-5 py-3 bg-white"
            style={{
              border: "0px solid gray",
              borderTop: `5px solid ${arrayColor[0]}`,
              height: "auto",
              boxShadow: "0 2px 3px 1px #c0bebe",
            }}
          >
            <div className="d-flex flex-column gap-2 w-100">
              <div className="d-flex gap-4">
                <div style={{ color: arrayColor[0], fontSize: "1.2rem" }}>
                  <b>Link</b>
                </div>
              </div>
              <div className="d-flex flex-column mx-2 max-sm-5">
                {utente.role === "D" && links.map((x, i) => {
                  if (
                    new Date(x.dateStart ?? "") < new Date() &&
                    new Date() < new Date(x.dateEnd ?? "")
                  )
                    return (
                      <a href={x.link} key={i} target={x.target}>
                        {x.message}
                      </a>
                    )
                })}
                {utente?.permissions?.includes("menu:warranty-email") && (
                  <a
                    href={`mailto:<linkKC>?subject=Dealer: ${kcinfo.sapCode} Data: ${
                      new Date().toISOString().split("T")[0]
                    }`}
                  >
                    {labels?.label.linkPage.list.sendWarranty}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
        {menuVoice.map((x, i) => {
          if (x.submenu.some((y) => utente?.permissions?.includes(y.role))) {
            return (
              <CardMenu
                title={x.name}
                color={arrayColor[i]}
                url={x.submenu[0]?.route ?? ""}
                description={x.submenu[0]?.name ?? ""}
                key={i}
                icon={x.icon}
              />
            )
          } else {
            return null
          }
        })}
      </div>
    </div>
  )
}

export default MenuHome
