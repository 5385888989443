import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Input from "../../../../Common/Structure/Input/Input"
import BtnCheck from "../../../../Common/Structure/Buttons/BtnCheck"
import SimpleSelect from "../../../../Common/Structure/Select/SimpleSelect"
import useGetHttp from "../../../../Common/Functions/HTTPRequest/Get"
import { ConsegnaIndirizzo } from "../../../../Models/ConsegnaIndirizzo"
import { RichiestaPartiGaranzia } from "../../../../Models/RichiestaPartiGaranzia"
import Textarea from "../../../../Common/Structure/Textarea/Textarea"
import ListaGaranzie from "./components/Lista"
import Loading from "../../../../Common/Components/Loader"
import { useNotification } from "../../../../Common/Components/hooks/useNotification"
import { Notification } from "../../../../Common/Components/Notification"
import CardContainer from "../../../../Common/Components/CardContainer"
import TitlePage from "../../../../Common/Structure/Title/TitlePage"
import { errorNotDefault } from "../../../../Common/defaultValue/ErrorNot"
import { ErrorNotification } from "../../../../Models/ErrorNotification"
import ErrorComponent from "../../../../Common/Components/ErrorComponent"
import { LabelLan } from "../../../../Models/LabelLan"
import { GeneralContext } from "../../MasterPage/MasterPage"

type Props = {}

const ListaPartiGaranzia = (props: Props) => {
  const [indirizzi, setIndirizzi] = useState<ConsegnaIndirizzo[]>([])
  const [richiesta, setRichiesta] = useState<RichiestaPartiGaranzia>()
  const [ErrorNotRender, setErrorNotRender] = useState<ErrorNotification>(errorNotDefault)
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<RichiestaPartiGaranzia>({
    mode: "onBlur",
  })
  const indirizziClient = useGetHttp("order/destination/getall")
  const partiGaranzieClient = useGetHttp("warranty/get-sap-products")

  const { utente, labels } = useContext(GeneralContext)
  const {
    label: {
      warranty: { request },
    },
  } = labels as LabelLan

  const { showNotification, notification } = useNotification()

  useEffect(() => {
    indirizziClient.fetch()
  }, [])

  useEffect(() => {
    if (indirizziClient.dataF) {
      setIndirizzi(indirizziClient.dataF)
    }
  }, [indirizziClient.dataF])

  useEffect(() => {
    if (ErrorNotRender.type !== "") {
      showNotification(partiGaranzieClient.errorMessage, "error")
      setErrorNotRender(errorNotDefault)
    }
  }, [ErrorNotRender])

  if (indirizziClient.isLoading) {
    return <Loading />
  }
  if (indirizziClient.isError) {
    return (
      <ErrorComponent
        errorMessage={indirizziClient.errorMessage || labels?.label.common.loadDestFail}
      />
    )
  }

  if (!utente?.permissions?.includes("menu:warranties-management")) {
    return <ErrorComponent auth />
  }

  return (
    <CardContainer customizeClassNames="col-12 col-md-10 col-lg-10 col-xl-10 my-4">
      <TitlePage Title={request.title} />
      <form
        onSubmit={handleSubmit(async (e) => {
          const result = await partiGaranzieClient.fetch({
            productCode: e.codiceProdotto,
            serialNumber: e.codiceMatricola,
          })
          if (result) {
            setRichiesta(e)
          } else {
            setRichiesta(undefined)
            setErrorNotRender({ type: "P", count: 1 })
          }
        })}
      >
        <div className="d-md-flex flex-column flex-md-row justify-content-md-between gap-md-5">
          <div className="w-100">
            <Input
              label={request.productCode}
              type="text"
              placeholder="B00000"
              {...register("codiceProdotto", { required: true, minLength: 1 })}
              className={errors.codiceProdotto ? "invalid " : ""}
              spacing={true}
            />
            <Input
              label={request.serialNumber}
              type="text"
              placeholder="123456789"
              {...register("codiceMatricola", { required: true, minLength: 1 })}
              className={errors.codiceMatricola ? "invalid " : ""}
              spacing={true}
            />
            <SimpleSelect
              {...register("indirizziId", { required: true })}
              label={request.destinations}
              isLoading={indirizziClient.isLoading}
              defaultValue={indirizzi.length && indirizzi[0].id}
              selectStyle={errors.indirizziId ? "invalid " : ""}
            >
              <option value="">{labels?.label.common.select}</option>
              {indirizzi &&
                indirizzi.map((x: ConsegnaIndirizzo, i: number) => {
                  return (
                    <option value={x.id} key={i + "-indirizzi"}>
                      {`${x.corporateName} - ${x.address} - ${x.locality} - ${x.postalCode}`}
                    </option>
                  )
                })}
            </SimpleSelect>
          </div>
          <div className="w-100">
            <Textarea
              label={request.note}
              inputDivStyle="flex-column h-100 pb-3"
              className="w-100 h-100 rounded-3"
              {...register("note", { required: false })}
            />
          </div>
        </div>
        <BtnCheck
          Title={request.load}
          type="submit"
          disabled={!isValid}
          style={{
            minWidth: 100,
          }}
          isLoading={partiGaranzieClient.isLoading}
        />
      </form>
      {partiGaranzieClient.isLoading && <Loading />}
      {richiesta && (
        <ListaGaranzie partiGaranzie={partiGaranzieClient.dataF} richiesta={richiesta} />
      )}
      {notification && <Notification message={notification.message} type={notification.type} />}
    </CardContainer>
  )
}

export default ListaPartiGaranzia
