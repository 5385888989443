import { ELingue } from "../../../Models/Enum/ELingue"

export const getButtonStatusText = (statusId: number, lang?: string): string => {
  if (lang === "EN") {
    switch (statusId) {
      case 0:
        return "New"
      case 1:
        return "Edit"
      case 2:
        return "To Send"
      case 3:
        return "Sent"
      case 4:
        return "Confirmed"
      case 5:
        return "Error"
      case 6:
        return "Time Out"

      default:
        return "Unknown"
    }
  } else {
    switch (statusId) {
      case 0:
        return "Nuovo"
      case 1:
        return "Modifica"
      case 2:
        return "Da Inviare"
      case 3:
        return "Inviato"
      case 4:
        return "Confermato"
      case 5:
        return "Errore"
      case 6:
        return "Scaduto"

      default:
        return "IGNOTO"
    }
  }
}

export const getButtonStatusColor = (statusId: number): string => {
  switch (statusId) {
    case 0:
      return "btn-success"
    case 1:
      return "btn-success"
    case 2:
      return "btn-primary"
    case 3:
      return "btn-warning"
    case 4:
      return "btn-success"
    case 5:
      return "btn-danger"
    case 6:
      return "btn-danger"

    default:
      return "btn-secondary"
  }
}

export const getStatusText = (statusId: number): string => {
  switch (statusId) {
    case 1:
      return "In fase di Modifica"
    case 2:
      return "Ordine da Inviare"
    case 3:
      return "Ordine inviato"
    case 4:
      return "Ordine ricevuto"
    case 5:
      return "Ordine errato"
    case 6:
      return "Ordine scaduto"

    default:
      return "In fase di creazione"
  }
}
