import React, { useContext, useEffect, useState } from "react"
import useGetHttp from "../../Functions/HTTPRequest/Get"
import usePostHttp from "../../Functions/HTTPRequest/Post"
import { ResProduct } from "../../../Models/Res/ResProduct"
import { ResProductTrolley } from "../../../Models/Res/ResProductTrolley"
import useDeleteHttp from "../../Functions/HTTPRequest/Delete"
import { GeneralContext } from "../../../Components/UI/MasterPage/MasterPage"

const useTrolley = () => {
  const [trolleyList, setTrolleyList] = useState<number>(0)
  const [trolleyListRes, setTrolleyListRes] = useState<ResProductTrolley[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const trolleyLoadClient = useGetHttp("trolley/getall")
  const trolleySaveClient = usePostHttp("trolley/save")

  const trolleyDeleteClient = useDeleteHttp("trolley/delete")
  const trolleyDeleteAllClient = useGetHttp("trolley/clear")

  const idImpUser = localStorage.getItem("IMP_USER")

  useEffect(() => {
    if (trolleyLoadClient.dataF) {  
      setTrolleyListRes(trolleyLoadClient.dataF)
      setTrolleyList(trolleyLoadClient.dataF.length)
    }
  }, [trolleyLoadClient.dataF])

  useEffect(() => {
    const time = setTimeout(async () => {
      debugger
      await loadTrolley()
      setIsLoading(false)
    }, 1000)
    return () => clearTimeout(time)
  }, [trolleyList, idImpUser])

  const addProduct = (product: ResProduct) => {
    setIsLoading(true)
    setTrolleyList((prev) => prev + 1)

    let data: Object = { userType: "A" }
    if (!localStorage.getItem("IMP_USER")) {
      data = { userType: "D" }
    } else {
      data = { ...data, dealerId: JSON.parse(localStorage.getItem("IMP_USER") ?? "").id ?? null }
    }
    trolleySaveClient.fetch({
      trolleyId: 0,
      productId: product.productId,
      productCode: product.productCode,
      quantita: 1,
      ...data
    }, undefined, true)
  }

  const deleteProduct = (trolleyId: number) => {
    setIsLoading(true)
    setTrolleyList((prev) => prev - 1)
    trolleyDeleteClient.fetch({ trolleyId: trolleyId })
    setTrolleyListRes((prev) => {
      return prev.filter((x) => x.trolleyId != trolleyId)
    })
  }

  const clearTrolley = (type: string | null) => {
    setIsLoading(true)
    setTrolleyList(0)
    setTrolleyListRes([])
    trolleyDeleteAllClient.fetch({ type: type })
  }

  const loadTrolley = async () => {
    setIsLoading(true)
    let data: Object = { userType: "A" }
    if (!localStorage.getItem("IMP_USER")) {
      data = { userType: "D" }
    } else {
      data = { ...data, dealerId: JSON.parse(localStorage.getItem("IMP_USER") ?? "").id ?? null }
    }
    trolleyLoadClient.fetch(data, undefined)
  }

  return {
    trolleyListRes,
    isLoading: isLoading,
    isError: trolleyLoadClient.isError || trolleySaveClient.isError,
    deleteProduct,
    addProduct,
    loadTrolley,
    clearTrolley,
    nProdotti: trolleyList,
  }
}

export default useTrolley
