import React from "react";

type CustomTableProps = {
  headers: string[];
  rows: any[][];
  children?: React.ReactNode;
  classNameBox?: string
};

const CustomTable: React.FC<CustomTableProps> = ({
  headers,
  rows,
  children,
  classNameBox
}) => {
  return (
    <div className={`table-responsive ${classNameBox}`}>
      <table className="table">
        <thead>
          <tr className="thead-tr">
            {headers.map((header, index) => (
              <th key={index} scope="col" className="text-start">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="text-start">{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {children}
    </div>
  );
};

export default CustomTable;
