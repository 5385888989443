import CardContainer from "../../../Common/Components/CardContainer"
import TitlePage from "../../../Common/Structure/Title/TitlePage"
import { Notification } from "../../../../src/Common/Components/Notification"
import { useContext } from "react"
import { useNotification } from "../../../Common/Components/hooks/useNotification"
import useGetHttp from "../../../Common/Functions/HTTPRequest/Get"
import { GeneralContext } from "../MasterPage/MasterPage"

const OrderStatusReport = () => {
  const ctx = useContext(GeneralContext)
  const { notification, showNotification } = useNotification()
  const reportClient = useGetHttp("")
  return (
    <div className="col-12 col-md-11 col-lg-11 col-xl-10">
      <CardContainer customizeClassNames="d-md-flex">
        <TitlePage Title={"Stato Ordini"} />
      </CardContainer>
      {notification && <Notification message={notification.message} type={notification.type} />}
    </div>
  )
}

export default OrderStatusReport
