import React, { useContext } from "react"
import CardContainer from "./CardContainer"
import { Link, useNavigate } from "react-router-dom"
import LogoSvg from "../Icons/Logo"
import BtnVisual from "../Structure/Buttons/BtnVisual"
import BoxArrowInRight from "../Icons/BoxArrowInRight"
import { reloadTimer } from "../Functions/Util/reloadTime"
import { GeneralContext } from "../../Components/UI/MasterPage/MasterPage"
import keycloak from "../../keycloack"

type props = {
  errorMessage?: string
  page?: boolean
  lang?: string
  action?: boolean
  impersinificaton?: boolean
  logo?: boolean
  logout?: boolean
  auth?: boolean
}

const ErrorComponent = ({
  errorMessage,
  page,
  lang,
  action = true,
  impersinificaton,
  logo,
  logout,
  auth,
}: props) => {
  const nav = useNavigate()

  const { labels } = useContext(GeneralContext)

  if (page) {
    return (
      <CardContainer>
        <div className="d-flex align-items-center flex-column">
          {logo && (
            <div>
              <LogoSvg onClick={() => nav("/")} className="p-5" />
            </div>
          )}
          <h3>{lang ? "Page not found." : "Pagina non trovata."}</h3>
          <br />
          <BtnVisual
            Title={lang ? "Back to Menu" : "Torna al Menu"}
            onClick={() => nav("/")}
            icon={<BoxArrowInRight />}
          />
        </div>
      </CardContainer>
    )
  }

  if (impersinificaton) {
    return (
      <CardContainer>
        <div className="d-flex align-items-center flex-column">
          {logo && (
            <div>
              <LogoSvg onClick={() => nav("/")} className="p-5" />
            </div>
          )}
          <h3>
            {labels?.languageCode !== "IT"
              ? "You must select a Dealer"
              : "Devi selezionare un delaer"}
          </h3>
          <br />
          <p>{errorMessage}</p>
        </div>
      </CardContainer>
    )
  }

  if (auth) {
    return (
      <CardContainer>
        <div className="d-flex align-items-center flex-column">
          <div>
            <LogoSvg onClick={() => nav("/")} className="p-5" />
          </div>
          <h3>
            {labels?.languageCode !== "IT"
              ? "You are not authorized to this page"
              : "Non sei autorizzato ad accedere alla pagina"}
          </h3>
          <br />
          <p>{errorMessage}</p>
        </div>
      </CardContainer>
    )
  }

  return (
    <CardContainer>
      <div className="d-flex align-items-center flex-column">
        {logo && (
          <div>
            <LogoSvg onClick={() => nav("/")} className="p-5" />
          </div>
        )}
        <h3>
          {labels?.languageCode === "EN" || lang === "EN"
            ? "An Error occured!"
            : "è".toUpperCase() + " avvenuto un errore!"}
        </h3>
        <br />
        <p>{errorMessage}</p>
        {action && (
          <div className="d-flex gap-3 my-4">
            <BtnVisual
              Title={labels?.languageCode === "EN" || lang === "EN" ? "Reload" : "Ricarica"}
              onClick={() => reloadTimer(0)}
              color="#0033A1"
            />
            <BtnVisual
              Title={
                labels?.languageCode === "EN" || lang === "EN" ? "Back to Menu" : "Torna al Menu"
              }
              onClick={() => nav("/")}
              icon={<BoxArrowInRight />}
            />
          </div>
        )}
        {logout && (
          <BtnVisual
            Title={"Logout"}
            onClick={() => keycloak.logout()}
            icon={<BoxArrowInRight />}
          />
        )}
      </div>
    </CardContainer>
  )
}

export default ErrorComponent
