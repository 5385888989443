import React, { useEffect, useState } from "react"
import Input from "../../../../../Common/Structure/Input/Input"
import SimpleSelect from "../../../../../Common/Structure/Select/SimpleSelect"
import BtnVisual from "../../../../../Common/Structure/Buttons/BtnVisual"
import Cestino from "../../../../../Common/Icons/Cestino"
import SpuntaIcon from "../../../../../Common/Icons/Spunta"
import {
  InputOverchargeNew,
  InputOverchargeSearch,
} from "../../../../../Models/OverchargeInputSearch"
import { FetchDataState } from "../../../../../Models/Generic"
import { defaultInputNew } from ".."
import useGetHttp from "../../../../../Common/Functions/HTTPRequest/Get"

type Props = {
  setInputNew: React.Dispatch<React.SetStateAction<InputOverchargeNew>>
  inputNew: InputOverchargeNew | undefined
  setNewLine: React.Dispatch<React.SetStateAction<boolean>>
  saveOvercharge: FetchDataState
  searchInput: InputOverchargeSearch
}

const InputNew = ({ inputNew, setInputNew, setNewLine, saveOvercharge, searchInput }: Props) => {
  const [currencys, setCurrencys] = useState<(string | null)[]>([])
  const currencyClient = useGetHttp("product/getall-currency")

  useEffect(() => {
    const init = async () => {
      await currencyClient.fetch()
    }
    init()
  }, [])

  useEffect(() => {
    if(currencyClient.dataF) {
      setCurrencys(currencyClient.dataF)
    }
  }, [currencyClient.dataF])

  return (
    <div>
      <div>
        <Input
          label="Codice Prodotto"
          value={inputNew?.productCode}
          inputDivStyle="align-items-center"
          onChange={({ currentTarget: { value } }) => {
            setInputNew((prev) => {
              return { ...prev, productCode: value }
            })
          }}
        />
        <Input
          label="Al Prezzo"
          value={inputNew?.toPrice}
          inputDivStyle="align-items-center"
          onChange={({ currentTarget: { value } }) => {
            setInputNew((prev) => {
              const cleanedValue = value.replace(/\D/g, "")
              return { ...prev, toPrice: +cleanedValue }
            })
          }}
        />
        <Input
          label="Spese di trasporto"
          type="number"
          value={inputNew?.overchargeValue}
          inputDivStyle="align-items-center"
          onChange={({ currentTarget: { value } }) => {
            setInputNew((prev) => {
              return { ...prev, overchargeValue: +value }
            })
          }}
        />
        <SimpleSelect
          label="Valuta"
          selectDivStyle="align-items-center"
          style={{ maxWidth: 200 }}
          value={inputNew?.currency}
          onChange={({ currentTarget: { value } }) => {
            setInputNew((prev) => {
              return { ...prev, currency: value }
            })
          }}
        >
          {currencys
            .filter((c) => c !== null)
            .map((c) => (
              <option value={c!} key={c}>{c}</option>
            ))}
        </SimpleSelect>
      </div>
      <div className="d-flex gap-3">
        <BtnVisual
          Title="Salva"
          onClick={async () => {
            let newLine = inputNew
            if (!inputNew?.id && newLine) {
              newLine.id = 0
            }
            setNewLine(false)
            await saveOvercharge.fetch({ ...inputNew, ...searchInput }, undefined, true)
            setInputNew(defaultInputNew)
          }}
          icon={<SpuntaIcon width={"1.5em"} height={"1.5em"} />}
        />
        <BtnVisual
          Title="Cancella"
          color="#cd1231"
          onClick={() => {
            setInputNew(defaultInputNew)
            setNewLine(false)
          }}
          icon={<Cestino width={"1.3em"} height={"1.3em"} color="#cd1231" />}
        />
      </div>
    </div>
  )
}

export default InputNew
