import React, { useContext } from "react"
import BtnSave from "../../../../../Common/Structure/Buttons/BtnSave"
import SimpleSelect from "../../../../../Common/Structure/Select/SimpleSelect"
import Input from "../../../../../Common/Structure/Input/Input"
import { useForm } from "react-hook-form"
import { NewUserOG } from "../../../../../Models/NuovoUtenteOG"
import BtnCheck from "../../../../../Common/Structure/Buttons/BtnCheck"
import CardContainer from "../../../../../Common/Components/CardContainer"
import { GeneralContext } from "../../../MasterPage/MasterPage"
import { LabelLan } from "../../../../../Models/LabelLan"

type Props = {
  saveUser: (form: NewUserOG, edit: boolean) => void
  setShowNewUser: React.Dispatch<React.SetStateAction<boolean>>
  id?: NewUserOG | undefined
}

const NewUtenteOrganigramma = ({ saveUser, setShowNewUser, id }: Props) => {
  const { labels } = useContext(GeneralContext)
  const {
    label: {
      agencyProfile: {
        organizationChart: { newUser },
      },
    },
  } = labels as LabelLan

  const ruoli = [
    {
      id: 1,
      nome: newUser.roleSelection.roles.tec,
    },
    {
      id: 2,
      nome: newUser.roleSelection.roles.com,
    },
    {
      id: 3,
      nome: newUser.roleSelection.roles.amm,
    },
  ]

  const {
    formState: { errors, isValid },
    register,
    reset,
    getValues,
  } = useForm<NewUserOG>({ mode: "onBlur" })

  const saveUserHandler = () => {
    if (!id) {
      saveUser(getValues(), false)
    } else {
      saveUser(getValues(), true)
    }
    reset()
  }

  return (
    <div className="col-12">
      <CardContainer customizeClassNames="mx-0 mx-md-5 px-4 px-md-5 ">
        <div className="d-flex mt-5">
          <div className="col-12">
            <Input
              label={newUser.name}
              {...register("nome", { required: true, minLength: 1 })}
              defaultValue={id?.nome}
              className={errors.nome ? "invalid " : ""}
              placeholder="Mario"
            />
            <Input
              label={newUser.surname}
              {...register("cognome", { required: true, minLength: 1 })}
              defaultValue={id?.cognome}
              className={errors.cognome ? "invalid " : ""}
              placeholder="Rossi"
            />
            <Input
              label={newUser.phone}
              {...register("telefono", { required: false })}
              defaultValue={id?.telefono}
              className={errors.telefono ? "invalid " : ""}
              placeholder="0000000000"
            />
            <Input
              label={newUser.mobilePhone}
              {...register("cellullare", { required: false })}
              defaultValue={id?.cellullare}
              className={errors.cellullare ? "invalid " : ""}
              placeholder="0000000000"
            />
            <Input
              label={newUser.fax}
              {...register("fax", { required: false })}
              defaultValue={id?.fax}
              className={errors.fax ? "invalid " : ""}
              placeholder="0000000000"
            />
            <Input
              label={newUser.email}
              type="email"
              {...register("email", { required: false })}
              defaultValue={id?.email}
              className={errors.email ? "invalid " : ""}
              placeholder="mariorossi@olivetti.it"
            />
            <SimpleSelect
              label={newUser.roleSelection.value}
              {...register("ruolo", { required: true })}
              defaultValue={id?.ruolo}
              selectStyle={`${errors.ruolo ? "invalid" : ""}`}
            >
              {ruoli.map((ruolo) => (
                <option key={ruolo.id} value={ruolo.id}>
                  {ruolo.nome}
                </option>
              ))}
            </SimpleSelect>
            <div className="my-4 d-flex gap-2">
              <BtnCheck
                onClick={() => {
                  saveUserHandler()
                }}
                Title={newUser.save}
                disabled={!isValid}
              />
              <BtnSave onClick={() => setShowNewUser(false)} Title={newUser.close} />
            </div>
          </div>
        </div>
      </CardContainer>
    </div>
  )
}

export default NewUtenteOrganigramma
