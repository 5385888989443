import { HTMLAttributes, ReactNode } from "react";

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Container = ({ children }: Props) => {
  return (
    <>
      <div className="d-flex justify-content-center mx-3 mx-md-0 mx-xl-0">
        {/* <div className="col-12 col-md-10 col-lg-8 col-xl-6"> */}
        {children}
        {/* </div> */}
      </div>
    </>
  );
};

export default Container;
