import React, { useContext, useState } from "react"
import BtnCheck from "../../../../../../../../Common/Structure/Buttons/BtnCheck"
import TitlePage from "../../../../../../../../Common/Structure/Title/TitlePage"
import { ReqProdotto } from "../../../../../../../../Models/Product"
import Input from "../../../../../../../../Common/Structure/Input/Input"
import { LabelLan } from "../../../../../../../../Models/LabelLan"
import { GeneralContext } from "../../../../../../MasterPage/MasterPage"

type Props = {
  onAddProdotti: (a: ReqProdotto[], reload: boolean) => void
}

const OrdineManuale = ({ onAddProdotti }: Props) => {
  const [codice, setCodice] = useState<number | string>(0)
  const [errore, setErrore] = useState<string>("")

  const gContex = useContext(GeneralContext)
  const {
    label: {
      HWOrder: { newOrder },
      notification: { message },
    },
  } = gContex.labels as LabelLan

  const prodottoAdd = () => {
    if (codice) {
      onAddProdotti([{ productCode: codice.toString(), productQty: 1 }], false)
      setErrore("")
    } else {
      setErrore(message.erroProdCodeCompilation)
    }
  }

  return (
    <div className="col-12 w-100 mt-5">
      <div className="w-100 d-flex justify-content-between flex-column flex-lg-row">
        <Input
          label={newOrder.body.list.productCode}
          type="text"
          placeholder="123"
          className="w-50"
          onChange={(e) => setCodice(e.currentTarget.value)}
          inputDivStyle="align-items-center flex-fill gap-3"
          justify="justify-content-start"
        />
        {errore && <p className="text-danger">{errore}</p>}
        <BtnCheck
          onClick={prodottoAdd}
          Title={newOrder.body.addProducts}
          btnStyle="align-self-center "
        />
      </div>
    </div>
  )
}

export default OrdineManuale
