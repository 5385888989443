import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./App.css"
import keycloak from "./keycloack"
import { ErrorBoundary } from "react-error-boundary"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

keycloak.onTokenExpired = () => {
  console.warn("Token Validity Expired")
  keycloak.updateToken(100).then(async () => {
    if (keycloak.token) {
      let acessToken
      await fetch(`${process.env.REACT_APP_BASE_KC_URL}/realms/oliord/protocol/openid-connect/token`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: "grant_type=urn:ietf:params:oauth:grant-type:uma-ticket&audience=oliord-backend",
      })
        .then((response) => response.json())
        .then((data) => (acessToken = data.access_token))
        .catch((error) => console.error(error))

      localStorage.setItem("ATK", acessToken ?? "")
    }
  })
}

keycloak
  .init({
    onLoad: "login-required",
    checkLoginIframe: false,
    enableLogging: true,
  })
  .then(async (authenticated) => {
    if (authenticated) {
      let acessToken

      await fetch(`${process.env.REACT_APP_BASE_KC_URL}/realms/oliord/protocol/openid-connect/token`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: "grant_type=urn:ietf:params:oauth:grant-type:uma-ticket&audience=oliord-backend",
      })
        .then((response) => response.json())
        .then((data) => (acessToken = data.access_token))
        .catch((error) => console.error(error))

      localStorage.setItem("ATK", acessToken ?? "")
      localStorage.setItem("IFL", "1")

      root.render(
        <React.StrictMode>
          <ErrorBoundary fallback={<p>Something went wrong. Try again later.</p>}>
            <App />
          </ErrorBoundary>
        </React.StrictMode>
      )
    }
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
