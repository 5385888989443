import React, { useContext, useEffect, useState } from "react"
import Textarea from "../../../../../../Common/Structure/Textarea/Textarea"
import useWindowWidth from "../../../../../../Common/Components/hooks/useScreenWidth"
import { ReqOrderHeaderDto } from "../../../../../../Models/Req/ReqOrderHeader"
import InputFieldsOrdine from "./Components/Inputs"
import { useForm } from "react-hook-form"
import { ListStateTestataOrdine } from "../../../../../../Models/ListStateTestataOrdine"
import FlagInputs from "./Components/FlagsInput"
import { FetchDataState } from "../../../../../../Models/Generic"
import { LabelLan } from "../../../../../../Models/LabelLan"
import { GeneralContext } from "../../../../MasterPage/MasterPage"

type Props = {
  setOrdine: React.Dispatch<React.SetStateAction<ReqOrderHeaderDto>>
  ordine: ReqOrderHeaderDto
  indirizziClient: FetchDataState
  terminiPagClient: FetchDataState
}

const TestataOrdineHW = ({ ordine, setOrdine, indirizziClient, terminiPagClient }: Props) => {
  const screenWidth = useWindowWidth()
  const [orderType, setOrderType] = useState(ordine.flagDelivery)
  const { labels } = useContext(GeneralContext)
  const {
    label: {
      HWOrder: {
        newOrder: { head },
      },
    },
  } = labels as LabelLan

  const {
    register,
    formState: { errors, isValid },
    getValues,
    setValue,
    handleSubmit,
  } = useForm<ListStateTestataOrdine>({ mode: "onBlur" })

  useEffect(() => {
    if (ordine.orderId > 0) {
      setValue("nConsessionario", ordine.dealerOrderCode)
      setValue(
        "dataConsessionario",
        new Date(ordine.dealerOrderDate ? ordine.dealerOrderDate : new Date())
          .toISOString()
          .split("T")[0]
      )
      setValue("idDestinazione", +ordine.destinationId)
      setValue("idPagamento", +ordine.termPaymentId)
      setValue("flagDelivery", ordine.flagDelivery)
      setValue("notes", ordine.notes)
      setValue("tipoOrdine", ordine.orderType ?? "WWW")
    }
  }, [ordine])

  useEffect(() => {
    setOrdine((prev) => {
      return { ...prev, flagDelivery: orderType }
    })
  }, [orderType])

  return (
    <form
      onChange={() => {
        setOrdine((prev) => {
          return {
            ...prev,
            destinationId: getValues("idDestinazione").toString(),
            termPaymentId: getValues("idPagamento").toString(),
            dealerOrderCode: getValues("nConsessionario") ?? "",
            dealerOrderDate: getValues("dataConsessionario") ?? "",
            notes: getValues("notes") ?? "",
            orderType: getValues("tipoOrdine"),
          }
        })
      }}
      className="d-flex justify-content-between flex-column flex-md-row flex-wrap"
    >
      <div className="col-12 d-flex flex-column justify-content-around flex-md-row my-3 gap-5">
        <div className="col-12 col-md-7" style={{ width: screenWidth > 767 ? "45%" : "" }}>
          <InputFieldsOrdine
            setOrdine={setOrdine}
            ordine={ordine}
            errors={errors}
            getValues={getValues}
            register={register}
            setValue={setValue}
            indirizziClient={indirizziClient}
            terminiPagClient={terminiPagClient}
          />
        </div>
        <div className="col-12 col-md-5">
          <Textarea
            label={head.note}
            inputDivStyle="flex-column h-100 pb-3"
            className="w-100 h-100 rounded"
            defaultValue={ordine?.notes}
            {...register("notes", { required: false })}
          />
        </div>
      </div>
      <FlagInputs
        setOrdine={setOrdine}
        ordine={ordine}
        errors={errors}
        orderType={orderType}
        setOrderType={setOrderType}
        register={register}
        setValue={setValue}
      />
    </form>
  )
}

export default TestataOrdineHW
