import React, { useContext, useEffect, useRef, useState } from "react"
import Input from "../../../../../../../../Common/Structure/Input/Input"
import { UseFormRegister, UseFormGetValues, UseFormSetValue, FieldErrors } from "react-hook-form"
import FileUploader from "../../../../../../../../Common/Components/Files/FileUploader"
import { ListStateTestataOrdine } from "../../../../../../../../Models/ListStateTestataOrdine"
import { ReqOrderHeaderDto } from "../../../../../../../../Models/Req/ReqOrderHeader"
import { GeneralContext } from "../../../../../../MasterPage/MasterPage"
import BtnVisual from "../../../../../../../../Common/Structure/Buttons/BtnVisual"
import UploadIcon from "../../../../../../../../Common/Icons/DownloadIcon"
import { LabelLan } from "../../../../../../../../Models/LabelLan"
import { OrderTypeProps } from "../.."

type Props = {
  ordine: ReqOrderHeaderDto
  setOrdine: React.Dispatch<React.SetStateAction<ReqOrderHeaderDto>>
  register: UseFormRegister<ListStateTestataOrdine>
  errors: FieldErrors<ListStateTestataOrdine>
  setValue: UseFormSetValue<ListStateTestataOrdine>
  setOrderType: React.Dispatch<React.SetStateAction<OrderTypeProps>>
  orderType: OrderTypeProps
}

const FlagInputs = ({
  errors,
  ordine,
  register,
  setOrdine,
  setValue,
  orderType,
  setOrderType,
}: Props) => {
  const { features, utente, labels } = useContext(GeneralContext)
  const {
    label: {
      HWOrder: {
        newOrder: { head },
      },
    },
  } = labels as LabelLan

  const [validateDoc, setValidateDoc] = useState(false)

  const uploadFile = (a: string) => {
    setOrdine((prev) => {
      return { ...prev, guidOrder: a }
    })
    setValue("guid", a)
  }

  useEffect(() => {
    if (ordine.orderType === "PAL" && ordine.guidOrder != null) {
      setValidateDoc(true)
    } else {
      setValidateDoc(false)
    }
  }, [ordine.orderType])


  return (
    <div
      className="col-12 border d-flex flex-column rounded-5 my-5"
      style={{ backgroundColor: "#F2F2F2", boxShadow: "0 2px 5px gray" }}
    >
      <div className="d-flex align-items-center flex-column flex-md-row mh-25 gap-0 gap-md-5 py-2 px-4 px-md-5">
        <p className="p-0 m-0 col-md-2 col-12 text-center text-md-start">{head.orderType.value}</p>
        <div className="d-flex flex-column flex-md-row align-items-center flex-fill">
          <div className="d-flex gap-5 w-100 justify-content-start justify-content-md-start">
            <Input
              label={head.orderType.types.normal}
              type="radio"
              value="WWW"
              {...register("tipoOrdine", { required: true })}
              checked={ordine?.orderType ? ordine?.orderType === "WWW" : true}
              className={errors.tipoOrdine ? "invalid" : ""}
              inputDivStyle="gap-2"
            />
            <Input
              label={head.orderType.types.pal}
              type="radio"
              value="PAL"
              {...register("tipoOrdine", { required: true })}
              checked={ordine?.orderType ? ordine?.orderType === "PAL" : false}
              className={errors.tipoOrdine ? "invalid" : ""}
              inputDivStyle="gap-2"
            />
            {utente?.role === "A" && (
              <Input
                label={head.orderType.types.net}
                type="radio"
                value="NET"
                {...register("tipoOrdine", { required: true })}
                checked={ordine?.orderType ? ordine?.orderType === "NET" : false}
                className={errors.tipoOrdine ? "invalid" : ""}
                inputDivStyle="gap-2"
              />
            )}
          </div>
          <BtnVisual
            btnStyle="BtnVisualOrderType h-25"
            dire={true}
            disabled={ordine.orderId === 0}
            icon={
              <UploadIcon
                color={ordine.orderId != 0 ? (validateDoc ? "#ff0000" : `#E88C0E`) : "#a9a9a9"}
              />
            }
            color={ordine.orderId != 0 ? (validateDoc ? "#ff0000" : `#E88C0E`) : "#a9a9a9"}
            onClick={
              features?.setModal
                ? () => {
                    features?.setModal((prev) => {
                      return {
                        ...prev,
                        isVisible: true,
                        title: head.fileModal,
                        children: <FileUploader onFileUpload={uploadFile} ordine={ordine} />,
                        reload: false,
                      }
                    })
                  }
                : undefined
            }
            Title={
              ordine.statusId == 0
                ? ordine?.guidOrder == ""
                  ? head.fileUpload
                  : head.fileRead
                : ordine.statusId > 0 && ordine.statusId < 3
                ? ordine.guidOrder == "00000000-0000-0000-0000-000000000000"
                  ? head.fileUpload
                  : head.fileRead
                : ordine.statusId >= 3
                ? ordine.guidOrder == "00000000-0000-0000-0000-000000000000"
                  ? head.fileRead
                  : head.fileEmpy
                : ""
            }
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-column flex-md-row mh-25 gap-0 gap-md-5 py-2 pt-5 pt-md-2 px-4 px-md-5">
        <p className="p-0 m-0 col-md-2 col-12 text-center text-md-start">
          {head.deliveryType.value}
        </p>
        <div className="d-flex gap-5 align-items-sm-center justify-content-start">
          <Input
            label={head.deliveryType.types.partial}
            type="radio"
            name="flagDelivery"
            onChange={() => setOrderType({ auth: true, value: true })}
            className={errors.flagDelivery ? "invalid" : ""}
            inputDivStyle="gap-2"
            checked={orderType.value}
          />
          <Input
            label={head.deliveryType.types.full}
            type="radio"
            name="flagDelivery"
            onChange={() => setOrderType({ auth: true, value: false })}
            className={errors.flagDelivery ? "invalid" : ""}
            inputDivStyle="gap-2"
            checked={!orderType.value}
          />
        </div>
      </div>
      <div
        className="d-flex flex-column mh-25 gap-2 py-2 pt-5 pt-md-2 px-4 px-md-5"
        style={{ maxWidth: 310 }}
      >
        <Input
          label={head.blockBill}
          type="checkbox"
          onChange={({ currentTarget: { checked } }) =>
            setOrdine((prev) => {
              return { ...prev, blockBill: checked }
            })
          }
          checked={ordine.blockBill}
        />
        <Input
          label={head.blockDelivery}
          type="checkbox"
          onChange={({ currentTarget: { checked } }) =>
            setOrdine((prev) => {
              return { ...prev, blockDelivery: checked }
            })
          }
          checked={ordine.blockDelivery}
        />
      </div>
    </div>
  )
}

export default FlagInputs
