import { useContext, useEffect, useState } from "react"
import BtnCheck from "../../Common/Structure/Buttons/BtnCheck"
import CustomTable from "../../Common/Structure/Tables/CustomTable"
import { ReqProdotto } from "../../Models/Product"
import useGetHttp from "../../Common/Functions/HTTPRequest/Get"
import ErrorComponent from "../../Common/Components/ErrorComponent"
import { ResAddProductCampaign } from "../../Models/Res/ResAddProductCampaign"
import { GeneralContext } from "../UI/MasterPage/MasterPage"
import { LabelLan } from "../../Models/LabelLan"

type Props = {
  onAddProdotto: (pro: ReqProdotto[], reload: boolean) => void
  codiciCompagnia: string[]
}

type ErrorOb = {
  isError: boolean
} & Error

const tableHeaders = ["Codice Prodotto", "Descrizione Prodotto", "Prezzo", "Quantità"]

const CampagneList = ({ onAddProdotto, codiciCompagnia }: Props) => {
  const [listaProdotti, setListaProdotti] = useState<ResAddProductCampaign[]>([])
  const [error, setError] = useState<ErrorOb>()
  const prodottiClient = useGetHttp("product/get-with-campaign")

  const { labels } = useContext(GeneralContext)
  const {
    label: {
      modals: { campaign },
    },
  } = labels as LabelLan

  useEffect(() => {
    const caricaProdotti = async () => {
      if (codiciCompagnia) {
        const param = "?" + codiciCompagnia.map((x) => `campaigns=${x}`).join("&")
        const result = await prodottiClient.fetch(undefined, param)
        if (!result) {
          setError({
            isError: true,
            message: campaign.error,
            name: "Compagnia Errore",
          })
        }
      }
    }

    caricaProdotti()
  }, [])

  useEffect(() => {
    if (prodottiClient.dataF) {
      setListaProdotti(prodottiClient.dataF)
    }
  }, [prodottiClient.dataF])

  if (error?.isError) {
    return <ErrorComponent errorMessage={error.message} />
  }

  return (
    <div className="d-flex flex-column w-100 gap-1">
      <p>{campaign.description}</p>
      {listaProdotti.length > 0 &&
        listaProdotti.map((campagna) => (
          <div key={campagna.campaignId}>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <div>
                <h5>{campaign.insert.replace("###", campagna.campaignId.toString())}</h5>
                {campagna.description && <p>{campagna.description}</p>}
              </div>
              <BtnCheck
                Title={campaign.toInsert}
                btnStyle="w-25 h-25"
                onClick={() => {
                  onAddProdotto(
                    campagna.products.map((prod) => {
                      return {
                        productId: prod.productId,
                        productQty: prod.minimoOrdine != 0 ? prod.minimoOrdine : 1,
                        campaignId: campagna.campaignId,
                      }
                    }) ?? [],
                    false
                  )
                }}
              />
            </div>

            {campagna.products.length > 0 && (
              <CustomTable
                headers={tableHeaders}
                classNameBox="mt-2"
                rows={campagna.products.map((prod) => [
                  prod.productId,
                  prod.productCode,
                  prod.productPrice,
                  prod.minimoOrdine ?? 1,
                ])}
              />
            )}
            <hr />
          </div>
        ))}
    </div>
  )
}

export default CampagneList
