import { ReactNode } from "react"

type Props = {
  customizeClassNames?: string
  children: ReactNode
}

const CardContainer = ({ children, customizeClassNames }: Props) => {
  return (
    <div className={"card border-0 cardContainer rounded-5 shadow-lg" + ` ${customizeClassNames}`}>
      {children}
    </div>
  )
}

export default CardContainer
