import { useNavigate } from "react-router-dom"
import { FetchDataState } from "../../../../../../Models/Generic"
import BtnCheck from "../../../../../../Common/Structure/Buttons/BtnCheck"
import { ReqOrderHeaderDto } from "../../../../../../Models/Req/ReqOrderHeader"
import Cestino from "../../../../../../Common/Icons/Cestino"
import { useState, useEffect, useContext } from "react"
import { errorNotDefault } from "../../../../../../Common/defaultValue/ErrorNot"
import { ErrorNotification } from "../../../../../../Models/ErrorNotification"
import { LabelLan } from "../../../../../../Models/LabelLan"
import { GeneralContext } from "../../../../MasterPage/MasterPage"

type Props = {
  codice?: string | number
  dataCreazione: string
  descrizione: string
  showNotification: (message: string, type: "success" | "error") => void
  orderDelClient: FetchDataState
  ordine: ReqOrderHeaderDto
}

const Title = ({
  codice,
  dataCreazione,
  descrizione,
  orderDelClient,
  ordine,
  showNotification,
}: Props) => {
  const { labels } = useContext(GeneralContext)
  const {
    label: {
      HWOrder: {
        newOrder: { upFront },
      },
      notification: { message },
    },
  } = labels as LabelLan
  const navigate = useNavigate()

  const [ErrorNotRender, setErrorNotRender] = useState<ErrorNotification>(errorNotDefault)

  useEffect(() => {
    if (ErrorNotRender.type !== "") {
      showNotification(orderDelClient.errorMessage, "error")
      setErrorNotRender(errorNotDefault)
    }
  }, [ErrorNotRender])

  return (
    <div className="col-12 d-flex flex-column align-items-center flex-md-row">
      <div className="col-3">
        <div className="d-flex flex-column">
          <p className="m-1">{upFront.idOrder}</p>
          <span style={{ fontWeight: "bold" }} className="boxTitleOrdine">
            {codice ?? "Nuovo"}
          </span>
        </div>
      </div>
      <div className="col-3">
        <div className="d-flex flex-column">
          <p className="m-1">{upFront.orderDate}</p>
          <span style={{ fontWeight: "bold" }} className="boxTitleOrdine">
            {dataCreazione}
          </span>
        </div>
      </div>
      <div className="col-3">
        <div className="d-flex flex-column">
          <p className="m-1">{upFront.statusOrder}</p>
          <span style={{ fontWeight: "bold" }} className="boxTitleOrdine">
            {descrizione ?? "In fase di creazione"}
          </span>
        </div>
      </div>
      <div className="col-12 col-md-3 text-end align-self-end">
        <BtnCheck
          Title={upFront.delete}
          btnStyle="mb-2"
          icon={<Cestino />}
          bgColor="btn-danger"
          disabled={ordine.statusId > 3 || ordine.statusId === 0}
          onClick={async () => {
            const result = await orderDelClient.fetch({ orderId: ordine.orderId })
            if (result) {
              showNotification(message.deleteOrderSuccess, "success")
              setTimeout(() => {
                navigate("/ricambi/lista-ordini")
              }, 500)
            } else {
              showNotification(orderDelClient.errorMessage, "error")
            }
          }}
        />
      </div>
    </div>
  )
}

export default Title
