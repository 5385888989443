import { useContext, useEffect, useState } from "react"
import { ChiaveValore, FetchDataState } from "../../../Models/Generic"
import { redirect } from "react-router-dom"
import { BaseRequest } from "../../../Models/BaseRequest"
import { GeneralContext } from "../../../Components/UI/MasterPage/MasterPage"
import { LangErrors } from "../../../Models/LangError"

const useGetHttp = (url: string) => {
  const [data, setData] = useState<undefined>(undefined)
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isTouched, setIsTouched] = useState(false)
  const [errorMessage, seterrorMessage] = useState<string>("")
  const [isSuccess, setIsSuccess] = useState(false)

  const { labelsErros } = useContext(GeneralContext)

  const fetch = async (
    Obj?: Object,
    customParameter?: string,
    noDeal?: boolean
  ): Promise<boolean> => {
    return await new Promise(async (resolve) => {
      setisLoading(true)

      const parameters: ChiaveValore[] = Object.entries(Obj ?? {}).map((x) => ({
        chiave: x[0],
        valore: x[1],
      }))

      //parameters.push({chiave: "authToken", valore: localStorage.getItem("ATK") ?? ""})

      try {
        const dataHTTP = await GetHTTP(url, parameters, customParameter, noDeal)
        if (dataHTTP) {
          setIsTouched(true)
          if (dataHTTP?.success) {
            setData(dataHTTP.data)
            resolve(true)
            setisLoading(false)
            setIsSuccess(true)
          } else {
            const { controller } = labelsErros as LangErrors
            const [codeController, codeApi, codeError] = dataHTTP.data.Code.split(".")
            const controllerSel = controller.find((x) => x.codice === codeController)
            const apiSel = controllerSel?.api.find((x) => x.codice === codeApi)
            let errorSel = apiSel?.errori[codeError]
            if (errorSel.includes("{0}")) {
              if (dataHTTP.data.Message.includes(":")) {
                errorSel = errorSel.replace("{0}", dataHTTP.data.Message.split(":")[1])
              } else {
                errorSel = errorSel.replace("{0}", "N")
              }
            }
            if (errorSel) {
              throw new Error(errorSel)
            } else {
              throw new Error(
                controller
                  .find((x) => x.codice === "100")!
                  .api.find((x) => x.codice === "0")!.errori["1"]
              )
            }
          }
        } else {
          throw new Error("La chiamata è fallita")
        }
      } catch (e: any) {
        setData(undefined)
        setIsError(true)
        seterrorMessage(e.message)
        setisLoading(false)
        resolve(false)
      }
    })
  }

  const reset = () => {
    setIsTouched(false)
    setIsError(false)
    setData(undefined)
    seterrorMessage("")
    setIsSuccess(false)
  }

  const state: FetchDataState = {
    dataF: data,
    isLoading,
    isError,
    errorMessage,
    fetch,
    isTouched,
    reset,
    isSuccess,
  }

  return state
}

export const GetHTTP = async (
  url: string,
  parameters: ChiaveValore[] = [],
  customParameter?: string,
  noDeal?: boolean
) => {
  const baseUrl = process.env.REACT_APP_BASE_API_URL
  let queryString
  if (localStorage.getItem("IMP_USER") && !noDeal) {
    const imp = JSON.parse(localStorage.getItem("IMP_USER") ?? "")
    parameters.push({ chiave: "dealerId", valore: imp.id })
  }
  if (customParameter) {
    queryString = customParameter
  } else {
    queryString = parameters.length
      ? `?${parameters
          .map((p) => (p.valore !== undefined ? `${p.chiave}=${p.valore}` : null))
          .filter((query) => query !== null)
          .join("&")}`
      : ""
  }
  const fullUrl = `${baseUrl}${url}${queryString}`
  let response: Response = {} as Response

  const authToken = localStorage.getItem("ATK")
  try {
    response = await fetch(fullUrl, {
      method: "GET",
      headers: {
        Authorization: authToken ?? "",
      },
    })
    const data = await response.json()
    const value = {
      data,
      statusCode: response.status,
      success: response.ok,
      error: false,
      errorMessage: "",
    }
    return value
  } catch (error) {
    const value = {
      data: undefined,
      statusCode: response.status,
      success: response.ok,
      error: true,
      errorMessage: response.statusText,
    }
    return value
  }
}

export default useGetHttp
