import React from "react"
import Spinner from "./Spinner"
import ErrorComponent from "./ErrorComponent"

export type props = {
  easyIcon?: boolean
}

const Loading = ({ easyIcon = false }: props) => {
  if (easyIcon) return <div className="spinner-border text-success spinner-border-sm" role="status"></div>

  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="spinner-border text-success" role="status"></div>
    </div>
  )
}

export default Loading
