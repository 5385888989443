import * as xlsx from "xlsx"

export const xlsxReader = (file: string | ArrayBuffer | undefined | null) => {
  if (typeof file === "string") return JSON.parse(file)
  if (file) {
    const workbook = xlsx.read(file, { type: "buffer" })
    const workSheetname = workbook.SheetNames[0]
    const worksheet = workbook.Sheets[workSheetname]
    const data: JSON[] = xlsx.utils.sheet_to_json<JSON>(worksheet)

    return data
  }
}
